import FbApp from "../../config/FbApp";

export default function Tickets() {}

export const getAllOpenTickets = () => {
	const db = FbApp.firestore();
	return db
		.collection("SUPPORT_TICKETS")
		.where("ticketStatus", "==", "open")
		.get()
		.then((snapshots) => {
			var docData = snapshots.docs.map((doc) => {
				const dataWithId = { id: doc.id, ...doc.data() };
				return dataWithId;
			});
			//console.log("all Tickets", docData);
			return docData;
		});
};

export const getAllCloseTickets = () => {
	const db = FbApp.firestore();
	return db
		.collection("SUPPORT_TICKETS")
		.where("ticketStatus", "==", "close")
		.get()
		.then((snapshots) => {
			var docData = snapshots.docs.map((doc) => {
				const dataWithId = { id: doc.id, ...doc.data() };
				return dataWithId;
			});
			//console.log("all Tickets", docData);
			return docData;
		});
};

export const getAllOnHoldTickets = () => {
	const db = FbApp.firestore();
	return db
		.collection("SUPPORT_TICKETS")
		.where("ticketStatus", "==", "onHold")
		.get()
		.then((snapshots) => {
			var docData = snapshots.docs.map((doc) => {
				const dataWithId = { id: doc.id, ...doc.data() };
				return dataWithId;
			});
			//console.log("all Tickets", docData);
			return docData;
		});
};

export const getAllInProgressTickets = () => {
	return new Promise((resolve, reject) => {
		const db = FbApp.firestore();
		db.collection("SUPPORT_TICKETS")
			.where("ticketStatus", "==", "inProgress")
			.get()
			.then((snapshots) => {
				var docData = snapshots.docs.map((doc) => {
					const dataWithId = { id: doc.id, ...doc.data() };
					return dataWithId;
				});
				//console.log("all Tickets", docData);
				resolve(docData);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const updateTicket = (updatedTicket) => {
	const db = FbApp.firestore();
	return db
		.collection("SUPPORT_TICKETS")
		.doc(updatedTicket.id)
		.update(updatedTicket)
		.then((response) => {
			if (response) {
				return response;
			}
		});
};

export const getAllTickets = () => {
	const db = FbApp.firestore();
	return db
		.collection("SUPPORT_TICKETS")
		.get()
		.then((snapshots) => {
			var docData = snapshots.docs.map((doc) => {
				const dataWithId = { id: doc.id, ...doc.data() };
				return dataWithId;
			});
			//console.log("all Tickets", docData);
			return docData;
		});
};
