import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import {
  Grid,
  Dialog,
  DialogContent,
  Box,
  Zoom,
  TextField,
  Button,
  useTheme,
  DialogTitle,
  Typography,
  DialogActions,
} from "@mui/material";

import { useSnackbar } from "notistack";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { addAndUpdateAllVehiclesMasterData } from "../../services/metadata/metadata";
import _ from "lodash";

function FareManagementDetailsDialog({
  open,
  setOpen,
  selectedFareMasterData,
  allVehiclesMasterData,
  selectedVehicleIndex,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [editMode, setEditMode] = useState(false);

  const handleCreateVehiclesFareManagementClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  useEffect(async () => {
    if (selectedFareMasterData) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [allVehiclesMasterData, selectedFareMasterData]);
  return (
    <>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={handleCreateVehiclesFareManagementClose}
      >
        <DialogTitle
          sx={{
            flexDirection: "row",
            textAlign: "center",
          }}
        >
          <Typography variant="button" fontSize={18}>
            {"Fare Management"}
          </Typography>
        </DialogTitle>
        <Formik
          enableReinitialize
          initialValues={{
            Category: selectedFareMasterData?.Category,
            SubCategory: selectedFareMasterData?.SubCategory,
            Model: selectedFareMasterData?.Model,
            BaseFare: selectedFareMasterData?.BaseFare,
            FarePerKM: selectedFareMasterData?.FarePerKM,
            submit: null,
          }}
          onSubmit={async (
            _values,
            { setErrors, setStatus, setSubmitting }
          ) => {
            try {
              let payload = {
                Category: _values.Category,
                SubCategory: _values.SubCategory,
                Model: _values.Model,
                BaseFare: parseInt(_values.BaseFare),
                FarePerKM: parseInt(_values.FarePerKM),
                isActive: true,
              };
              let error = false;
              if (
                _values.BaseFare === undefined ||
                _values.BaseFare === "" ||
                _.isNaN(parseInt(_values.BaseFare))
              ) {
                enqueueSnackbar("Please enter BaseFare", {
                  variant: "error",
                });
                error = true;
              }
              if (
                _values.FarePerKM === undefined ||
                _values.FarePerKM === "" ||
                _.isNaN(parseInt(_values.FarePerKM))
              ) {
                enqueueSnackbar("Please enter FarePerKM", {
                  variant: "error",
                });
                error = true;
              }
              if (!error && !editMode) {
                let vehicleArray = [];
                allVehiclesMasterData.data.vehicles =
                  allVehiclesMasterData.data.vehicles.map((vehicle, index) => {
                    vehicleArray.push(vehicle);
                    return vehicle;
                  });
                await addAndUpdateAllVehiclesMasterData(allVehiclesMasterData)
                  .then(() => {
                    enqueueSnackbar(`Vehicle Fare Data has been added`, {
                      variant: "success",
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      TransitionComponent: Zoom,
                    });
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {
                    setOpen(false);
                  });
              } else if (!error) {
                allVehiclesMasterData.data.vehicles =
                  allVehiclesMasterData.data.vehicles.map((vehicle, index) => {
                    if (selectedVehicleIndex === index) {
                      return payload;
                    }
                    return vehicle;
                  });
                await addAndUpdateAllVehiclesMasterData(
                  allVehiclesMasterData
                ).then(() => {
                  enqueueSnackbar(`Vehicle Fare Data has been updated `, {
                    variant: "success",
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                    TransitionComponent: Zoom,
                  });
                  setOpen(false);
                });
              }
              setStatus({ success: true });
              setSubmitting(false);
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent
                dividers
                sx={{
                  p: 3,
                }}
              >
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={3}
                    sm={4}
                    md={2}
                    justifyContent="center"
                    textAlign={{ sm: "right" }}
                  >
                    <Box
                      pr={3}
                      sx={{
                        pt: `${theme.spacing(2)}`,
                        pb: { xs: 1, md: 0 },
                      }}
                      alignSelf="center"
                    >
                      <b>{"Category*"} :</b>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      mb: `${theme.spacing(3)}`,
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={10}
                  >
                    <TextField
                      error={Boolean(touched.Category && errors.Category)}
                      fullWidth
                      helperText={touched.Category && errors.Category}
                      disabled
                      name="Category"
                      placeholder={"Enter Category"}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Category}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sm={4}
                    md={2}
                    justifyContent="center"
                    textAlign={{ sm: "right" }}
                  >
                    <Box
                      pr={3}
                      sx={{
                        pt: `${theme.spacing(2)}`,
                        pb: { xs: 1, md: 0 },
                      }}
                      alignSelf="center"
                    >
                      <b>{"Sub-Category*"} :</b>
                    </Box>
                  </Grid>

                  <Grid
                    sx={{
                      mb: `${theme.spacing(3)}`,
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={10}
                  >
                    <TextField
                      error={Boolean(touched.SubCategory && errors.SubCategory)}
                      fullWidth
                      helperText={touched.SubCategory && errors.SubCategory}
                      disabled
                      name="SubCategory"
                      placeholder={"Enter SubCategory"}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.SubCategory}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sm={4}
                    md={2}
                    justifyContent="center"
                    textAlign={{ sm: "right" }}
                  >
                    <Box
                      pr={3}
                      sx={{
                        pt: `${theme.spacing(2)}`,
                        pb: { xs: 1, md: 0 },
                      }}
                      alignSelf="center"
                    >
                      <b>{"Model*"} :</b>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      mb: `${theme.spacing(3)}`,
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={10}
                  >
                    <TextField
                      error={Boolean(touched.Model && errors.Model)}
                      fullWidth
                      helperText={touched.Model && errors.Model}
                      disabled
                      name="Model"
                      placeholder={"Enter Model"}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.Model}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sm={4}
                    md={2}
                    justifyContent="center"
                    textAlign={{ sm: "right" }}
                  >
                    <Box
                      pr={3}
                      sx={{
                        pt: `${theme.spacing(2)}`,
                        pb: { xs: 1, md: 0 },
                      }}
                      alignSelf="center"
                    >
                      <b>{"Base Fare*"} :</b>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      mb: `${theme.spacing(3)}`,
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={10}
                  >
                    <TextField
                      error={Boolean(touched.BaseFare && errors.BaseFare)}
                      fullWidth
                      helperText={touched.BaseFare && errors.BaseFare}
                      name="BaseFare"
                      placeholder={"Enter BaseFare"}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.BaseFare}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sm={4}
                    md={2}
                    justifyContent="center"
                    textAlign={{ sm: "right" }}
                  >
                    <Box
                      pr={3}
                      sx={{
                        pt: `${theme.spacing(2)}`,
                        pb: { xs: 1, md: 0 },
                      }}
                      alignSelf="center"
                    >
                      <b>{"Fare-Per-KM*"} :</b>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      mb: `${theme.spacing(3)}`,
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={10}
                  >
                    <TextField
                      error={Boolean(touched.FarePerKM && errors.FarePerKM)}
                      fullWidth
                      helperText={touched.FarePerKM && errors.FarePerKM}
                      name="FarePerKM"
                      placeholder={"Enter FarePerKM"}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.FarePerKM}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "left" }} />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="error"
                  size="medium"
                  onClick={() => setOpen(false)}
                  startIcon={<CancelOutlinedIcon fontSize="small" />}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    mx: 2,
                  }}
                  type="submit"
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                  size="medium"
                  color="success"
                  startIcon={<SaveOutlinedIcon fontSize="small" />}
                >
                  {editMode ? "Update" : "Save"}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default FareManagementDetailsDialog;
