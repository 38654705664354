
import React,{useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { currentUserAuth, resetPassword } from '../../services/authentication/authentication';
import { activateDeactiveUser, updateUserProfile } from '../../services/users/userOnboarding';
import { useNavigate } from 'react-router-dom';
import { orange } from '@material-ui/core/colors';
import { Backdrop, CircularProgress, IconButton, InputAdornment, Snackbar } from '@material-ui/core';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Alert from '@material-ui/lab/Alert';
import { stubTrue } from 'lodash';
import Lottie from 'react-lottie';
import animationData from '../../assets/jss/lf30_editor_fvhlirkb.json';


const loaderOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor:'#FF8000',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(6),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#FF8000',
    color: '#fff',
    "&:hover":{
        backgroundColor: "#c55100"
      }
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: orange,
  },
});

export default function ResetPwd() {
  const classes = useStyles();
  let navigate = useNavigate();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [successToastOpen, setSuccessToastOpen] = React.useState(false);
  const [failureToastOpen, setFailureToastOpen] = React.useState(false);
  const [openLoader, setOpenLoader] = React.useState(false);



  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
 
 
 
  const closeLoader = () => {
    setOpenLoader(false);
};
const toggleLoader = () => {
    setOpenLoader(!openLoader);
};

  const handleClose = (event) => {
    setSuccessToastOpen(false);
    setFailureToastOpen(false);
};


  const userProfile = JSON.parse(localStorage.getItem("USER_PROFILE"));
  var user  = currentUserAuth();
    //console.log("current user",user);
  const changePassword = () =>{
    if (password !== confirmPassword){
      setFailureToastOpen(true)
      setConfirmPassword('')
      setPassword('');
      return;
    }
    toggleLoader();
    resetPassword(user,password).then(resp =>{
      closeLoader();
      //console.log("success",resp);
      userProfile.isDefaultPassword = false
      toggleLoader();
      activateDeactiveUser(userProfile).then(response =>{
        closeLoader();
        setSuccessToastOpen(true);
          //console.log("response profile update",response);
          navigate("/app/dashboard");
      },error =>{
        //console.log(error);
      })
    })
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        <ThemeProvider theme={theme}>
        <form className={classes.form} noValidate>
          <TextField          
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type={showPassword ? "text " : "Password"}
            id="password"
            value={password}
            autoComplete="current-password"
            onChange={(e) => { setPassword(e.target.value) }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type={showConfirmPassword ? "text " : "Password"}
            value={confirmPassword}
            onChange={(e) => { setConfirmPassword(e.target.value) }}
            id="confirmPassword"
            autoComplete="confirmPassword"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
 
          <Button
            // type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick = {changePassword}
          >
          Submit          
          </Button>
        </form>
        </ThemeProvider>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <Snackbar open={successToastOpen}
       autoHideDuration={2000} onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center',
         }}>
        <Alert onClose={handleClose} severity="success">
          Password reset successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={failureToastOpen} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
        <Alert onClose={handleClose} severity="error">
        Confirm password does not match!
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={openLoader} onClick={closeLoader}>
            {/* <CircularProgress color="inherit" /> */}
            <Lottie
            options={loaderOptions}
            height={200}
            width={600}
          />
       </Backdrop>
    </Container>
  );
}
