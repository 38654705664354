import React, { useState, useEffect } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
	Container,
	Grid,
	makeStyles,
	CircularProgress,
	Backdrop,
	Typography,
	ThemeProvider,
	createMuiTheme,
	MenuItem,
	TextField,
	Button,
	TableContainer,
	TableHead,
	Divider,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import * as XLSX from 'xlsx';


//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import { orange } from "@material-ui/core/colors";
import GridContainer from "../../components/Grid/GridContainer";
import stateDistrictData from "../../allStates-Districts.json";
import { forEach } from "lodash";
import { getAllProvider } from "../../services/users/userOnboarding";
import { getAllVehicles } from "../../services/vehicle/Vehicles";
import moment from "moment";
import { getDescendingOrderedList } from "../Helper/Utils";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	title: {
		fontSize: 25,
		textAlign: "center",
	},
	customTableContainer: {
		overflowX: "initial",
	},

	tableCell: {
		Width: 100,
	},
	head: {
		backgroundColor: "#FFA93F",
		position: "sticky",
		top: 0,
		color: "#fff",
	},
	button: {
		margin: theme.spacing(1),
		backgroundColor: "#FF8000",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#c55100",
		},
	},
}));
const theme = createMuiTheme({
	palette: {
		primary: orange,
	},
});

export default function VehicalStatus() {
	const classes = useStyles();
	let navigate = useNavigate();
	const [open, setOpen] = useState(false);

	const [inputValues, setInputValues] = useState({
		state: "Maharashtra",
		city: "Pune",
		operator: "",
		vehicleNumber: "",
		fromDate: "",
		toDate: "",
	});
	const { state, city, operator, vehicleNumber, fromDate, toDate } = inputValues;
	const [stateData, setStateData] = useState([]);
	const [districts, setDistricts] = useState([]);
	const [allProvider, setAllProvider] = useState([]);
	const [provider, setProvider] = useState([]);
	const [allVehicle, setAllVehicle] = useState([]);
	const [allVehicleList, setAllVehicleList] = useState([]);
	const [mainVehicleList, setMainVehicleList] = useState([]);


	const getInitalValues = () => {
		return {
			state: "Maharashtra",
			city: "Pune",
			operator: "",
			vehicleNumber: "",
			fromDate: "",
			toDate: "",
		};
	};
	const handleChange = (name) => (event) => {
		let valueToSet = event.target.value;
		setInputValues({ ...inputValues, error: false, [name]: valueToSet });

		if (name === "state") {
			Object.keys(stateDistrictData).forEach((key) => {
				if (stateDistrictData[key].state == valueToSet) {
					setDistricts(stateDistrictData[key].districts);
					//console.log("districts", stateDistrictData[key].districts);
				}
			});
		}

		if (name === "operator") {
			let operatorEmail = provider.filter((element) => element.businessInfo.businessName == valueToSet);
			let vehicle = mainVehicleList.filter((element) => element.ownerId == operatorEmail[0].businessInfo.officeEmail);
			let vehicleData = [];
			Object.keys(vehicle).forEach((key) => {
				vehicleData.push(vehicle[key].vehicleNumber);
			});
			setAllVehicle(vehicleData);
		}
	};
	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message, variant) => {
		enqueueSnackbar(message, {
			variant: variant ? variant : "info",
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		});
	};
	const handleSearch = () => {
		if (fromDate !== "" && toDate !== "") {
			let fromDateMil = new Date(fromDate).getTime();
			let toDateMil = new Date(toDate).getTime();
			if (fromDateMil > toDateMil) {
				showToast("Invalid Date Selected", "error");
				return;
			}
		}
		let filteredData = mainVehicleList;
		if (state !== "") {
			filteredData = mainVehicleList.filter((element) => element.state == state);
		}
		if (city !== "") {
			filteredData = filteredData.filter((element) => element.city == city);
		}
		if (operator !== "") {
			let filterProvider = provider.filter((element) => element.businessInfo.businessName == operator);
			filteredData = filteredData.filter((element) => element.ownerId == filterProvider[0].businessInfo.officeEmail);
		}
		if (vehicleNumber !== "") {
			filteredData = filteredData.filter((element) => element.vehicleNumber == vehicleNumber);
		}
		if (fromDate !== "") {
			filteredData = filteredData.filter((element) => new Date(fromDate).getTime() <= element.createdOnMil);
		}
		let lastHourToDate = new Date(toDate);
		lastHourToDate.setHours(23, 59, 0);
		let lastHourToDateMil = lastHourToDate.getTime();
		if (toDate !== "") {
			filteredData = filteredData.filter((element) => element.createdOnMil <= lastHourToDateMil);
		}
		setAllVehicleList(filteredData);
	};

	useEffect(() => {
		let allData = [];
		Object.keys(stateDistrictData).forEach((key) => {
			allData.push(stateDistrictData[key].state);
		});
		//console.log("stateData", allData);
		setStateData(allData);

		getAllProvider().then((response) => {
			setProvider(response);
			let providerData = [];
			Object.keys(response).forEach((key) => {
				providerData.push(response[key].businessInfo?.businessName);
			});

			setAllProvider(getDescendingOrderedList(providerData));
		});

		getAllVehicles().then((response) => {
			setAllVehicleList(getDescendingOrderedList(response));
			setMainVehicleList(getDescendingOrderedList(response));
			// let vehicleData = [];
			// Object.keys(response).forEach(key => {
			// 	//console.log('key',key)
			// 	vehicleData.push(response[key].vehicleNumber)
			// })
			// setAllVehicle(vehicleData);
		});
	}, []);

	const closeLoader = () => {
		setOpen(false);
	};
	const toggleLoader = () => {
		setOpen(!open);
	};
		 
		const exportToExcel = () => {  
			let excelElements = [];
			allVehicleList.map((excel,index)=>{
				let excelElement = {
					"Sr.No": index + 1,
					"Vehicle Category":excel?.vehicleCategory,
					"Sub Category":excel?.vehicleSubCategory,
					"Vehicle Number":excel?.vehicleNumber,
					"Vehicle OnBoarding Date":`${moment(excel.createdOnMil).format("DD/MM/YYYY")}`,
					"VIN/Engine/Chasis Number": excel?.rcNo,
					"Insurance Policy Number" : excel?.insuranceNo,
					"Insurance Provider" : excel?.insuranceName
				  };
				  excelElements.push(excelElement);
				  return excel;
			});
			 const wb = XLSX.utils.book_new();
			 const ws1 = XLSX.utils.json_to_sheet(excelElements);
			 XLSX.utils.book_append_sheet(wb, ws1, 'Vehicle List');
			 XLSX.writeFile(wb, `Vehicle Status report.xlsx`);
		};
	return (
		<Container>
			<Container maxWidth={false}>
				<GridItem xs={12} sm={12} md={12}>
					<Typography className={classes.title}> Vehicle Status Report </Typography>
					<ThemeProvider theme={theme}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="state"
									label="State"
									type="text"
									id="state"
									select
									value={state}
									onChange={handleChange("state")}
								>
									{/* {stateData.map((value,index)=>(
                                         <MenuItem value={value} key={index}>{value}</MenuItem>   
                                    ))} */}
									<MenuItem value="Maharashtra">Maharashtra</MenuItem>
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="city"
									label="City"
									type="text"
									id="city"
									select
									value={city}
									onChange={handleChange("city")}
								>
									<MenuItem value="Pune">Pune</MenuItem>
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="operator"
									label="Operator"
									type="text"
									id="operator"
									select
									value={operator}
									onChange={handleChange("operator")}
								>
									{allProvider.map((value, index) => (
										<MenuItem value={value} key={index}>
											{value}
										</MenuItem>
									))}
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="vehicleNumber"
									label="Vehicle Number"
									type="text"
									id="vehicleNumber"
									select
									value={vehicleNumber}
									onChange={handleChange("vehicleNumber")}
								>
									{allVehicle.map((value, index) => (
										<MenuItem value={value} key={index}>
											{value}
										</MenuItem>
									))}
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="fromDate"
									label="Onboarding From Date"
									type="date"
									id="fromDate"
									value={fromDate}
									onChange={handleChange("fromDate")}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="toDate"
									label="Onboarding To Date"
									type="date"
									id="toDate"
									value={toDate}
									onChange={handleChange("toDate")}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={2}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={() => handleSearch()}
								>
									Search
								</Button>
							</GridItem>
							<GridItem xs={12} sm={12} md={2}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={() => {
										setInputValues(getInitalValues());
										setAllVehicleList(mainVehicleList);
									}}
								>
									Clear
								</Button>
							</GridItem>
							<GridItem xs={12} sm={12} md={2}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={(e) => {
										exportToExcel("Export")
									}}
									startIcon={<FileDownloadOutlinedIcon fontSize="small" />}

								>
									Export
								</Button>
							</GridItem>
						</GridContainer>
						<GridContainer style={{ paddingTop: 20 }}>&nbsp;</GridContainer>
						<Divider></Divider>
						<GridContainer>
							<Table className={classes.table}>
								<TableBody>
									<TableRow>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Sr No.
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Vehicle Category
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Sub Category
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Vehicle Number
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Vehicle Onboarding Date
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												VIN/Engine/Chasis Number
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Insurance Policy Number
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Insurance Provider
											</Typography>
										</TableCell>
									</TableRow>

									{allVehicleList.map((vehicle, index) => (
										<TableRow className={classes.tableRow} key={index}>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{index + 1}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{vehicle.vehicleCategory}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{vehicle.vehicleSubCategory}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{vehicle.vehicleNumber}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{/* {vehicle.createdOn} */}
													{`${moment(vehicle.createdOnMil).format("DD/MM/YYYY")}`}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{vehicle.rcNo}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{vehicle.insuranceNo}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{vehicle?.insuranceName || "-"}
												</Typography>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
							{/* </TableContainer> */}
						</GridContainer>
					</ThemeProvider>
				</GridItem>
			</Container>
			<Backdrop className={classes.backdrop} open={open} onClick={closeLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Container>
	);
}
