import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import {
	Backdrop,
	CircularProgress,
	TableContainer,
	Fade,
	Typography,
	Modal,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Chip,
	TableHead,
	Tooltip,
} from "@material-ui/core";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import Lottie from "react-lottie";
import animationData from "../../assets/jss/lf30_editor_fvhlirkb.json";
import { getAllVehicles, updateVehicle } from "../../services/vehicle/Vehicles";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import { RemoveRedEyeOutlined } from "@material-ui/icons";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import { cardHeader } from "../../assets/jss/material-dashboard-react";
import SearchField from "react-search-field";
import _ from "lodash";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((styles) => ({
	backdrop: {
		zIndex: styles.zIndex.drawer + 1,
		color: "#fff",
	},

	customTableContainer: {
		overflowX: "initial",
	},

	tableCell: {
		Width: 100,
	},
	head: {
		backgroundColor: "#FFA93F",
		position: "sticky",
		top: 0,
		color: "#fff",
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	paperModal: {
		backgroundColor: styles.palette.background.paper,
		border: "2px solid #000",
		boxShadow: styles.shadows[5],
		padding: styles.spacing(2, 4, 3),
		width: "50%",
	},
}));

const PurpleSwitch = withStyles({
	switchBase: {
		"&$checked": {
			color: "#FF8000",
		},
		"&$checked + $track": {
			backgroundColor: "#FF8000",
		},
	},
	checked: {},
	track: {},
})(Switch);

const loaderOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};
export default function VehicleListView() {
	const classes = useStyles();

	const [vehicleList, setvehicleList] = useState([]);
	const [openLoader, setOpenLoader] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [selectedVehicle, setselectedVehicle] = useState(null);
	const [filteredVehicleList, setfilterdVehicleList] = useState([]);
	const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message, variant) => {
		enqueueSnackbar(message, {
			variant: variant ? variant : "info",
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		});
	};

	const closeLoader = () => {
		setOpenLoader(false);
	};
	const toggleLoader = () => {
		setOpenLoader(!openLoader);
	};
	const handleOpenModal = (vehicle) => {
		setselectedVehicle(vehicle);
		//console.log(vehicle);
		setOpenModal(true);
		//TODO - Add vehicle image showing code here.
	};
	const handleCloseModal = () => {
		setselectedVehicle(null);
		setOpenModal(false);
	};

	const onDeactivate = (vehicle) => {
		setselectedVehicle(vehicle);
		//console.log("selectedVehicle", vehicle);
		vehicle.isActive = false;
		updateVehicle(vehicle)
			.then((response) => {
				//console.log(response, "vehicle deactivated");
				setselectedVehicle("");
				handleCloseConfirmationDialog();
				//	setCurrentUserStatus(false);
				showToast(`Vehicle ${vehicle?.vehicleNumber} is Deactivated`, "error");
			})
			.catch((error) => {
				showToast(`Error deactivating Vehicle`, "success");
			});
	};

	const onActivate = (vehicle) => {
		setselectedVehicle(vehicle);
		//console.log("selectedVehicle", vehicle);
		vehicle.isActive = true;
		updateVehicle(vehicle)
			.then((response) => {
				//console.log(response, "vehicle Activated");
				setselectedVehicle("");
				handleCloseConfirmationDialog();
				//setCurrentUserStatus(true);
				showToast(`Vehicle ${vehicle?.vehicleNumber} is Activated`, "success");
			})
			.catch((error) => {
				showToast(`Error activating Vehicle`, "success");
			});
	};

	const handleOpenConfirmationDialog = () => {
		setOpenConfirmationDialog(true);
	};

	const handleCloseConfirmationDialog = () => {
		setOpenConfirmationDialog(false);
	};

	const handleToggleVehicle = (vehicle) => {
		handleOpenConfirmationDialog();
		setselectedVehicle(vehicle);
	};

	/* const getVehicleImagePath = (vehicleNo) => {
		const baseUrl = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/VehicleImages/${vehicleNo}`;
		let imagePath = url.replace(baseUrl, '');
		const indexOfEndPath = imagePath.indexOf('?');
		imagePath = imagePath.substring(0, indexOfEndPath);
		imagePath = imagePath.replace('%2F', '/');
		//console.log(imagePath);
		return imagePath;
	}; */

	useEffect(() => {
		toggleLoader();
		getAllVehicles().then((vehicles) => {
			if (vehicles) {
				vehicles = _.orderBy(vehicles, "createdOnMil", "desc");
			}
			closeLoader();
			setvehicleList(vehicles);
			setfilterdVehicleList(vehicles);
			//console.log(vehicles, "vehicle");
		});
	}, []);
	const onChangeSerchText = (e) => {
		if (e && e.trim() !== " ") {
			let filterVehicle = vehicleList.filter((vehicle) => {
				return (
					vehicle?.providerFirstName?.toLowerCase().indexOf(e.toLowerCase()) > -1 ||
					vehicle?.providerLastName?.toLowerCase().indexOf(e.toLowerCase()) > -1
				);
			});
			//console.log("Text", filterVehicle);
			setfilterdVehicleList(filterVehicle);
		} else {
			setfilterdVehicleList(vehicleList);
		}
	};

	return (
		<>
			<div>&nbsp;</div>
			<div>
				<SearchField
					placeholder="Search Operator Name..."
					onChange={onChangeSerchText}
					searchText=""
					classNames="test-class"
				/>
			</div>
			<div>&nbsp;</div>
			<TableContainer component={Paper} className={classes.customTableContainer}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Sr.No
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Operator Name
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Category
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Sub-Category
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Model
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Vehicle No
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Status
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Actions
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									View
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredVehicleList.map((vehicle, index) => (
							<TableRow key={index} className={classes.tableRow}>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{index + 1}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{vehicle.providerFirstName} {vehicle.providerLastName}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{vehicle.vehicleCategory}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{vehicle.vehicleSubCategory}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography style={{ width: 300, wordWrap: "break-word" }} variant="body1" gutterBottom>
										{vehicle.vehicleModel}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{vehicle.vehicleNumber}
									</Typography>
								</TableCell>
								<TableCell>
									{vehicle.isActive == true ? (
										<Chip
											label="&nbsp;Active&nbsp;"
											color="green"
											variant="outlined"
											style={{ borderColor: "green", color: "black", fontWeight: "bold" }}
										/>
									) : (
										<Chip
											label="Inactive"
											variant="outlined"
											style={{ backgroundColor: "red", color: "white", fontWeight: "bold" }}
										/>
									)}
								</TableCell>
								{vehicle.isActive ? (
									<TableCell>
										<Tooltip arrow title="Deactivate">
											<PurpleSwitch
												checked={true}
												onClick={() => {
													handleToggleVehicle(vehicle);
												}}
											/>
										</Tooltip>
									</TableCell>
								) : (
									<TableCell>
										<Tooltip arrow title="Activate">
											<PurpleSwitch
												onClick={() => {
													// onActivate(vehicle)
													handleToggleVehicle(vehicle);
												}}
												checked={false}
											/>
										</Tooltip>
									</TableCell>
								)}
								<TableCell>
									<RemoveRedEyeOutlined onClick={() => handleOpenModal(vehicle)} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Dialog
				//component={Paper}
				//fullWidth="true"
				maxWidth="xs"
				open={openConfirmationDialog}
				//TransitionComponent={Transition}
				onClose={handleCloseConfirmationDialog}
			>
				<DialogTitle id="alert-dialog-title">{"NowWagon"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{`Do you want to ${
						selectedVehicle !== "" && selectedVehicle?.isActive ? "Deactivate" : "Activate"
					} Vehicle ${selectedVehicle?.vehicleNumber}?`}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						className={classes.submit}
						onClick={
							selectedVehicle !== ""
								? selectedVehicle?.isActive
									? () => onDeactivate(selectedVehicle)
									: () => onActivate(selectedVehicle)
								: null
						}
					>
						YES
					</Button>
					<Button color="primary" className={classes.submit} onClick={() => handleCloseConfirmationDialog()} autoFocus>
						NO
					</Button>
				</DialogActions>
			</Dialog>
			<Backdrop className={classes.backdrop} open={openLoader} onClick={closeLoader}>
				{/* <Lottie 
	        options={loaderOptions}
            height={200}
            width={600}
        /> */}
				<CircularProgress color="inherit" />
			</Backdrop>
			{selectedVehicle !== null && (
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className={classes.modal}
					open={openModal}
					onClose={handleCloseModal}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={openModal}>
						<div className={classes.paperModal}>
							<GridContainer>
								<GridItem xs={12} sm={12} md={8}>
									<GridContainer>
										<GridItem xs={12} sm={12} md={4}>
											<strong>Vehicle No:</strong>
										</GridItem>
										<GridContainer></GridContainer>
										<GridItem xs={12} sm={12} md={8}>
											<Typography variant="body2" noWrap>
												{selectedVehicle.vehicleNumber}
											</Typography>
										</GridItem>
									</GridContainer>

									<GridContainer>&nbsp;</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={4}>
											<strong>RTO Authority:</strong>
										</GridItem>
										<GridItem xs={12} sm={12} md={8}>
											<Typography variant="body2" noWrap>
												{selectedVehicle.regAuthority}
											</Typography>
										</GridItem>
									</GridContainer>

									<GridContainer>&nbsp;</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={4}>
											<strong>RC Number:</strong>
										</GridItem>
										<GridItem xs={12} sm={12} md={8}>
											<Typography variant="body2" noWrap>
												{selectedVehicle.rcNo}
											</Typography>
										</GridItem>
									</GridContainer>
									<GridContainer>&nbsp;</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={4}>
											<strong>Vehicle Size Ton:</strong>
										</GridItem>
										<GridItem xs={12} sm={12} md={8}>
											<Typography variant="body2" noWrap>
												{selectedVehicle.vehicleSize}
											</Typography>
										</GridItem>
									</GridContainer>
									{/* <GridContainer>&nbsp;</GridContainer> */}
									{/* <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <strong>Creation Date:</strong>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8}>
                    <Typography variant="body2" noWrap><Moment format='YYYY-MM-DD:hh-mm'>{selectedOrder.createOn}</Moment></Typography>
                  </GridItem>
                </GridContainer> */}
									<GridContainer>&nbsp;</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={4}>
											<strong>Category:</strong>
										</GridItem>
										<GridItem xs={12} sm={12} md={8}>
											<Typography variant="body2" noWrap>
												{selectedVehicle.vehicleCategory}
											</Typography>
										</GridItem>
									</GridContainer>
									<GridContainer>&nbsp;</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={4}>
											<strong>Sub-Category:</strong>
										</GridItem>
										<GridItem xs={12} sm={12} md={8}>
											<Typography variant="body2" noWrap>
												{selectedVehicle.vehicleSubCategory}
											</Typography>
										</GridItem>
									</GridContainer>
									<GridContainer>&nbsp;</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={4}>
											<strong>Model:</strong>
										</GridItem>
										<GridItem xs={12} sm={12} md={8}>
											<Typography variant="body2" noWrap>
												{selectedVehicle.vehicleModel}
											</Typography>
										</GridItem>
									</GridContainer>
									{/* <GridContainer>&nbsp;</GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <strong>Rc No:</strong>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8}>
                    <Typography variant="body2" noWrap>{selectedVehicle.rcNo}</Typography>
                  </GridItem>
                </GridContainer> */}
									<GridContainer>&nbsp;</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={4}>
											<strong>Insurance No:</strong>
										</GridItem>
										<GridItem xs={12} sm={12} md={8}>
											<Typography variant="body2" noWrap>
												{selectedVehicle.insuranceNo}
											</Typography>
										</GridItem>
									</GridContainer>
									<GridContainer>&nbsp;</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={4}>
											<strong>Status:</strong>
										</GridItem>
										<GridItem xs={12} sm={12} md={8}>
											<Typography variant="body2" noWrap>
												{selectedVehicle.isActive === true ? "Active" : "Deactivated"}
											</Typography>
										</GridItem>
									</GridContainer>
									<GridContainer>&nbsp;</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={4}>
											<strong>Owner Id:</strong>
										</GridItem>
										<GridItem xs={12} sm={12} md={8}>
											<Typography variant="body2" noWrap>
												{selectedVehicle.ownerId}
											</Typography>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</div>
					</Fade>
				</Modal>
			)}
		</>
	);
}
