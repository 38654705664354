import FbApp from "../../config/FbApp";

export default function Drivers() {}

export const getAllDrivers = () => {
	return new Promise((resolve, reject) => {
		const db = FbApp.firestore();
		//return db.collection('PROVIDER_USER').where('role' , '==', 'DRIVER').orderBy('firstName', 'desc')
		db.collection("PROVIDER_USER")
			// .where("role", "==", "DRIVER")
			.get()
			.then((snapshots) => {
				var docData = snapshots.docs.map((doc) => doc.data());
				docData = docData.filter((row) => row.role === "PROVIDER-DRIVER" || row.role === "DRIVER");
				docData = docData.map((row, index) => {
					if (
						row.role === "PROVIDER-DRIVER" &&
						row?.userProfile &&
						row?.userProfile?.businessInfo &&
						row?.userProfile?.contactDetails
					) {
						let obj = {
							email: row?.userProfile?.businessInfo?.officeEmail,
							firstName: row?.userProfile?.contactDetails?.contactFirstName,
							lastName: row?.userProfile?.contactDetails?.contactLastName,
							mobileNumber: row?.userProfile?.contactDetails.contactMobileNumber,
							ownerId: row?.userProfile.businessInfo.officeEmail,
						};
						let newrow = { ...obj, ...row };
						return newrow;
					} else if (row.role === "PROVIDER-DRIVER") {
						return false;
					}
					return row;
				});
				resolve(docData);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const getSelectedDrivers = (city, operatorEmail) => {
	const db = FbApp.firestore();
	return db
		.collection("PROVIDER_USER")
		.where("role", "==", "DRIVER")
		.where("ownerId", "==", operatorEmail)
		.where("isActive", "==", true)
		.get()
		.then((snapshots) => {
			var docData = snapshots.docs.map((doc) => doc.data());
			//console.log("all Drivers", docData);
			return docData;
		});
};
