import FbApp from "../../config/FbApp";

export default function Authentication() {}
// const navigate = useNavigate();

const Login = (email, password) => {
	// const navigate = useNavigate();
	const auth = FbApp.auth();
	return auth.signInWithEmailAndPassword(email, password).then(
		(data) => {
			//console.log("login ",data)
			return data;
		},
		(error) => {
			//console.log("error",error);
			return error;
			// return false
		}
	);
};
const signup = (email, password) => {
	const auth = FbApp.auth();
	auth.createUserWithEmailAndPassword(email, password).then((response) => {
		if (response) {
			//console.log(response);
		}
	});
};
const currentUserAuth = () => {
	const auth = FbApp.auth();
	return auth.currentUser;
};
export const resetPassword = (user, newPassword) => {
	// const auth = FbApp.auth();
	// auth.sendPasswordResetEmail(email)
	return user
		.updatePassword(newPassword)
		.then(function () {})
		.catch(function (error) {
			// An error happened.
			return error;
		});
};

export const changePassword = (user, newPassword) => {
	// const auth = FbApp.auth();
	// auth.sendPasswordResetEmail(email)
	return user
		.updatePassword(newPassword)
		.then(function () {
			user.reauthenticateWithCredential(user, newPassword);
		})
		.catch(function (error) {
			// An error happened.
			return error;
		});
};
export const resetPasswordExternal = (email) => {
	const auth = FbApp.auth();
	return auth
		.sendPasswordResetEmail(email)
		.then((resp) => {
			//console.log("success", resp);
			return resp;
		})
		.catch((error) => {
			//console.log('Error while calling send reset link email..' , error);
			throw error;
		});
};
export { currentUserAuth };
export { Login };
export { signup };
