import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import { Backdrop, CircularProgress, TableContainer, TableHead, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";

import Lottie from "react-lottie";
import animationData from "../../assets/jss/lf30_editor_fvhlirkb.json";
import { getAllCloseTickets } from "../../services/ticketingSystem/Tickets";
import _ from "lodash";

const useStyles = makeStyles((styles) => ({
	backdrop: {
		zIndex: styles.zIndex.drawer + 1,
		color: "#fff",
	},

	customTableContainer: {
		overflowX: "initial",
	},

	tableCell: {
		Width: 100,
	},
	head: {
		backgroundColor: "#FFA93F",
		position: "sticky",
		top: 0,
		color: "#fff",
	},
}));

const PurpleSwitch = withStyles({
	switchBase: {
		"&$checked": {
			color: "#FF8000",
		},
		"&$checked + $track": {
			backgroundColor: "#FF8000",
		},
	},
	checked: {},
	track: {},
})(Switch);

const loaderOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};

export default function CloseTickets() {
	const classes = useStyles();

	const [openLoader, setOpenLoader] = useState(false);
	const [closeTicketData, setCloseTicketData] = useState([]);

	const closeLoader = () => {
		setOpenLoader(false);
	};

	const toggleLoader = () => {
		setOpenLoader(!openLoader);
	};
	useEffect(() => {
		getAllCloseTickets().then((closedTickets) => {
			closeLoader();
			closedTickets = _.orderBy(closedTickets, "closedOnMil", "desc");
			setCloseTicketData(closedTickets);
		});
	}, []);

	return (
		<>
			<TableContainer component={Paper} className={classes.customTableContainer}>
				<Table>
					<TableHead>
						<TableRow>
							{/* <TableCell className={classes.head}>
              <Typography variant="h4" gutterBottom className={classes.titleColor}>Ticket ID</Typography>
            </TableCell>
            <TableCell className={classes.head} >
              <Typography variant="h4" gutterBottom className={classes.titleColor}>Category</Typography>
            </TableCell>
            <TableCell  className={classes.head}>
              <Typography variant="h4" gutterBottom className={classes.titleColor}>Sub-Category</Typography>
            </TableCell> */}
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Sr.No
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Created By
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Description
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Creator's Comment
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Executive's Comment
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{closeTicketData.map((ticket, index) => (
							<TableRow key={index} className={classes.tableRow}>
								{/* <TableCell >
                <Typography variant="body1" gutterBottom>{ticket.id}</Typography>
              </TableCell>
              <TableCell >
                <Typography variant="body1" gutterBottom>{ticket.category}</Typography> 
              </TableCell>
              <TableCell >
                <Typography variant="body1" gutterBottom>{ticket.subCategory}</Typography>
              </TableCell> */}
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{index + 1}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{ticket.ticketOwner}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{ticket.issueDescription}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{ticket.operatorComment || ticket.driverComment || ticket.customerComment}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{ticket.executiveComment}
									</Typography>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Backdrop className={classes.backdrop} open={openLoader} onClick={closeLoader}>
				{/* <Lottie 
	        options={loaderOptions}
            height={200}
            width={600}
        /> */}
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
}
