import React, { useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
  Container,
  Grid,
  makeStyles,
  CircularProgress,
  Backdrop,
  Typography,
} from "@material-ui/core";
//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  title: {
    fontSize: 25,
    textAlign: "center",
  },
  card: {
    // flexDirection:'row',
    marginTop: "40%",
    background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
}));

export default function Reports() {
  const classes = useStyles();
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const closeLoader = () => {
    setOpen(false);
  };
  const toggleLoader = () => {
    setOpen(!open);
  };
  return (
    <Container>
      <Container maxWidth={false}>
        <GridItem xs={12} sm={12} md={8}>
          <Typography className={classes.title}>Report PAGE</Typography>
        </GridItem>
      </Container>

      <Backdrop className={classes.backdrop} open={open} onClick={closeLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
