import React, { useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
  Container,
  makeStyles,
  CircularProgress,
  Backdrop,
  Typography,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import { orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  title: {
    fontSize: 15,
    textAlign: "justify",
    fontFamily: "Times New Roman",
  },
  customTableContainer: {
    overflowX: "initial",
  },

  tableCell: {
    Width: 100,
  },
  head: {
    backgroundColor: "#FFA93F",
    position: "sticky",
    top: 0,
    color: "#fff",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#FF8000",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c55100",
    },
  },
}));
const theme = createMuiTheme({
  palette: {
    primary: orange,
  },
});

export default function FAQScreen() {
  const classes = useStyles();
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const closeLoader = () => {
    setOpen(false);
  };
  const toggleLoader = () => {
    setOpen(!open);
  };
  return (


  
    <Container>
       <Container maxWidth={false}>
         <GridItem xs={12} sm={12} md={12}>
           <ThemeProvider theme={theme}>
             <Typography className={classes.title}>
               <h2 > FAQs - Operator </h2>
               <br />
               <p>

                <Accordion>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    <b>Why should I register as an Operator on NowWagon?</b>
                        
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    NowWagon allows you to connect with your customer anywhere in
                your city with the help of just one tap on NowWagon Mobile App
                and manages your trips. So, make yourself comfortable and enjoy
                your business as NowWagon is with you now and forever.

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    <b>How do I register to NowWagon as an Operator?</b>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Simply contact our support executives, and they will help you
                with the registration process.
                <br />
                <b>Email: executive@nowwagon.com</b>
                <br />
                <b>Contact: +91-9699854306</b> <br />
                <br />
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    <b>How do I add my vehicle to NowWagon?</b>
                        
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Upon successful registration, you can add your vehicle through
                NowWagon Operator app. What are the types of vehicles I can
                onboard to NowWagon? NowWagon allows onboarding of any
                commercial vehicle that serves for Domestic, Industrial,
                Construction, and Agriculture purposes. What are the required
                documents for onboarding my vehicle to NowWagon? Following
                documents are required
                <br />
                <b>• Vehicle image</b>
                <br />
                <b>• RC Book</b>
                <br />
                <b>• Valid Insurance Policy </b>
                <br />
                <b>• Pollution Control (PUC)</b>
                <br />
                <br />

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    <b><b>
                  Do I need to pay any registration fees while onboarding to
                  NowWagon?
                </b> </b>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    NowWagon doesn’t charge any registration fee but you need to pay
                security deposit which will be refunded in case you wish to
                cancel your registration with NowWagon family.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    <b>How do I check my earnings on NowWagon?</b>
                        
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    NowWagon provides in-app reports showcasing your weekly/monthly
                earnings.

                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    <b>How do I contact NowWagon support team?</b>
                        
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    <b>
                  {" "}
                  Contact us at:
                  <br />
                  Email: support@nowwagon.com
                  <br />
                  Mobile: +91-9699854306{" "}
                </b>
              </p>
              <br />
              <p>
                <b>Contact us at:</b>
                <br />
                <b>• Email: support@nowwagon.com</b>
                <br />
                <b>• Mobile: +91-9699854306 </b>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion> 
  


   

  


    
  </p>
  </Typography>
  </ThemeProvider>
  </GridItem>
  </Container>
  </Container>
  );
              }
