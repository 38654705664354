import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { currentUserAuth, changePassword } from "../../services/authentication/authentication";
import { activateDeactiveUser } from "../../services/users/userOnboarding";
import { useNavigate } from "react-router-dom";
import { orange } from "@material-ui/core/colors";
import { Backdrop, CircularProgress, IconButton, InputAdornment, Snackbar } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Alert from "@material-ui/lab/Alert";
import Lottie from "react-lottie";
import animationData from "../../assets/jss/lf30_editor_fvhlirkb.json";
import { TramRounded } from "@material-ui/icons";

const loaderOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: "#FF8000",
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	form: {
		width: "100%",
		marginTop: theme.spacing(6),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: "#FF8000",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#c55100",
		},
	},
}));

const theme = createMuiTheme({
	palette: {
		primary: orange,
	},
});

export default function ChangePwd() {
	const classes = useStyles();
	let navigate = useNavigate();
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [currentPassword, setCurrentPassword] = useState("");

	const [successToastOpen, setSuccessToastOpen] = useState(false);
	const [failureToastOpen, setFailureToastOpen] = useState(false);
	const [failureToastMsg, setFailureToastMsg] = useState("");
	const [openLoader, setOpenLoader] = useState(false);
	const [failureOldToastOpen, setFailureOldToastOpen] = useState(false);
	const [failureOldToastMsg, setFailureOldToastMsg] = useState("");

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);

	const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
	const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

	const handleClickShowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
	const handleMouseDownCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);

	const closeLoader = () => {
		setOpenLoader(false);
	};
	const toggleLoader = () => {
		setOpenLoader(!openLoader);
	};

	const handleClose = (event) => {
		setSuccessToastOpen(false);
		setFailureToastOpen(false);
		setFailureOldToastOpen(false);
	};

	const userProfile = JSON.parse(localStorage.getItem("USER_PROFILE"));
	const UserPassword = localStorage.getItem("USER_PASSWORD");
	let user = currentUserAuth();
	// //console.log('current user', user);

	const onChangePassword = () => {
		if (currentPassword === "") {
			setFailureOldToastOpen(true);
			setFailureOldToastMsg("Please enter old password");
			setCurrentPassword("");
			setConfirmPassword("");
			setPassword("");
			return false;
		} else if (currentPassword !== UserPassword) {
			setFailureOldToastOpen(true);
			setFailureOldToastMsg("Old password does not match!");
			setCurrentPassword("");
			setConfirmPassword("");
			setPassword("");
			return false;
		}
		if (password === "") {
			setFailureToastOpen(true);
			setFailureToastMsg("Please Enter New Password");
			return false;
		} else if (confirmPassword === "") {
			setFailureToastOpen(true);
			setFailureToastMsg("Please Enter Confirm New Password");
			return false;
		} else if (password !== confirmPassword) {
			setFailureToastOpen(true);
			setFailureToastMsg("Confirm password does not match!");

			return false;
		} else if (password.length < 6 && confirmPassword.length < 6) {
			// let regex1 = /^(?=.*[A-Za-z])(?=.*d)[A-Za-zd]{8,}$/;
			// //console.log(`regex1.test(confirmPassword) : ${regex1.test(confirmPassword)}`);
			// if (!regex1.test(confirmPassword)) {
			// 	//console.log('must include at least 1 special character');
			// }
			// //console.log('Password must be alphanumeric, of minimum 8 characters');
			setPassword("");
			setConfirmPassword("");
			setFailureOldToastMsg("");
			setFailureToastMsg("");
			return false;
		}

		toggleLoader();
		changePassword(user, password).then((response) => {
			closeLoader();
			//console.log('success', response);
			localStorage.setItem("USER_PASSWORD", password);
			toggleLoader();
			activateDeactiveUser(userProfile).then(
				(response) => {
					closeLoader();
					setSuccessToastOpen(true);
					//console.log('response profile update', response);
					setConfirmPassword("");
					setFailureOldToastMsg("");
					setCurrentPassword("");
					setConfirmPassword("");
					setPassword("");
					// navigate('/app/dashboard');
				},
				(error) => {
					//console.log(error);
				}
			);
		});
	};
	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Change Password
				</Typography>
				<ThemeProvider theme={theme}>
					<form className={classes.form} noValidate>
						<Typography component="h6" variant="h6">
							Email : {userProfile.email}
						</Typography>
						<TextField
							variant="outlined"
							margin="normal"
							require
							fullWidth
							name="OldPassword"
							label="Old Password"
							type={showCurrentPassword ? "text " : "Password"}
							id="oldpassword"
							value={currentPassword}
							autoComplete="old-password"
							onChange={(e) => {
								setCurrentPassword(e.target.value);
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowCurrentPassword}
											onMouseDown={handleMouseDownCurrentPassword}
										>
											{showCurrentPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							require
							fullWidth
							name="password"
							label="New Password"
							type={showPassword ? "text " : "Password"}
							id="password"
							min="8"
							value={password}
							autoComplete="current-password"
							onChange={(e) => {
								setPassword(e.target.value);
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>

						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="confirmPassword"
							label="Confirm Password"
							title="Password must be alphanumeric, of minimum 8 characters, must include at least 1 special character"
							type={showConfirmPassword ? "text " : "Password"}
							value={confirmPassword}
							onChange={(e) => {
								setConfirmPassword(e.target.value);
							}}
							id="confirmPassword"
							autoComplete="confirmPassword"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowConfirmPassword}
											onMouseDown={handleMouseDownConfirmPassword}
										>
											{showConfirmPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						{/* <Typography component="h9" variant="h9">
							Password must be alphanumeric, must have 8 characters and include at least 1 special character
						</Typography> */}

						<Button
							// type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={onChangePassword}
						>
							Submit
						</Button>
					</form>
				</ThemeProvider>
			</div>
			{/* <Box mt={8}>
        <Copyright />
      </Box> */}
			<Snackbar
				open={successToastOpen}
				autoHideDuration={2000}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<Alert onClose={handleClose} severity="success">
					Password reset successfully!
				</Alert>
			</Snackbar>
			<Snackbar
				open={failureToastOpen}
				autoHideDuration={2000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert onClose={handleClose} severity="error">
					{failureToastMsg}
				</Alert>
			</Snackbar>
			<Snackbar
				open={failureOldToastOpen}
				autoHideDuration={2000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert onClose={handleClose} severity="error">
					{failureOldToastMsg}
				</Alert>
			</Snackbar>
			<Backdrop className={classes.backdrop} open={openLoader} onClick={closeLoader}>
				<CircularProgress color="inherit" />
				{/* <Lottie
            options={loaderOptions}
            height={200}
            width={600}
          /> */}
			</Backdrop>
		</Container>
	);
}
