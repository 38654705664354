import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Snackbar,
  Backdrop,
  TextField,
  Button,
  MenuItem,
  Typography,
  CircularProgress,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { isAdmin, isExecutive, superAdmin } from "./AddUserView";
import { userOnBoard } from "../../services/users/userOnboarding";
import MuiAlert from "@material-ui/lab/Alert";
import { orange } from "@material-ui/core/colors";
//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../assets/jss/lf30_editor_fvhlirkb.json";
import { useSnackbar } from "notistack";

const loaderOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#FF8000",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c55100",
    },
  },
}));

const roles = [
  {
    value: "EXECUTIVE",
    label: "Executive",
  },
  {
    value: "PROVIDER",
    label: "Provider",
  },
  {
    value: "DRIVER",
    label: "Driver",
  },
];

const theme = createMuiTheme({
  palette: {
    primary: orange,
  },
});

export default function AddExecutiveView() {
  const classes = useStyles();
  let navigate = useNavigate();

  const [userRole, setUserRole] = useState(roles);
  const [open, setOpen] = useState(false);
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [failureToastOpen, setFailureToastOpen] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  // Noti snackbar handling...
  const { enqueueSnackbar } = useSnackbar();
  const showToast = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant ? variant : "info",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  const [inputValues, setInputValues] = useState({
    salutation: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    role: "",
    isActive: true,
  });

  const {
    salutation,
    firstName,
    lastName,
    middleName,
    email,
    mobileNumber,
    role,
    isActive,
  } = inputValues;

  const closeLoader = () => {
    setOpen(false);
  };
  const toggleLoader = () => {
    setOpen(!open);
  };
  const handleClose = (event) => {
    setSuccessToastOpen(false);
    setFailureToastOpen(false);
  };

  const handleChange = (name) => (event) => {
    let valueToSet = event.target.value;
    if ((name == "mobileNumber")) {
      if (valueToSet.length != 10) {
        setMobileNumberError(true);
      } else {
        setMobileNumberError(false);
      }
    }
    setInputValues({ ...inputValues, error: false, [name]: valueToSet });
  };

  const addExecutive = () => {
    /* if (
      email === "" ||
      firstName === "" ||
      mobileNumber === "" ||
      role === ""
    ) {
      setFailureToastOpen(true);
      return;
    } */
    let error = false;
    if (email == undefined || email == "") {
      error = true;
      showToast("Please enter email", "error");
    }
    if (firstName == undefined || firstName == "") {
      error = true;
      showToast("Please enter first name", "error");
    }
    if (mobileNumber == undefined || mobileNumber == "") {
      error = true;
      showToast("Please enter mobile number", "error");
    } else if (mobileNumber.length != 10) {
      error = true;
      showToast("Mobile number should be of 10 digits only", "error");
    }
    if (role == undefined || role == "") {
      error = true;
      showToast("Please enter role", "error");
    }
    if (error) {
      return;
    }

    var currentTimeStamp = new Date();
    let payload = {
      salutation: salutation,
      email: email,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      mobileNumber: mobileNumber,
      role: role,
      isDefaultPassword: true,
      isActive: true,
      lastLogin: currentTimeStamp,
      createdOn: currentTimeStamp,
      createdOnMil: currentTimeStamp.getTime()
    };
    //console.log("payload", payload);
    toggleLoader();
    userOnBoard(payload).then((data) => {
      
      //console.log("data of user onbord:", data);
      if (data !== undefined) {
        setSuccessToastOpen(true);
        navigate("/app/executiveManagement");
        setInputValues({
          ...inputValues,
          email: "",
          firstName: "",
          middleName: "",
          lastName: "",
          mobileNumber: "",
          role: "",
          salutation: "",
        });
      } else {
        setFailureToastOpen(true);
      }
    }).catch(error =>{
		//console.log(error);
		showToast(error.message, 'error');
	}).finally(() =>{
		closeLoader();
	});
  };

  useEffect(() => {
    if (isAdmin()) {
      let filteredRoles = [];
      filteredRoles.push({
        value: "EXECUTIVE",
        label: "Executive",
      });
      setUserRole(filteredRoles);
    }
    if (isExecutive()) {
      let filteredRoles = [];
      filteredRoles.push({
        value: "EXECUTIVE",
        label: "Executive",
      });
      setUserRole(filteredRoles);
    }
    if (superAdmin()) {
      let filteredRoles = [];
      filteredRoles.push({
        value: "EXECUTIVE",
        label: "Executive",
      });
      setUserRole(filteredRoles);
    }
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={2}></GridItem>
      <GridItem xs={12} sm={12} md={8}>
        <form className={classes.root} noValidate autoComplete="on">
          <Typography
            component="h2"
            variant="h2"
            style={{ textAlign: "center", padding: 20 }}
          >
            Add Office Executive
          </Typography>
          <GridContainer>&nbsp;</GridContainer>
          <ThemeProvider theme={theme}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  //   required
                  fullWidth
                  name="salutation"
                  label="Salutation"
                  type="text"
                  id="salutation"
                  select
                  value={salutation}
                  onChange={handleChange("salutation")}
                >
                  <MenuItem value="Mr">Mr</MenuItem>
                  <MenuItem value="Mrs">Mrs</MenuItem>
                  <MenuItem value="Ms">Ms</MenuItem>
                </TextField>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="firstName"
                  label="First Name"
                  type="firstName"
                  id="firstName"
                  value={firstName}
                  onChange={handleChange("firstName")}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="middleName"
                  label="Middle Name"
                  type="text"
                  id="middleName"
                  value={middleName}
                  onChange={handleChange("middleName")}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={handleChange("lastName")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  label="E-mail"
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleChange("email")}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  size="small"
                  inputProps={{ maxLength: 10 }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 10,
                      maxLength: 10,
                    },
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="mobileNumber"
                  label="Mobile Number"
                  id="mobileNumber"
                  value={mobileNumber}
                  type="number"
				  error={mobileNumberError}
                  onChange={handleChange("mobileNumber")}
				  helperText={mobileNumberError ? 'Mobile number should be 10 digits' : ''}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Role"
                  name="role"
                  id="role"
                  select
                  value={role}
                  onChange={handleChange("role")}
                >
                  {userRole.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </GridItem>
            </GridContainer>
            <GridContainer>&nbsp;</GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}></GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Button
                  variant="contained"
                  className={classes.button}
                  fullWidth="true"
                  onClick={addExecutive}
                >
                  Add Executive
                </Button>
              </GridItem>
            </GridContainer>
          </ThemeProvider>
        </form>
      </GridItem>
      <Snackbar
        open={successToastOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success">
          User Added Successully.
        </Alert>
      </Snackbar>
      <Snackbar
        open={failureToastOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error">
          Please Fill All Mandetory Feilds !
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={open} onClick={closeLoader}>
        {/* <CircularProgress color="inherit" /> */}
        <Lottie options={loaderOptions} height={200} width={600} />
      </Backdrop>
    </GridContainer>
  );
}
