import React, { useEffect, useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
	Container,
	Grid,
	makeStyles,
	CircularProgress,
	Backdrop,
	Typography,
	ThemeProvider,
	createMuiTheme,
	MenuItem,
	TextField,
	Button,
	TableContainer,
	TableHead,
	Divider,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";

//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import { orange } from "@material-ui/core/colors";
import GridContainer from "../../components/Grid/GridContainer";
import stateDistrictData from "../../allStates-Districts.json";
import { getAllProvider } from "../../services/users/userOnboarding";
import { getAllVehicles } from "../../services/vehicle/Vehicles";
import dayjs from "dayjs";
import { getAllDrivers } from "../../services/drivers/Drivers";
import { getAllRides } from "../../services/ride/Ride";
import { getDescendingOrderedList } from "../Helper/Utils";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import * as XLSX from 'xlsx';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	title: {
		fontSize: 25,
		textAlign: "center",
	},
	customTableContainer: {
		overflowX: "initial",
	},

	tableCell: {
		Width: 100,
	},
	head: {
		backgroundColor: "#FFA93F",
		position: "sticky",
		top: 0,
		color: "#fff",
	},
	button: {
		margin: theme.spacing(1),
		backgroundColor: "#FF8000",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#c55100",
		},
	},
}));
const theme = createMuiTheme({
	palette: {
		primary: orange,
	},
});

export default function OperatorReport() {
	const classes = useStyles();
	let navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [stateData, setStateData] = useState([]);
	const [districts, setDistricts] = useState([]);
	const [allProvider, setAllProvider] = useState([]);
	const [vehicleList, setVehicleList] = useState([]);
	const [allVehicle, setAllVehicle] = useState([]);
	const [allProviderList, setAllProviderList] = useState([]);
	const [mainProviderList, setMainProviderList] = useState([]);
	const [allDriverList, setAllDriverList] = useState([]);
	const [allRidesData, setAllRidesData] = useState([]);
	const [searchApplied, setSearchApplied] = useState(false);
	const [inputValues, setInputValues] = useState({
		state: "",
		city: "",
		operator: "",
		vehicleNumber: "",
		fromDate: "",
		toDate: "",
	});
	const { state, city, operator, vehicleNumber, fromDate, toDate } = inputValues;
	const getInitalValues = () => {
		return {
			state: "",
			city: "",
			operator: "",
			vehicleNumber: "",
			fromDate: "",
			toDate: "",
		};
	};
	const handleChange = (name) => (event) => {
		let valueToSet = event.target.value;
		setInputValues({ ...inputValues, [name]: valueToSet });
		if (name === "state") {
			Object.keys(stateDistrictData).forEach((key) => {
				if (stateDistrictData[key].state == valueToSet) {
					setDistricts(stateDistrictData[key].districts);
				}
			});
		}
		if (name === "operator") {
			let vehicles = vehicleList.filter((element) => element.ownerId == valueToSet);
			let vehicleNumberList = [];
			vehicles.forEach((vehicle) => {
				vehicleNumberList.push(vehicle.vehicleNumber);
			});
			setAllVehicle(vehicleNumberList);
		}
	};
	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message, variant) => {
		enqueueSnackbar(message, {
			variant: variant ? variant : "info",
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		});
	};
	const handleSearch = () => {
		if (fromDate !== "" && toDate !== "") {
			let lastHourToDate = new Date(toDate);
			lastHourToDate.setHours(23, 59, 0);
			let lastHourToDateMil = lastHourToDate.getTime();
			let fromDateMil = new Date(fromDate).getTime();
			let toDateMil = new Date(toDate).getTime();
			if (fromDateMil > lastHourToDateMil) {
				showToast("Invalid Date Selected", "error");
				return;
			}
		}
		let filteredData = mainProviderList;
		if (operator !== "") {
			filteredData = filteredData.filter((element) => element.businessInfo.officeEmail == operator);
		}
		if (vehicleNumber !== "") {
			let vehicles = vehicleList.filter((element) => element.vehicleNumber == vehicleNumber);
			filteredData = filteredData.filter((element) => element.businessInfo.officeEmail == vehicles[0].ownerId);
		}
		setAllProviderList(getDescendingOrderedList(filteredData));
		setSearchApplied(true);
	};

	useEffect(() => {
		let allData = [];
		Object.keys(stateDistrictData).forEach((key) => {
			allData.push(stateDistrictData[key].state);
		});
		setStateData(allData);

		getAllProvider().then((response) => {
			setMainProviderList(response);
			setAllProviderList(getDescendingOrderedList(response));
			let providerData = [];
			Object.keys(response).forEach((key) => {
				providerData.push({
					operatorName: response[key].businessInfo?.businessName,
					operatorEmail: response[key].businessInfo.officeEmail,
				});
			});
			setAllProvider(providerData);
		});

		getAllVehicles().then((response) => {
			let vehicleData = [];
			Object.keys(response).forEach((key) => {
				vehicleData.push(response[key].vehicleNumber);
			});
			setVehicleList(getDescendingOrderedList(response));
			setAllVehicle(vehicleData);
		});
		getAllDrivers().then((response) => {
			setAllDriverList(getDescendingOrderedList(response));
		});
		getAllRides().then((response) => {
			setAllRidesData(getDescendingOrderedList(response));
		});
	}, []);
	const getTotalVehiclesAssociated = (email) => {
		let count = 0;
		let vehicleAssoc = vehicleList.filter((vehicle) => vehicle.ownerId === email);
		if (vehicleAssoc && vehicleAssoc?.length > 0) {
			count = vehicleAssoc?.length;
		}
		return count;
	};
	const getTotalDriversAssociated = (email) => {
		let count = 0;
		let driverAssoc = getDriversForOperator(email);
		if (driverAssoc && driverAssoc?.length > 0) {
			count = driverAssoc?.length;
		}
		return count;
	};
	const getDriversForOperator = (email) => {
		return allDriverList.filter((driver) => driver.ownerId === email);
	};
	const getTotalRevenueGenerated = (email) => {
		let totalRevenue = 0;
		let driverAssociated = getDriversForOperator(email);
		let driverAssociatedEmails = [];
		driverAssociated.forEach((driver) => {
			driverAssociatedEmails.push(driver.email);
		});
		if (driverAssociated && driverAssociatedEmails && driverAssociatedEmails?.length > 0) {
			let filteredData = allRidesData;
			if (searchApplied && fromDate !== "") {
				filteredData = filteredData.filter((ride) => new Date(fromDate).getTime() <= ride.tripDateMil);
			}
			let lastHourToDate = new Date(toDate);
			lastHourToDate.setHours(23, 59, 0);
			let lastHourToDateMil = lastHourToDate.getTime();
			if (searchApplied && toDate !== "") {
				filteredData = filteredData.filter((ride) => lastHourToDateMil >= ride.tripDateMil);
			}
			filteredData.forEach((ride) => {
				if (ride.driverEmail && driverAssociatedEmails.includes(ride.driverEmail)) {
					totalRevenue = parseInt(ride?.amount) + totalRevenue;
				}
			});
		}
		return totalRevenue;
	};
	const closeLoader = () => {
		setOpen(false);
	};
	const toggleLoader = () => {
		setOpen(!open);
	};

	const exportToExcel = () => {
		let excelElements = [];
		allProviderList.map((excel,index)=>{
			let excelElement = {
				"Sr.No": index + 1,
				"Operator Name":excel?.businessInfo?.businessName,
				"Operator Email id":excel?.businessInfo.officeEmail,
				"Contact Number":excel?.contactDetails?.contactPersonMobileNumber || "-",
				"Number of Vehicles associated": getTotalVehiclesAssociated(excel?.businessInfo.officeEmail),
				"Number of Drivers associated": getTotalDriversAssociated(excel?.businessInfo.officeEmail),
				"Total Operator Revenue":getTotalRevenueGenerated(excel?.businessInfo.officeEmail),
			  };
			  excelElements.push(excelElement);
			  return excel;
		});
		 const wb = XLSX.utils.book_new();
		 const ws1 = XLSX.utils.json_to_sheet(excelElements);
		 XLSX.utils.book_append_sheet(wb, ws1, 'Operator Status');
		 XLSX.writeFile(wb, `Operator report.xlsx`);
	};
	return (
		<Container>
			<Container maxWidth={false}>
				<GridItem xs={12} sm={12} md={12}>
					<Typography className={classes.title}> Operator Report </Typography>
					<ThemeProvider theme={theme}>
						<GridContainer>
							{/* <GridItem xs={12} sm={12} md={3}>
                                <TextField
                                    size='small'
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="state"
                                    label="State"
                                    type="text"
                                    id="state"
                                    select
                                    value={state}
                                    onChange={handleChange('state')}
                                >
                                    {stateData.map((value,index)=>(
                                         <MenuItem value={value} key={index}>{value}</MenuItem>   
                                    ))}
                                </TextField>
                            </GridItem> */}
							{/* <GridItem xs={12} sm={12} md={3}>
                                <TextField
                                    size='small'
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="city"
                                    label="City"
                                    type="text"
                                    id="city"
                                    select
                                    value={city}
                                    onChange={handleChange('city')}
                                >
                                    {districts.map((value,index)=>(
                                         <MenuItem value={value} key={index}>{value}</MenuItem>   
                                    ))}
                                </TextField>
                            </GridItem> */}

							<GridItem xs={12} sm={12} md={3}>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="fromDate"
									label="From Date"
									type="date"
									id="fromDate"
									value={fromDate}
									onChange={handleChange("fromDate")}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="toDate"
									label="To Date"
									type="date"
									id="toDate"
									value={toDate}
									onChange={handleChange("toDate")}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="operator"
									label="Operator"
									type="text"
									id="operator"
									select
									value={operator}
									onChange={handleChange("operator")}
								>
									{allProvider.map((value, index) => (
										<MenuItem value={value.operatorEmail} key={value.operatorEmail}>
											{value.operatorName}
										</MenuItem>
									))}
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="vehicleNumber"
									label="Vehicle Number"
									type="text"
									id="vehicleNumber"
									select
									value={vehicleNumber}
									onChange={handleChange("vehicleNumber")}
								>
									{allVehicle.map((value, index) => (
										<MenuItem value={value} key={index}>
											{value}
										</MenuItem>
									))}
								</TextField>
							</GridItem>
							{/* <GridItem xs={12} sm={12} md={3}>
                                <Button style={{ marginTop: 20 }} variant="contained" className={classes.button}
                                    fullWidth="true" >Clear</Button>
                            </GridItem> */}

							<GridItem xs={12} sm={12} md={2}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={() => handleSearch()}
								>
									Search
								</Button>
							</GridItem>
							<GridItem xs={12} sm={12} md={2}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={() => {
										setInputValues(getInitalValues());
										setAllProviderList(getDescendingOrderedList(mainProviderList));
										setSearchApplied(false);
									}}
								>
									Clear
								</Button>
							</GridItem>
							<GridItem xs={12} sm={12} md={2}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={(e) => {
										exportToExcel("Excel Export")
									}}
									startIcon={<FileDownloadOutlinedIcon fontSize="small" />}

								>
									Export
								</Button>
							</GridItem>
						</GridContainer>
						<GridContainer style={{ paddingTop: 20 }}>&nbsp;</GridContainer>
						<Divider></Divider>
						<GridContainer>
							<Table className={classes.table}>
								<TableBody>
									<TableRow>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Sr No.
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Operator Name
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Operator Email ID
											</Typography>
										</TableCell>

										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Contact Number
											</Typography>
										</TableCell>

										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Number of vehicles associated
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Number of Drivers associated
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Total Operator Revenue
											</Typography>
										</TableCell>
										{/* <TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Operator Category
											</Typography>
										</TableCell> */}
									</TableRow>

									{allProviderList.map((provider, index) => (
										<TableRow className={classes.tableRow} key={index}>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{index + 1}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{provider.businessInfo?.businessName}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{provider.businessInfo.officeEmail}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom align="center">
													{provider?.contactDetails?.contactPersonMobileNumber || "-"}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom align="center">
													{getTotalVehiclesAssociated(provider.businessInfo.officeEmail)}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom align="center">
													{getTotalDriversAssociated(provider.businessInfo.officeEmail)}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{getTotalRevenueGenerated(provider.businessInfo.officeEmail)}
												</Typography>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
							{/* </TableContainer> */}
						</GridContainer>
					</ThemeProvider>
				</GridItem>
			</Container>
			<Backdrop className={classes.backdrop} open={open} onClick={closeLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Container>
	);
}
