import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Typography } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import data from "../../allStates-Districts.json";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import dayjs from "dayjs";
import {
  Container,
  Grid,
  makeStyles,
  CircularProgress,
  Backdrop,
  ThemeProvider,
  createMuiTheme,
  MenuItem,
  TextField,
  Button,
  TableContainer,
  TableHead,
  Divider,
} from "@material-ui/core";
import Page from "../../components/Page";
import {
  activateDeactiveUser,
  getAllCustomer,
  getAllExecutive,
  getAllProviderAndDriver,
  getAllUser,
  updateUserProfile2,
} from "../../services/users/userOnboarding";
import {
  getAllMetadata,
  getAllMetadataWithSubMetadata,
  postData,
} from "../../services/metadata/metadata";
import { TrendingUpRounded } from "@material-ui/icons";
import FbApp from "../../config/FbApp";
import { useLocation } from "react-router-dom";
import { orange } from "@material-ui/core/colors";
import stateDistrictData from "../../allStates-Districts.json";
import { getAllVehicles } from "../../services/vehicle/Vehicles";
import { getAllRides } from "../../services/ride/Ride";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  title: {
    fontSize: 25,
    textAlign: "center",
  },
  customTableContainer: {
    overflowX: "initial",
  },

  tableCell: {
    Width: 100,
  },
  head: {
    backgroundColor: "#FFA93F",
    position: "sticky",
    top: 0,
    color: "#fff",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#FF8000",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c55100",
    },
  },
}));
const theme = createMuiTheme({
  palette: {
    primary: orange,
  },
});

function createData(name, Executive, Operator, Driver, Vehicle) {
  return { name, Executive, Operator, Driver, Vehicle };
}

function createtripData(name, Trip, Operator, Driver, Vehicle) {
  return { name, Trip, Operator, Driver, Vehicle };
}

export default function Dashboard() {
  //export default Dashboard;
  const classes = useStyles();
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [inputValues, setInputValues] = useState({
    state: "",
    city: "",
    fromDate: "",
    toDate: "",
  });
  const { state, city, fromDate, toDate } = inputValues;
  const [stateData, setStateData] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [executive, setExecutive] = useState();
  const [Operator, setOperator] = useState();
  const [driver, setDriver] = useState();
  const [vehicle, setVehicle] = useState();
  const [activeExecutive, setActiveExecutive] = useState();
  const [inActiveExecutive, setInActiveExecutive] = useState();
  const [activeOperator, setActiveOperator] = useState();
  const [inActiveOperator, setInActiveOperator] = useState();
  const [activeDriver, setActiveDriver] = useState();
  const [inActiveDriver, setInActiveDriver] = useState();
  const [activeVehicle, setActiveVehicle] = useState();
  const [inActiveVehicle, setInActiveVehicle] = useState();
  const [executiveAllList, setExecutiveAllList] = useState();
  const [OperatorAllList, setOperatorAllList] = useState();
  const [driverAllList, setDriverAllList] = useState();
  const [vehicleAllList, setVehicleAllList] = useState();
  const [customer, setCustomer] = useState();
  const [customerAllList, setCustomerAllList] = useState();
  const [rideSummaryData, setRideSummaryData] = useState({
    totalRides: 0,
    openRides: 0,
    pendingRides: 0,
    intransitRides: 0,
    completedRides: 0,
    cancelledRides: 0,
  });

  // Noti snackbar handling...
  const { enqueueSnackbar } = useSnackbar();
  const showToast = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant ? variant : "info",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };
  //   //console.log( dayjs.unix(provider[0].createdOn.seconds).format('YYYY-MM-DD'))

  // 	if(fromDate > dayjs.unix(provider[0].createdOn.seconds).format('YYYY-MM-DD')){
  //         //console.log("fromDate",fromDate)
  // 	}

  // //console.log("Allprovider",dayjs.unix(provider[0].createdOn.seconds).format('YYYY-MM-DD'))
  //  dayjs.unix(provider[0].createdOn.seconds).format('YYYY-MM-DD');

  const rows = [
    // (100 * partialValue) / totalValue
    // createData('Executive', executive?.length, activeExecutive?.length, inActiveExecutive?.length, activeExecutive?.length / 100),
    createData(
      "Executive",
      executive?.length,
      activeExecutive?.length,
      inActiveExecutive?.length,
      ((100 * activeExecutive?.length) / executive?.length).toFixed(0)
    ),
    createData(
      "Operator",
      Operator?.length,
      activeOperator?.length,
      inActiveOperator?.length,
      ((100 * activeOperator?.length) / Operator?.length).toFixed(0)
    ),
    createData(
      "Driver ",
      driver?.length,
      activeDriver?.length,
      inActiveDriver?.length,
      ((100 * activeDriver?.length) / driver?.length).toFixed(0)
    ),
    createData(
      "Vehicle ",
      vehicle?.length,
      activeVehicle?.length,
      inActiveVehicle?.length,
      ((100 * activeVehicle?.length) / vehicle?.length).toFixed(0)
    ),
    createData(
      "Customer",
      customer?.length,
      customer?.length,
      0,
      ((100 * customer?.length) / customer?.length).toFixed(0)
    ),
  ];

  const handleChange = (name) => (event) => {
    let valueToSet = event.target.value;
    // //console.log("ALL",valueToSet)
    setInputValues({ ...inputValues, error: false, [name]: valueToSet });

    if (name === "state") {
      Object.keys(stateDistrictData).forEach((key) => {
        if (stateDistrictData[key].state == valueToSet) {
          setDistricts(stateDistrictData[key].districts);
          // //console.log("districts", stateDistrictData[key].districts);
        }
      });
    }
  };

  const handleSearch = () => {
    if (state == "") {
      alert("Please Select State");
      return;
    } else if (city == "") {
      alert("Please Select City");
      return;
    }

    if (state !== "") {
      let stateWiseOperator = OperatorAllList.filter(
        (element) => element.businessInfo.businessState == state
      );
      setOperator(stateWiseOperator);
      getOperator(stateWiseOperator);
      let stateWiseDriver = driverAllList.filter(
        (element) => element.state == state
      );
      setDriver(stateWiseDriver);
      getDriver(stateWiseDriver);
      let stateWiseVehicle = vehicleAllList.filter(
        (element) => element.state == state
      );
      setVehicle(stateWiseVehicle);
      getVehicle(stateWiseVehicle);
      let stateWiseCustomer = customerAllList.filter(
        (element) => element.state == state
      );
      setCustomer(stateWiseCustomer);
      // let stateWiseRide = rideAllList.filter(element => element.state == state)
      //   setRide(stateWiseRide)
      //   getRide(stateWiseRide)

      if (city !== "") {
        let cityWiseOperator = stateWiseOperator.filter(
          (element) => element.businessInfo.businessCity == city
        );
        setOperator(cityWiseOperator);
        getOperator(cityWiseOperator);
        let cityWiseDriver = stateWiseDriver.filter(
          (element) => element.city == city
        );
        setDriver(cityWiseDriver);
        getDriver(cityWiseDriver);
        let cityWiseVehicle = stateWiseVehicle.filter(
          (element) => element.city == city
        );
        setVehicle(cityWiseVehicle);
        getVehicle(cityWiseVehicle);
        let cityWiseCustomer = stateWiseCustomer.filter(
          (element) => element.city == city
        );
        setCustomer(cityWiseCustomer);
        // let cityWiseRide = stateWiseRide.filter(element => element.city == city)
        // setRide(cityWiseRide)
        // getRide(cityWiseRide)
        // if (fromDate !== '') {
        // 	let fromDateWiseProvider = cityWiseProvider.filter(element => element.dayjs.unix(cityWiseProvider.createdOn.seconds).format('YYYY-MM-DD') > fromDate)
        // 	//console.log("fromDateWiseProvider", fromDateWiseProvider)
        // 	setProvider(fromDateWiseProvider)
        // 	getProvider(fromDateWiseProvider)
        // 	let fromDateWiseDriver = cityWiseDriver.filter(element => element.city == city)
        // 	setDriver(fromDateWiseDriver)
        // 	getDriver(fromDateWiseDriver)
        // 	let fromDateWiseVehicle = cityWiseVehicle.filter(element => element.city == city)
        // 	setVehicle(fromDateWiseVehicle)
        // 	getVehicle(fromDateWiseVehicle)
        // 	let fromDateWiseCustomer = cityWiseCustomer.filter(element => element.city == city)
        // 	setCustomer(fromDateWiseCustomer)
        // 	// let fromDateWiseRide = cityWiseRide.filter(element => element.city == city)
        // 	// setRide(fromDateWiseRide)
        // 	// getRide(fromDateWiseRide)

        // }
      }
    }
  };

  const getExecutive = (executive) => {
    let activeExecutive = executive.filter(
      (element) => element.isActive == true
    );
    let inActiveExecutive = executive.filter(
      (element) => element.isActive == false
    );
    setActiveExecutive(activeExecutive);
    setInActiveExecutive(inActiveExecutive);
  };

  const getOperator = (Operator) => {
    let activeOperator = Operator.filter((element) => element.isActive == true);
    let inActiveOperator = Operator.filter(
      (element) => element.isActive == false
    );
    setActiveOperator(activeOperator);
    setInActiveOperator(inActiveOperator);
  };
  const getDriver = (driver) => {
    let activeDriver = driver.filter((element) => element.isActive == true);
    let inActiveDriver = driver.filter((element) => element.isActive == false);
    setActiveDriver(activeDriver);
    setInActiveDriver(inActiveDriver);
  };

  const getVehicle = (vehicle) => {
    let ActiveVehicle = vehicle.filter((element) => element.isActive == true);
    let inActiveVehicle = vehicle.filter(
      (element) => element.isActive == false
    );
    setActiveVehicle(ActiveVehicle);
    setInActiveVehicle(inActiveVehicle);
  };
  useEffect(async () => {
    let allData = [];
    Object.keys(stateDistrictData).forEach((key) => {
      allData.push(stateDistrictData[key].state);
    });
    // //console.log("stateData", allData);
    setStateData(allData);
    let user = JSON.parse(await localStorage.getItem("USER_PROFILE"));
    if (
      user &&
      user != null &&
      (user.role == "ADMIN" || user.role == "EXECUTIVE")
    ) {
      //   showToast(`Welcome to NowWagon ${user.firstName} ${user.lastName}!`, 'success');
    }
  }, []);

  useEffect(() => {
    getAllExecutive().then((executive) => {
      setExecutive(executive);
      getExecutive(executive);
      setExecutiveAllList(executive);
    });
    getAllProviderAndDriver().then((OperatorAndDriver) => {
      let Operator = OperatorAndDriver.filter(
        (element) =>
          element.role == "PROVIDER" || element.role == "PROVIDER-DRIVER"
      );
      let driver = OperatorAndDriver.filter(
        (element) =>
          element.role == "DRIVER" || element.role == "PROVIDER-DRIVER"
      );
      setOperator(Operator);
      setOperatorAllList(Operator);
      setDriver(driver);
      setDriverAllList(driver);
      getOperator(Operator);
      getDriver(driver);
    });
    getAllVehicles().then((vehicle) => {
      setVehicle(vehicle);
      setVehicleAllList(vehicle);
      getVehicle(vehicle);
    });
    getAllRides().then((ride) => {
      console.log(ride);
      let openRides =
        ride.filter((element) => element.rideStatus === "Pending") || [];
      let pendingRides =
        ride.filter((element) => element.rideStatus === "Accepted") || [];
      let intransitRides =
        ride.filter(
          (element) =>
            element.rideStatus === "In-transit" ||
            element.rideStatus === "Payment Due"
        ) || [];
      let completedRides =
        ride.filter((element) => element.rideStatus === "Completed") || [];
      let cancelledRides =
        ride.filter((element) => element.rideStatus === "Cancelled") || [];
      let data = {
        totalRides:
          openRides?.length +
          pendingRides?.length +
          intransitRides?.length +
          completedRides?.length,
        openRides: openRides?.length,
        pendingRides: pendingRides.length,
        intransitRides: intransitRides?.length,
        completedRides: completedRides?.length,
        cancelledRides: cancelledRides?.length,
      };
      setRideSummaryData({ ...data });
    });
    getAllCustomer().then((customer) => {
      setCustomer(customer);
      setCustomerAllList(customer);
    });
  }, [setExecutive, setOperator, setDriver, setVehicle]);

  const closeLoader = () => {
    setOpen(false);
  };
  const toggleLoader = () => {
    setOpen(!open);
  };

  return (
    <Container>
      <Container maxWidth={false}>
        <GridItem xs={12} sm={12} md={12}>
          <Typography className={classes.title}> DASHBOARD </Typography>
          <ThemeProvider theme={theme}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="state"
                  label="State"
                  type="text"
                  id="state"
                  select
                  value={state}
                  onChange={handleChange("state")}
                >
                  {/* {stateData.map((value,index)=>(
                                       <MenuItem value={value} key={index}>{value}</MenuItem>   
                                  ))} */}
                  <MenuItem value="Maharashtra">Maharashtra</MenuItem>
                </TextField>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="city"
                  label="City"
                  type="text"
                  id="city"
                  select
                  value={city}
                  onChange={handleChange("city")}
                >
                  <MenuItem value="Pune">Pune</MenuItem>
                </TextField>
              </GridItem>
              {/* <GridItem xs={12} sm={12} md={3}>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									size='small'
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="fromDate"
									label="From Date"
									type='date'
									id="fromDate"
									value={fromDate}
									onChange={handleChange('fromDate')}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									size='small'
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="toDate"
									label="To Date"
									type="date"
									id="toDate"

									value={toDate}
									onChange={handleChange('toDate')}
								/>
							</GridItem> */}
              {/* <GridItem xs={12} sm={12} md={3}>
                              <Button style={{ marginTop: 20 }} variant="contained" className={classes.button}
                                  fullWidth="true" >Clear</Button>
                          </GridItem> */}
              <GridItem xs={12} sm={12} md={3}>
                <Button
                  style={{ marginTop: 20 }}
                  variant="contained"
                  className={classes.button}
                  fullWidth="true"
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </GridItem>
            </GridContainer>

            <GridContainer style={{ paddingTop: 20 }}>&nbsp;</GridContainer>

            <Divider></Divider>
          </ThemeProvider>
        </GridItem>

        <TableContainer component={Paper}>
          <Typography className={classes.title}>
            {" "}
            Operational Summary{" "}
          </Typography>
          <ThemeProvider theme={theme}></ThemeProvider>
          <Table className={classes.table} aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Entity</b>
                </TableCell>
                <TableCell align="center">
                  <b>Total</b>
                </TableCell>
                <TableCell align="center">
                  <b>Active</b>
                </TableCell>
                <TableCell align="center">
                  <b>In-Active</b>
                </TableCell>
                <TableCell align="center">
                  <b>Active%</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="center">{row.Executive}</TableCell>
                  <TableCell align="center">{row.Operator}</TableCell>
                  <TableCell align="center">{row.Driver}</TableCell>
                  <TableCell align="center">
                    {row.Vehicle != "NaN" ? `${row.Vehicle}%` : `0`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      <Container>
        <table>
          <TableCell align="center">
            <b>
              <u></u>
            </b>
          </TableCell>
        </table>
      </Container>

      <Container>
        <TableContainer component={Paper}>
          <Typography className={classes.title}> Ride Summary </Typography>
          <ThemeProvider theme={theme}></ThemeProvider>
          <Table className={classes.table} aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Particular</b>
                </TableCell>
                <TableCell align="center">
                  <b>Total</b>
                </TableCell>
                <TableCell align="center">
                  <b>Pending&nbsp;</b>
                </TableCell>
                <TableCell align="center">
                  <b>Accepted&nbsp;</b>
                </TableCell>
                <TableCell align="center">
                  <b>In-Transit&nbsp;</b>
                </TableCell>
                <TableCell align="center">
                  <b>Completed&nbsp;</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {triprows.map((row) => (
							
							))} */}
              <TableRow key={"Rides"}>
                <TableCell component="th" scope="row">
                  {"Rides"}
                </TableCell>
                <TableCell align="center">
                  {rideSummaryData?.totalRides}
                </TableCell>
                <TableCell align="center">
                  {rideSummaryData?.openRides}
                </TableCell>
                <TableCell align="center">
                  {rideSummaryData?.pendingRides}
                </TableCell>
                <TableCell align="center">
                  {rideSummaryData?.intransitRides}
                </TableCell>
                <TableCell align="center">
                  {rideSummaryData?.completedRides}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Backdrop className={classes.backdrop} open={open} onClick={closeLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
