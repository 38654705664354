import FbApp from "../../config/FbApp";
import { DateTime } from "luxon";

export default function Ride() {}

export const getAllRides = async () => {
	const db = FbApp.firestore();
	const rideRef = db.collection("RIDE_REQUESTS");
	const snapshot = await rideRef.get();
	const ride = [];
	snapshot.forEach((doc) => {
		const document = doc.data();
		const jstripDate = document?.tripDate?.toDate();
		document.tripDate = DateTime.fromJSDate(jstripDate).toISODate();
		ride.push(document);
	});
	return ride;
};
