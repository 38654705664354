import React, { useEffect, useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
	Container,
	Grid,
	makeStyles,
	CircularProgress,
	Backdrop,
	Typography,
	ThemeProvider,
	createMuiTheme,
	MenuItem,
	TextField,
	Button,
	TableContainer,
	TableHead,
	Divider,
	Chip,
} from "@material-ui/core";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import _ from "lodash";

//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import { orange } from "@material-ui/core/colors";
import GridContainer from "../../components/Grid/GridContainer";
import stateDistrictData from "../../allStates-Districts.json";
import { getAllProvider } from "../../services/users/userOnboarding";
import { getAllVehicles } from "../../services/vehicle/Vehicles";
import { useSnackbar } from "notistack";

import dayjs from "dayjs";
import { getDescendingOrderedList } from "../Helper/Utils";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import * as XLSX from 'xlsx';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	title: {
		fontSize: 25,
		textAlign: "center",
	},
	customTableContainer: {
		// overflowX: "overflowX",
		maxHeight: 670,
	},
	tableCell: {
		minWidth: 100,
		textAlign: "center",
	},
	titleColor: {
		backgroundColor: "#FFA93F",
		position: "sticky",
		top: 0,
		color: "#fff",
		textAlign: "center",
		fontSize: 16,
		// height: 20,
	},
	tableRow: {
		minWidth: 20,
	},
	table: {
		position: "sticky",
	},
	button: {
		margin: theme.spacing(1),
		backgroundColor: "#FF8000",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#c55100",
		},
	},
}));
const theme = createMuiTheme({
	palette: {
		primary: orange,
	},
});

export default function InsuranceReport() {
	const classes = useStyles();
	let navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [allProvider, setAllProvider] = useState([]);
	const [allVehicle, setAllVehicle] = useState([]);
	const [allVehicleList, setAllVehicleList] = useState();
	const [mainVehicleList, setMainVehicleList] = useState([]);

	const [inputValues, setInputValues] = useState({
		operator: "",
		vehicleNumber: "",
		fromDate: "",
		toDate: "",
	});
	const { operator, vehicleNumber, fromDate, toDate } = inputValues;
	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message, variant) => {
		enqueueSnackbar(message, {
			variant: variant ? variant : "info",
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		});
	};
	const handleChange = (name) => (event) => {
		let valueToSet = event.target.value;
		setInputValues({ ...inputValues, [name]: valueToSet });
		if (name === "operator") {
			let vehicles = allVehicleList.filter((element) => element.ownerId == valueToSet);
			let vehicleNumberList = [];
			vehicles.forEach((vehicle) => {
				vehicleNumberList.push(vehicle.vehicleNumber);
			});
			setAllVehicle(vehicleNumberList);
		}
	};

	const onHandleButton = () => {
		if (!fromDate && !toDate && !operator && !vehicleNumber) {
			showToast("Please enter filter criteria", "error");
		}
		if (fromDate !== "" && toDate !== "") {
			let fromDateMil = new Date(fromDate).getTime();
			let lastHourToDate = new Date(toDate);
			lastHourToDate.setHours(23, 59, 0);
			let lastHourToDateMil = lastHourToDate.getTime();
			if (fromDateMil > lastHourToDateMil) {
				showToast("Invalid Date Selected", "error");
				return;
			}
		}
		let fileteredVehicle = [...mainVehicleList];
		if (fromDate && fromDate !== "") {
			let fromTime = moment(fromDate).toDate().getTime();
			fileteredVehicle = fileteredVehicle.filter((element) => element.insuraceStartDateMil >= fromTime);
		}
		let lastHourToDate = new Date(toDate);
		lastHourToDate.setHours(23, 59, 0);
		let lastHourToDateMil = lastHourToDate.getTime();
		if (toDate && toDate !== "") {
			fileteredVehicle = fileteredVehicle.filter((element) => element.insuranceExpireDateMil <= lastHourToDateMil);
		}
		if (operator && operator != "") {
			fileteredVehicle = fileteredVehicle.filter((element) => element.ownerId === operator);
		}
		if (vehicleNumber && vehicleNumber != "") {
			fileteredVehicle = fileteredVehicle.filter((element) => element.vehicleNumber == vehicleNumber);
		}
		setAllVehicleList(getDescendingOrderedList(fileteredVehicle));
	};

	const clearFilters = () => {
		setInputValues({
			operator: "",
			vehicleNumber: "",
			fromDate: "",
			toDate: "",
		});
		setAllVehicleList(getDescendingOrderedList(mainVehicleList));
	};

	useEffect(() => {
		getAllProvider().then((response) => {
			let providerData = [];
			Object.keys(response).forEach((key) => {
				providerData.push({
					operatorName: response[key].businessInfo?.businessName,
					operatorEmail: response[key].businessInfo.officeEmail,
				});
			});
			setAllProvider(providerData);
		});

		getAllVehicles().then((response) => {
			// let vehicleData = []
			let vehicleData = _.orderBy(response, "insuraceStartDateMil", "desc");
			let vehicleNumbers = [];
			Object.keys(response).forEach((key) => {
				vehicleNumbers.push(response[key].vehicleNumber);
			});
			setAllVehicleList(getDescendingOrderedList(response));
			setAllVehicle([...vehicleNumbers]);
			setMainVehicleList([...vehicleData]);
		});
	}, []);

	const closeLoader = () => {
		setOpen(false);
	};
	const toggleLoader = () => {
		setOpen(!open);
	};
	const getInsuranceStatus = (insuraceStartDateMil, insuranceExpireDateMil) => {
		if (
			insuraceStartDateMil === "" ||
			insuranceExpireDateMil === "" ||
			isNaN(insuraceStartDateMil) ||
			isNaN(insuranceExpireDateMil)
		) {
			return (
				<Chip
					label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
					variant="outlined"
					style={{
						backgroundColor: "orange",
						color: "white",
						fontWeight: "bold",
					}}
				/>
			);
		}
		if (insuraceStartDateMil !== "" && insuranceExpireDateMil !== "" && new Date().getTime() < insuranceExpireDateMil) {
			return (
				<Chip
					label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Active&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
					variant="outlined"
					style={{
						backgroundColor: "green",
						color: "white",
						fontWeight: "bold",
					}}
				/>
			);
		} else {
			return (
				<Chip
					label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Expired&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
					variant="outlined"
					style={{
						backgroundColor: "red",
						color: "white",
						fontWeight: "bold",
					}}
				/>
			);
		}
	};

	const exportToExcel = () => {
		let excelElements = [];
		allVehicleList.map((excel,index)=>{
			let excelElement = {
				"Sr.No": index + 1,
				"Operator Name":excel?.providerFirstName +''+excel?.providerLastName,
				"Operator email":excel?.ownerId,
				"Vehicle Number":excel?.vehicleNumber,
				"Insurance Provider name": excel?.insuranceName,
				"Insurance No.": excel?.insuranceNo,
				"Insurance Start Date": excel?.insuraceStartDateMil
				? moment(excel?.insuraceStartDateMil).format("DD/MM/YYYY")
				: "-",
				"Insurance Expiry": excel?.insuranceExpireDateMil
				? moment(excel?.insuranceExpireDateMil).format("DD/MM/YYYY")
				: "-",
				"Insurance Status":excel?.insuranceStatus,
			  };
			  excelElements.push(excelElement);
			  return excel;
		});
		 const wb = XLSX.utils.book_new();
		 const ws1 = XLSX.utils.json_to_sheet(excelElements);
		 XLSX.utils.book_append_sheet(wb, ws1, 'Insurance Status');
		 XLSX.writeFile(wb, `Insurance report.xlsx`);
	};
	return (
		<>
			{/* <Container> */}
			{/* <Container maxWidth={false}> */}
			<GridItem xs={12} sm={12} md={12} sx={{ width: "100%", overflow: "hidden" }}>
				<Typography className={classes.title}> Insurance Report </Typography>
				<ThemeProvider theme={theme}>
					<GridContainer>
						{/* <GridItem xs={12} sm={12} md={3}>
                                <TextField
                                    size='small'
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="state"
                                    label="State"
                                    type="text"
                                    id="state"
                                    select
                                    value={state}
                                    onChange={handleChange('state')}
                                >
                                    {stateData.map((value,index)=>(
                                         <MenuItem value={value} key={index}>{value}</MenuItem>   
                                    ))}
                                </TextField>
                            </GridItem> */}
						{/* <GridItem xs={12} sm={12} md={3}>
                                <TextField
                                    size='small'
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="city"
                                    label="City"
                                    type="text"
                                    id="city"
                                    select
                                    value={city}
                                    onChange={handleChange('city')}
                                >
                                    {districts.map((value,index)=>(
                                         <MenuItem value={value} key={index}>{value}</MenuItem>   
                                    ))}
                                </TextField>
                            </GridItem> */}

						<GridItem xs={12} sm={12} md={3}>
							<TextField
								InputLabelProps={{
									shrink: true,
								}}
								size="small"
								variant="outlined"
								margin="normal"
								fullWidth
								name="fromDate"
								label=" Start Date From"
								type="date"
								id="fromDate"
								value={fromDate}
								onChange={handleChange("fromDate")}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={3}>
							<TextField
								InputLabelProps={{
									shrink: true,
								}}
								size="small"
								variant="outlined"
								margin="normal"
								fullWidth
								name="toDate"
								label=" Start Date To"
								type="date"
								id="toDate"
								value={toDate}
								onChange={handleChange("toDate")}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={3}>
							<TextField
								size="small"
								variant="outlined"
								margin="normal"
								fullWidth
								name="operator"
								label="Operator Name"
								type="text"
								id="operator"
								select
								value={operator}
								onChange={handleChange("operator")}
							>
								{allProvider.map((value, index) => (
									<MenuItem value={value.operatorEmail} key={value.operatorEmail}>
										{value.operatorName}
									</MenuItem>
								))}
							</TextField>
						</GridItem>
						<GridItem xs={12} sm={12} md={3}>
							<TextField
								size="small"
								variant="outlined"
								margin="normal"
								fullWidth
								name="vehicleNumber"
								label="Vehicle Number"
								type="text"
								id="vehicleNumber"
								select
								value={vehicleNumber}
								onChange={handleChange("vehicleNumber")}
							>
								{allVehicle.map((value, index) => (
									<MenuItem value={value} key={index}>
										{value}
									</MenuItem>
								))}
							</TextField>
						</GridItem>
						<GridItem xs={12} sm={12} md={2}>
							<Button
								style={{ marginTop: 20 }}
								variant="contained"
								onClick={onHandleButton}
								className={classes.button}
								fullWidth="true"
							>
								Search
							</Button>
						</GridItem>
						<GridItem xs={12} sm={12} md={2}>
							<Button
								style={{ marginTop: 20 }}
								variant="contained"
								onClick={clearFilters}
								className={classes.button}
								fullWidth="true"
							>
								Clear
							</Button>
						</GridItem>
						<GridItem xs={12} sm={12} md={2}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={(e) => {
										exportToExcel("Excel Export")
									}}
									startIcon={<FileDownloadOutlinedIcon fontSize="small" />}

								>
									Export
								</Button>
							</GridItem>
					</GridContainer>
					<GridContainer style={{ paddingTop: 20 }}>&nbsp;</GridContainer>
					<Divider></Divider>
					<TableContainer component={Paper} className={classes.customTableContainer}>
						<Table stickyHeader textAlign="center">
							<TableHead>
								<TableRow className={classes.table}>
									<TableCell className={classes.titleColor}>
										<Typography variant="h6" gutterBottom className={classes.titleColor}>
											Sr No.
										</Typography>
									</TableCell>
									<TableCell className={classes.titleColor}>
										<Typography variant="h6" gutterBottom className={classes.titleColor}>
											Operator Name
										</Typography>
									</TableCell>
									<TableCell className={classes.titleColor}>
										<Typography variant="h6" gutterBottom className={classes.titleColor}>
											Operator email
										</Typography>
									</TableCell>
									<TableCell className={classes.titleColor}>
										<Typography variant="h6" gutterBottom className={classes.titleColor}>
											Vehicle No
										</Typography>
									</TableCell>
									<TableCell className={classes.titleColor}>
										<Typography variant="h6" gutterBottom className={classes.titleColor}>
											Insurance Provider name
										</Typography>
									</TableCell>
									<TableCell className={classes.titleColor}>
										<Typography variant="h6" gutterBottom className={classes.titleColor}>
											Insurance No.
										</Typography>
									</TableCell>

									<TableCell className={classes.titleColor}>
										<Typography variant="h6" gutterBottom className={classes.titleColor}>
											Insurance Start Date
										</Typography>
									</TableCell>
									<TableCell className={classes.titleColor}>
										<Typography variant="h6" gutterBottom className={classes.titleColor}>
											Insurance Expiry Date
										</Typography>
									</TableCell>
									<TableCell className={classes.titleColor}>
										<Typography variant="h6" gutterBottom className={classes.titleColor}>
											Insurance Status
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{allVehicleList &&
									allVehicleList.map((vehicle, index) => (
										<TableRow className={classes.tableRow} key={index}>
											<TableCell className={classes.tableCell}>
												<Typography variant="body1" gutterBottom>
													{index + 1}
												</Typography>
											</TableCell>
											<TableCell className={classes.tableCell}>
												<Typography variant="body1" gutterBottom>
													{vehicle.providerFirstName} {vehicle.providerLastName}
												</Typography>
											</TableCell>
											<TableCell className={classes.tableCell}>
												<Typography variant="body1" gutterBottom>
													{vehicle.ownerId}
												</Typography>
											</TableCell>
											<TableCell className={classes.tableCell}>
												<Typography variant="body1" gutterBottom>
													{vehicle.vehicleNumber}
												</Typography>
											</TableCell>
											<TableCell className={classes.tableCell}>
												<Typography variant="body1" gutterBottom>
													{vehicle?.insuranceName || "-"}
												</Typography>
											</TableCell>
											<TableCell className={classes.tableCell}>
												<Typography variant="body1" gutterBottom>
													{vehicle.insuranceNo}
												</Typography>
											</TableCell>
											<TableCell className={classes.tableCell}>
												<Typography variant="body1" gutterBottom>
													{vehicle?.insuraceStartDateMil
														? moment(vehicle?.insuraceStartDateMil).format("DD/MM/YYYY")
														: "-"}
												</Typography>
											</TableCell>
											<TableCell className={classes.tableCell}>
												<Typography variant="body1" gutterBottom>
													{vehicle?.insuranceExpireDateMil
														? moment(vehicle?.insuranceExpireDateMil).format("DD/MM/YYYY")
														: "-"}
												</Typography>
											</TableCell>
											<TableCell className={classes.tableCell}>
												{getInsuranceStatus(vehicle?.insuraceStartDateMil, vehicle?.insuranceExpireDateMil)}
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
						{/* </TableContainer> */}
					</TableContainer>
				</ThemeProvider>
			</GridItem>
			{/* </Container> */}
			<Backdrop className={classes.backdrop} open={open} onClick={closeLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
			{/* </Container> */}
		</>
	);
}
