import React, { useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
	Container,
	Grid,
	makeStyles,
	CircularProgress,
	Backdrop,
	Typography,
	ThemeProvider,
	createMuiTheme,
	MenuItem,
	TextField,
	Button,
	TableContainer,
	TableHead,
	Divider,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import * as XLSX from 'xlsx';
//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import { orange } from "@material-ui/core/colors";
import GridContainer from "../../components/Grid/GridContainer";
import stateDistrictData from "../../allStates-Districts.json";
import { useEffect } from "react";
import { getAllRides } from "../../services/ride/Ride";
import moment from "moment";
import { useSnackbar } from "notistack";
import { getDescendingOrderedList } from "../Helper/Utils";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	title: {
		fontSize: 25,
		textAlign: "center",
	},
	customTableContainer: {
		overflowX: "initial",
	},

	tableCell: {
		Width: 100,
	},
	head: {
		backgroundColor: "#FFA93F",
		position: "sticky",
		top: 0,
		color: "#fff",
	},
	button: {
		margin: theme.spacing(1),
		backgroundColor: "#FF8000",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#c55100",
		},
	},
}));
const theme = createMuiTheme({
	palette: {
		primary: orange,
	},
});

export default function TripHistoryReport() {
	const classes = useStyles();
	let navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [inputValues, setInputValues] = useState({
		state: "Maharashtra",
		city: "Pune",
		fromDate: "",
		toDate: "",
	});
	const { state, city, fromDate, toDate } = inputValues;
	const [stateData, setStateData] = useState([]);
	const [districts, setDistricts] = useState([]);
	const [allRide, setallRide] = useState([]);
	const [mainRideList, setMainRideList] = useState([]);
    
	console.log('allRide',allRide,mainRideList);
	const handleChange = (name) => (event) => {
		let valueToSet = event.target.value;
		setInputValues({ ...inputValues, error: false, [name]: valueToSet });

		if (name === "state") {
			Object.keys(stateDistrictData).forEach((key) => {
				if (stateDistrictData[key].state == valueToSet) {
					setDistricts(stateDistrictData[key].districts);
					//console.log("districts", stateDistrictData[key].districts);
				}
			});
		}
	};
	const getInitialValues = () => {
		return {
			state: "Maharashtra",
			city: "Pune",
			fromDate: "",
			toDate: "",
		};
	};
	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message, variant) => {
		enqueueSnackbar(message, {
			variant: variant ? variant : "info",
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		});
	};
	const handleSearch = () => {
		if (fromDate !== "" && toDate !== "") {
			let fromDateMil = new Date(fromDate).getTime();
			let toDateMil = new Date(toDate).getTime();
			if (fromDateMil > toDateMil) {
				showToast("Invalid Date Selected", "error");
				return;
			}
		}
		let filteredData = mainRideList;
		// if (state !== "") {
		// 	filteredData = filteredData.filter((element) => element.state == state);
		// }
		if (city !== "") {
			filteredData = filteredData.filter((element) => element.city == city);
		}
		if (fromDate !== "") {
			filteredData = filteredData.filter((ride) => new Date(fromDate).getTime() <= ride.tripDateMil);
		}
		let lastHourToDate = new Date(toDate);
		lastHourToDate.setHours(23, 59, 0);
		let lastHourToDateMil = lastHourToDate.getTime();
		if (toDate !== "") {
			filteredData = filteredData.filter((ride) => lastHourToDateMil >= ride.tripDateMil);
		}
		setallRide(getDescendingOrderedList(filteredData));
		// if (fromDate !== "") {
		// 	let fromDateWiseRide = mainRideList.filter((element) => element.tripDate > fromDate);
		// 	setallRide(fromDateWiseRide);
		// 	// //console.log("mainVehicleList", mainVehicleList)

		// 	// //console.log("mainVehicleList", fromDateWiseVehicle)
		// 	if (toDate !== "") {
		// 		let toDateWiseRide = fromDateWiseRide.filter((element) => element.tripDate < toDate);
		// 		setallRide(toDateWiseRide);
		// 		// //console.log("mainVehicleList", toDateWiseVehicle)
		// 	}
		// }

		// if (fromDate == "" && toDate !== "") {
		// 	let toDateWiseVehicle = mainRideList.filter((element) => element.tripDate < toDate);
		// 	setallRide(toDateWiseVehicle);
		// }
	};

	useEffect(() => {
		let allData = [];
		Object.keys(stateDistrictData).forEach((key) => {
			allData.push(stateDistrictData[key].state);
		});
		//console.log("stateData", allData);
		setStateData(allData);

		getAllRides().then((Response) => {
			setallRide(getDescendingOrderedList(Response));
			setMainRideList(getDescendingOrderedList(Response));
		});
	}, []);

	const closeLoader = () => {
		setOpen(false);
	};
	const toggleLoader = () => {
		setOpen(!open);
	};
	const exportToExcel = () => {
		let excelElements = [];
		allRide.map((excel,index)=>{
			let excelElement = {
				"Sr.No": index + 1,
				"Date":`${moment(excel.tripDate).format("DD/MM/YYYY")}`,
				"Customer Name":excel?.customerFirstName,
				"Ride Start Location":excel?.startPoint?.sourceName,
				"Ride End Location":excel?.endPoint?.destinationName,
				"Distance": excel?.distance,
				"Driver Name" : excel?.driverfirstName + " " + excel?.driverlastName,
				"Vehicle Type" : excel?.category,
				"Vehicle Number" : excel?.vehicleNO,
				"Labor Count" : excel?.labor,
				"Ride Status" : excel?.rideStatus,
				"Ride Charges" : excel?.amount
			  };
			  excelElements.push(excelElement);
			  return excel;
		});
		 const wb = XLSX.utils.book_new();
		 const ws1 = XLSX.utils.json_to_sheet(excelElements);
		 XLSX.utils.book_append_sheet(wb, ws1, 'Trip history Status');
		 XLSX.writeFile(wb, `Trip history report.xlsx`);
	};
	
	return (
		<Container>
			<Container maxWidth={false}>
				<GridItem xs={12} sm={12} md={12}>
					<Typography className={classes.title}> Ride History Report </Typography>
					<ThemeProvider theme={theme}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="state"
									label="State"
									type="text"
									id="state"
									select
									value={state}
									onChange={handleChange("state")}
								>
									<MenuItem value="Maharashtra">Maharashtra</MenuItem>
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="city"
									label="City"
									type="text"
									id="city"
									select
									value={city}
									onChange={handleChange("city")}
								>
									<MenuItem value="Pune">Pune</MenuItem>
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="fromDate"
									label="From Date"
									type="date"
									id="fromDate"
									value={fromDate}
									onChange={handleChange("fromDate")}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="toDate"
									label="To Date"
									type="date"
									id="toDate"
									value={toDate}
									onChange={handleChange("toDate")}
								/>
							</GridItem>
							{/* <GridItem xs={12} sm={12} md={3}>
                                <Button style={{ marginTop: 20 }} variant="contained" className={classes.button}
                                    fullWidth="true" >Clear</Button>
                            </GridItem> */}
							<GridItem xs={12} sm={12} md={2}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={() => handleSearch()}
								>
									Search
								</Button>
							</GridItem>
							<GridItem xs={12} sm={12} md={2}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={() => {
										setInputValues(getInitialValues());
										setallRide(getDescendingOrderedList(mainRideList));
									}}
								>
									Clear
								</Button>
							</GridItem>
							<GridItem xs={12} sm={12} md={2}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={(e) => {
										exportToExcel("Excel Export")
									}}
									startIcon={<FileDownloadOutlinedIcon fontSize="small" />}

								>
									Export
								</Button>
							</GridItem>
						</GridContainer>
						<GridContainer style={{ paddingTop: 20 }}>&nbsp;</GridContainer>
						<Divider></Divider>
						<GridContainer>
							<Table className={classes.table}>
								<TableBody>
									<TableRow>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Sr No.
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Date
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Customer Name
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Ride Start Location
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Ride End Location
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Distance
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Driver Name
											</Typography>
										</TableCell>

										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Vehicle Type
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Vehicle Number
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Labor Count
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Ride Status
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Ride charges
											</Typography>
										</TableCell>
									</TableRow>

									{allRide.map((ride, index) => (
										<TableRow className={classes.tableRow} key={index}>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{index + 1}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{/* {ride.tripDate} */}
													{`${moment(ride.tripDate).format("DD/MM/YYYY")}`}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{ride.customerFirstName}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{ride.startPoint.sourceName}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{ride.endPoint.destinationName}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{ride.distance}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{ride.driverfirstName + " " + ride.driverlastName}
												</Typography>
											</TableCell>

											<TableCell>
												<Typography variant="body1" gutterBottom>
													{ride.category}
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography variant="body1" gutterBottom>
													{ride?.vehicleNO || "-"}
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography variant="body1" gutterBottom>
													{ride?.labor || "-"}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{ride.rideStatus}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{ride.amount}
												</Typography>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
							{/* </TableContainer> */}
						</GridContainer>
					</ThemeProvider>
				</GridItem>
			</Container>
			<Backdrop className={classes.backdrop} open={open} onClick={closeLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Container>
	);
}
