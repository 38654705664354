import React from 'react';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Typography from '@material-ui/core/Typography';


import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#00acc1',
        color: '#fff'
    },
}));


export default function UpdateUserView() {
    const classes = useStyles();
    const [user, setUser] = React.useState({});
    const [inputValues,setInputValues]=React.useState({
        email:user.email,
        firstName:user.firstName,
        middleName:user.middleName,
        lastName:user.lastName,
        mobileNumber:user.mobileNumber,  
        Aadhar:'',
        pan:'',
        drivingLicence:'',
        address:'',
        permanentAddress:''
    })
    const {email,firstName,lastName,middleName,mobileNumber,Aadhar,pan,drivingLicence,address,permanentAddress}=inputValues;

    const handleChange = name => event => {
        let valueToSet = event.target.value;
            valueToSet = [];
            valueToSet.push(event.target.value);
        setInputValues({ ...inputValues, error: false, [name]: valueToSet });
    };
 
    React.useEffect(() =>{
        let userProfile = localStorage.getItem("USER_PROFILE");
        //console.log("profile",userProfile);
        setUser(JSON.parse(userProfile))
    },[])
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={2}></GridItem>
            <GridItem xs={12} sm={12} md={8}>
                <form className={classes.root} noValidate autoComplete="on">
                    <Typography component="h1" variant="h2"style={{textAlign:'center',padding:20}}>
                        Update Users Details
                    </Typography>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                            <TextField
                                variant='standard'
                                margin="normal"
                                required
                                fullWidth
                                name="name"
                                label="First Name"
                                type="name"
                                id="name"
                                value={user.firstName}
                                onChange={handleChange('firstName')}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <TextField
                                variant="standard"
                                margin="normal"
                                required
                                fullWidth
                                name="middlename"
                                label="MiddleName"
                                type="text"
                                id="middlename"
                                value={user.middleName}
                                onChange={handleChange('middleName')}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <TextField
                                variant="standard"
                                margin="normal"
                                required
                                fullWidth
                                name="lastname"
                                label="Last Name"
                                type="text"
                                id="lastname"
                                value={user.lastName}
                                onChange={handleChange('lastName')}

                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                // variant="standard"
                                margin="normal"
                                required
                                fullWidth
                                name="email"
                                label="Email Id"
                                type="email"
                                id="email"
                                value={user.email}
                                onChange={handleChange('email')}

                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                inputProps={{ maxLength: 10 }}
                                variant="standard"
                                margin="normal"
                                required
                                fullWidth
                                name="mobileNumber"
                                label="Mobile Number"
                                type=""
                                id="mobileNumber"
                                value={user.mobileNumber}
                                onChange={handleChange('mobileNumber')}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                margin="normal"
                                fullWidth
                                inputProps={{ maxLength: 12 }}
                                name="AAadhar"
                                label="AAadhar"
                                type="file"
                                id="AAadhar"
                                value={Aadhar}
                                onChange={handleChange('Aadhar')}
                            />

                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                margin="normal"
                                fullWidth
                                name="PAN"
                                label="PAN"
                                type="file"
                                id="PAN"
                                value={pan}
                                onChange={handleChange('pan')}

                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                margin="normal"
                                fullWidth
                                name="driving-license"
                                label="Driving License"
                                type="file"
                                id="driving-license"
                                value={drivingLicence}
                                onChange={handleChange('drivingLicence')}

                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                margin="normal"
                                required
                                fullWidth
                                name="current-address"
                                label="CURRENT ADDRESS"
                                type="text"
                                id="current-address"
                                value={address}
                                onChange={handleChange('address')}

                            />
                        </GridItem> 

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                margin="normal"
                                required
                                fullWidth
                                name="permanent-address"
                                label="PERMANENT ADDRESS"
                                type="text"
                                id="permanent-address"
                                value={permanentAddress}
                                onChange={handleChange('permanentAddress')}
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={8}>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Button variant="contained" className={classes.button}
                                fullWidth="true">Update User Profile</Button>
                        </GridItem>
                    </GridContainer>
                </form>
            </GridItem>
        </GridContainer>

    )
}