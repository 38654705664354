import FbApp from "../../config/FbApp";
import { DateTime } from "luxon";
export default function Vehicles() {}

export const getAllVehicles = async () => {
	const db = FbApp.firestore();
	const vehicleRef = db.collection("VEHICLE").orderBy("vehicleCategory", "asc");
	const snapshots = await vehicleRef.get();
	const vehicle = [];
	snapshots.forEach((doc) => {
		const document = doc.data();
		if (document.createdOn !== undefined && document.createdOn !== "") {
			document.createdOnObj = document.createdOn;
		}
		vehicle.push(document);
	});

	return vehicle;
};
export const getVehicleCategoryMetadataNew = async () => {
	return new Promise((resolve, reject) => {
		const db = FbApp.firestore();
		db.collection("MD_VEHICAL_CATEGORY")
			.get()
			.then((categories) => {
				let categoriesWithData = [];
				categories.forEach((category) => {
					categoriesWithData.push(category.data());
				});
				console.log(categoriesWithData);
			})
			.catch((err) => reject(err));
	});
};

export const updateVehicle = (vehicle) => {
	const db = FbApp.firestore();
	return db
		.collection("VEHICLE")
		.doc(vehicle.vehicleNumber)
		.update(vehicle)
		.then((response) => {
			if (response) {
				return response;
			}
		})
		.catch((error) => {
			throw error;
		});
};
export const addVehicleType = (payload) => {
	const db = FbApp.firestore();
	var userRef = db.collection("VEHICLE_TYPE");
	return userRef
		.doc(payload.vehicleType)
		.set(payload)
		.then((response) => {
			return response;
		});
};
export const getVehicleType = () => {
	const db = FbApp.firestore();
	return db
		.collection("VEHICLE_TYPE")
		.orderBy("vehicleCategory", "asc")
		.get()
		.then((snapshots) => {
			var docData = snapshots.docs.map((doc) => doc.data());
			//console.log('vehicleType', docData);
			return docData;
		});
};

export const updateVehicleStatus = (vehicleNumber, vehicleStatus) => {
	const db = FbApp.firestore();
	return db
		.collection("VEHICLE")
		.doc(vehicleNumber)
		.update({ isActive: vehicleStatus })
		.then((response) => {
			if (response) {
				return response;
			}
		})
		.catch((error) => {
			throw error;
		});
};
