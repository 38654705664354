import React, { useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
  Container,
  Grid,
  makeStyles,
  CircularProgress,
  Backdrop,
  Typography,
  Button,
  TextField,
  ThemeProvider,
  createMuiTheme,
  Snackbar,
} from "@material-ui/core";
//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import GridContainer from "../../components/Grid/GridContainer";
import { orange } from "@material-ui/core/colors";
import { addVehicleType } from "../../services/vehicle/Vehicles";

import Lottie from "react-lottie";
import animationData from "../../assets/jss/lf30_editor_fvhlirkb.json";
import Alert from "@material-ui/lab/Alert";

const loaderOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const theme = createMuiTheme({
  palette: {
    primary: orange,
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  title: {
    fontSize: 25,
    textAlign: "center",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#FF8000",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c55100",
    },
  },
}));

export default function VehicleType() {
  const classes = useStyles();
  let navigate = useNavigate();
  const [openLoader, setOpenLoader] = useState(false);
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [failureToastOpen, setFailureToastOpen] = useState(false);

  const closeLoader = () => {
    setOpenLoader(false);
  };
  const toggleLoader = () => {
    setOpenLoader(!openLoader);
  };
  const handleClose = (event) => {
    setSuccessToastOpen(false);
    setFailureToastOpen(false);
  };
  const [payload, setPayload] = useState({
    VehicleType: "",
    VehicleLoadSize: "",
    VehicleDescription: "",
    VehicleManufacture: "",
    VehicleManufactureDate: "",
  });
  const {
    vehicleType,
    vehicleLoadSize,
    vehicleDescription,
    vehicleManufacture,
    vehicleManufactureDate,
  } = payload;

  const handleChange = (name) => (event) => {
    setPayload({ ...payload, [name]: event.target.value });
  };

  const onAddvehicleType = () => {
    if (
      vehicleType === "" ||
      vehicleLoadSize === "" ||
      vehicleDescription === "" ||
      vehicleDescription === "" ||
      vehicleManufactureDate === ""
    ) {
      setFailureToastOpen(true);
      return;
    }
    toggleLoader();
    addVehicleType(payload);
    closeLoader();
    resetForm();
    setSuccessToastOpen(true);
  };

  const resetForm = () => {
    setPayload({
      ...payload,
      vehicleType: "",
      vehicleDescription: "",
      vehicleManufacture: "",
      vehicleManufactureDate: "",
      vehicleLoadSize: "",
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={2}></GridItem>
      <GridItem xs={12} sm={12} md={8}>
        <form className={classes.root} noValidate autoComplete="on">
          <Typography
            component="h2"
            variant="h2"
            style={{ textAlign: "center", padding: 20 }}
          >
            Add Vehicle Type
          </Typography>
          <GridContainer>&nbsp;</GridContainer>
          <ThemeProvider theme={theme}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="vehicleType"
                  label="Vehicle Type"
                  type="text"
                  id="vehicleType"
                  value={vehicleType}
                  onChange={handleChange("vehicleType")}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="vehicleLoadSize"
                  label="Vehicle Load Size"
                  type="text"
                  id="vehicleLoadSize"
                  value={vehicleLoadSize}
                  onChange={handleChange("vehicleLoadSize")}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="vehicleDescription"
                  label="Vehicle Description"
                  type="text"
                  id="vehicleDescription"
                  value={vehicleDescription}
                  onChange={handleChange("vehicleDescription")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="vehicleManufacture"
                  label="Vehicle Manufacture"
                  type="text"
                  id="vehicleManufacture"
                  value={vehicleManufacture}
                  onChange={handleChange("vehicleManufacture")}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  inputProps={{ maxLength: 10 }}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  type="date"
                  name="vehicleManufactureDate"
                  label="vehicle Manufacture Date"
                  id="vehicleManufactureDate"
                  value={vehicleManufactureDate}
                  onChange={handleChange("vehicleManufactureDate")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>&nbsp;</GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}></GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Button
                  variant="contained"
                  className={classes.button}
                  fullWidth="true"
                  onClick={onAddvehicleType}
                >
                  Add Vehicle Type
                </Button>
              </GridItem>
            </GridContainer>
          </ThemeProvider>
        </form>
      </GridItem>
      <Snackbar
        open={successToastOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success">
          Vehicle Type Added Successfully.
        </Alert>
      </Snackbar>
      <Snackbar
        open={failureToastOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error">
          Please Fill All Mandetory Feilds !
        </Alert>
      </Snackbar>
      <Backdrop
        className={classes.backdrop}
        open={openLoader}
        onClick={closeLoader}
      >
        <CircularProgress color="inherit" />
        <Lottie options={loaderOptions} height={200} width={600} />
      </Backdrop>
    </GridContainer>
  );
}
