import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";

import {
	Backdrop,
	Button,
	CircularProgress,
	TableContainer,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Chip,
	Tooltip,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { activateDeactiveUser, getAllProvider } from "../../services/users/userOnboarding";

import Switch from "@material-ui/core/Switch";
import EditIcon from "@material-ui/icons/Edit";
//Dialog

import moment from "moment";
//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Lottie from "react-lottie";
import animationData from "../../assets/jss/lf30_editor_fvhlirkb.json";
import SearchField from "react-search-field";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { updateVehicleStatus } from "../../services/vehicle/Vehicles";

const loaderOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};

const useStyles = makeStyles((styles) => ({
	backdrop: {
		zIndex: styles.zIndex.drawer + 1,
		color: "#fff",
	},

	customTableContainer: {
		overflowX: "initial",
	},
	head: {
		backgroundColor: "#FFA93F",
		position: "sticky",
		top: 0,
		color: "#fff",
	},
}));

const PurpleSwitch = withStyles({
	switchBase: {
		"&$checked": {
			color: "#FF8000",
		},
		"&$checked + $track": {
			backgroundColor: "#FF8000",
		},
	},
	checked: {},
	track: {},
})(Switch);

export default function UserListView() {
	const classes = useStyles();
	const [userList, setUserList] = useState([]);
	const [selectedUser, setSelectedUser] = useState("");
	const [openActive, setOpenActive] = useState(false);
	const [openDeactive, setOpenDeactive] = useState(false);
	const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
	const [openLoader, setOpenLoader] = useState(false);
	const [filteredUserList, setFilteredUserList] = useState([]);

	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message, variant) => {
		enqueueSnackbar(message, {
			variant: variant ? variant : "info",
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		});
	};

	const onDeactivate = (user) => {
		setSelectedUser(user);
		////console.log('selectedUser', user);
		user.isActive = false;
		activateDeactiveUser(user).then((response) => {
			setOpenDeactive(false);
			if (user.role && user.vehicle && user.role === "PROVIDER-DRIVER") {
				updateVehicleStatus(user.vehicle, false);
				console.log("Vehicle No", user.vehicle, "Role", user.role);
			}
			setSelectedUser("");
			handleCloseConfirmationDialog();
			//	setCurrentUserStatus(false);
			showToast(`User is Deactivated`, "error");
		});
	};

	const onActivate = (user) => {
		setSelectedUser(user);
		////console.log('selectedUser', user);
		user.isActive = true;
		activateDeactiveUser(user).then((response) => {
			// setOpenActive(false);
			if (user.role && user.vehicle && user.role === "PROVIDER-DRIVER") {
				updateVehicleStatus(user.vehicle, true);
				console.log("Vehicle No", user.vehicle, "Role", user.role);
			}
			setSelectedUser("");
			handleCloseConfirmationDialog();
			//setCurrentUserStatus(true);
			showToast(`User is Activated`, "success");
		});
	};
	let navigate = useNavigate();

	const viewUser = (user) => {
		// ////console.log("selected", user);
		localStorage.setItem("SELECTED_USER", JSON.stringify(user));
		navigate("/app/addUser/editUserMode", { state: user.businessInfo.officeEmail });
	};

	const closeLoader = () => {
		setOpenLoader(false);
	};
	const toggleLoader = () => {
		setOpenLoader(!openLoader);
	};
	const handleOpenConfirmationDialog = () => {
		setOpenConfirmationDialog(true);
	};

	const handleCloseConfirmationDialog = () => {
		setOpenConfirmationDialog(false);
	};
	const handleToggleUser = (user) => {
		handleOpenConfirmationDialog();
		setSelectedUser(user);
	};

	useEffect(() => {
		toggleLoader();
		getAllProvider().then((allProviders) => {
			allProviders = allProviders.filter((element) => element.role == "PROVIDER" || element.role == "PROVIDER-DRIVER");
			closeLoader();
			if (allProviders) {
				allProviders = _.orderBy(allProviders, "createdOnMil", "desc");
			}
			setUserList(allProviders);
			setFilteredUserList(allProviders);
		});
		// if (window.performance) {
		// 	console.info("window.performance works fine on this browser");
		// 	if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
		// 		alert("Reloaded");
		// 	}
		// }
	}, []);

	const onChangeSerchText = (e) => {
		////console.log('Text', userList);
		if (e && e.trim() !== " ") {
			let filterUser = userList.filter((user) => {
				return (
					user?.contactDetails?.contactFirstName?.toLowerCase().indexOf(e.toLowerCase()) > -1 ||
					user?.contactDetails?.contactLastName?.toLowerCase().indexOf(e.toLowerCase()) > -1
				);
			});
			setFilteredUserList(filterUser);
		} else {
			setFilteredUserList(userList);
		}
	};
	return (
		<>
			<div>
				<SearchField
					placeholder="Search Operator..."
					onChange={onChangeSerchText}
					searchText=""
					classNames="test-class"
				/>
			</div>

			<TableContainer component={Paper} className={classes.customTableContainer}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									City
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Operator Name
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Mobile No
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									E-Mail Id
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Status
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Created
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Action
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}></Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredUserList.map((user, index) => (
							<TableRow value={user} key={index} className={classes.tableRow}>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{user?.contactDetails?.contactCity}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{user?.contactDetails?.contactFirstName}
										{user?.contactDetails?.contactMiddleName} {user?.contactDetails?.contactLastName}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{user?.contactDetails?.contactMobileNumber}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{user?.businessInfo?.officeEmail}
									</Typography>
								</TableCell>
								<TableCell>
									{user.isOnboarded === true ? (
										<Chip
											label="&nbsp;ONBOARDED&nbsp;"
											color="green"
											variant="outlined"
											style={{ borderColor: "green", color: "black", fontWeight: "bold" }}
										/>
									) : (
										<Chip
											label="&nbsp;&nbsp;&nbsp;&nbsp;PENDING&nbsp;&nbsp;&nbsp;&nbsp;"
											variant="outlined"
											style={{ backgroundColor: "red", color: "white", fontWeight: "bold" }}
										/>
									)}
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{moment(user?.createdOn?.toDate()).format("DD/MM/YYYY")}
									</Typography>
								</TableCell>

								<TableCell>
									<Tooltip arrow title={user?.isActive ? "Deactivate" : "Activate"}>
										<PurpleSwitch
											checked={user.isActive ? true : false}
											onClick={() => {
												//user.isActive ? onDeactivate(user) : onActivate(user);
												handleToggleUser(user);
											}}
										/>
									</Tooltip>
								</TableCell>
								<TableCell>
									<EditIcon
										onClick={() => {
											viewUser(user);
										}}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Dialog
				//component={Paper}
				//fullWidth="true"
				maxWidth="xs"
				open={openConfirmationDialog}
				//TransitionComponent={Transition}
				onClose={handleCloseConfirmationDialog}
			>
				<DialogTitle id="alert-dialog-title">{"NowWagon"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{`Do you want to ${
						selectedUser !== "" && selectedUser?.isActive ? "Deactivate" : "Activate"
					} user?`}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						className={classes.submit}
						onClick={
							selectedUser !== ""
								? selectedUser.isActive
									? () => onDeactivate(selectedUser)
									: () => onActivate(selectedUser)
								: null
						}
					>
						YES
					</Button>
					<Button color="primary" className={classes.submit} onClick={() => handleCloseConfirmationDialog()} autoFocus>
						NO
					</Button>
				</DialogActions>
			</Dialog>

			<Backdrop className={classes.backdrop} open={openLoader} onClick={closeLoader}>
				{/* <CircularProgress color="inherit" /> */}
				<Lottie options={loaderOptions} height={200} width={600} />
			</Backdrop>
		</>
	);
}
