import React, { useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
	Container,
	makeStyles,
	CircularProgress,
	Backdrop,
	Typography,
	ThemeProvider,
	createMuiTheme,
} from "@material-ui/core";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import { orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	title: {
		fontSize: 15,
		textAlign: "justify",
		fontFamily: "Times New Roman",
	},
	customTableContainer: {
		overflowX: "initial",
	},

	tableCell: {
		Width: 100,
	},
	head: {
		backgroundColor: "#FFA93F",
		position: "sticky",
		top: 0,
		color: "#fff",
	},
	button: {
		margin: theme.spacing(1),
		backgroundColor: "#FF8000",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#c55100",
		},
	},
}));
const theme = createMuiTheme({
	palette: {
		primary: orange,
	},
});

export default function FAQScreen() {
	const classes = useStyles();
	let navigate = useNavigate();
	const [open, setOpen] = useState(false);

	const closeLoader = () => {
		setOpen(false);
	};
	const toggleLoader = () => {
		setOpen(!open);
	};
	return (
		<Container>
			<Container maxWidth={false}>
				<GridItem xs={12} sm={12} md={12}>
					<ThemeProvider theme={theme}>
						<Typography className={classes.title}>
							<h2>Privacy Policy (NowWagon) </h2>
							<br />
							<p>
								Your privacy matters to <b>NowWagon Smart Movers LLP</b> (the “Company”, “we”,
								“NowWagon”, or “us”).
								<br />
								We are in compliance with Information Technology (Reasonable security practices and
								procedures and sensitive personal data or information) Rules, 2011,{" "}
								<b>IS/ISO/IEC 27001</b> standard or the codes of the best practices for data protection
								as approved and notified as well as Information Technology Intermediaries guidelines)
								Rules, 2011 in relation to subsection (2) of section 87 read with sub-section (2) of
								section 79 of the <b>Information Technology Act, 2000 (21 of 2000)</b>
								<br />
								This Privacy Policy explains how we collect, process, use, share and protect information
								aboutyou. It also tells you how you can access and update your information and make
								certain choices about how your information is used.
								<br />
								The Privacy Policy covers both “online” (e.g., web and mobile services, including any
								websitesoperated by us such as
								<b> https://www.NowWagon.com/ </b> or Mobile Application, however accessed and/or used,
								whether via personal computers, mobile devices or otherwise) and “offline” (e.g.,
								collection of data through mailings, telephone, or in person) activities owned,
								operated, provided, or made available by the Company. Our “online” and “offline”
								activities, in relation to facilitation of transport hireservices through a network of
								third-party drivers and transport operators, are collectively referenced asthe
								“Services”.
								<br /> This Privacy Policy also applies to your use of interactive features or downloads
								that:
								<br />
								<b>i)</b> We own or control;
								<br />
								<b>ii)</b> Are available through the Services; or
								<br />
								<b>iii)</b> Interact with the Servicesand post or incorporate this Privacy Policy.
							</p>
							<p>
								<Accordion>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>1. DEFINITIONS</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												Unless otherwise provided in this Privacy Policy, the terms capitalized
												in the Privacy Policy shall have the meaning as provided hereunder:
												<br />• <b>“Co-branded Services”</b> shall have the meaning assigned to
												the term in paragraph 4(c) hereto.
												<br />• <b>“Device”</b> shall mean computer, mobile or any other device
												used to access the Services.
												<br />• <b>“Device Identifier”</b> shall mean IP address or other unique
												identifier for the Device.
												<br />• <b>“Mobile Application”</b> shall mean application provided by
												us on the Device to access the Services.
												<br /> • <b>“Mobile Device Information”</b> shall have the meaning
												assigned to the term in paragraph 2(d)(ii)hereto.
												<br />• <b>“Promotion”</b> shall mean any contest and other promotions
												offered by us.
												<br />• <b>“Protected Information”</b> shall mean such categories of
												information that could reasonably be used to identify you personally,
												including your name, e-mail address, and mobile number.
												<br />• <b>“TPSP”</b> shall mean a third party service provider.
												<br />• <b>“Usage Information”</b> shall have the meaning assigned to
												the term in paragraph 2(b)(i) hereto.
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>2. WHAT INFORMATION DO WE COLLECT?</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<b>a) INFORMATION YOU PROVIDE TO US </b>
											<br />
											We may ask you to provide us with certain Protected Information. We may
											collect this information through various means and in various places through
											the Services, including account registration forms, contact us forms, or
											when you otherwise interact with us.
											<br />
											When you sign up to use theServices, you create a user profile. We shall ask
											you to provide only such Protected Information which is for lawful purpose
											connected with our Services and necessary to be collected by us forsuch
											purpose. The current data fields that might be requested for are:
											<br />
											<b>
												• Email
												<br />• Password
												<br />• Name
												<br />• Address
												<br />• Mobile phone Number
												<br />• Pin Code
												<br />• Vehicle number,
												<br />• Vehicle make (e.g. Tata 407)
												<br />• Driver name
												<br />• Driver’s contact number
											</b>
											<br />
											<br />
											<b>b) INFORMATION WE COLLECT AS YOU ACCESS AND USE OUR SERVICES</b>
											<br />
											<b>i).</b> In addition to any Protected Information or other information
											that you choose to submit to us, weand our TPSP may use a variety of
											technologies that automatically (or passively) collect certain information
											whenever you visit or interact with the Services (“Usage Information”). This
											Usage Information may include the browser that you are using, the URL that
											referred you to our Services,all of the areas within our Services that you
											visit, and the time of day, among other information. Inaddition, we collect
											your Device Identifier for your Device. A Device Identifier is a number that
											is automatically assigned to your Device used to access the Services, and
											our computers identify your Device by its Device Identifier.
											<br />
											<b>ii).</b> In case of booking via call centre, NowWagon may record calls
											for quality and training purposes.
											<br />
											<b>iii).</b> In addition, tracking information is collected as you navigate
											through our Services, including, but not limited to geographic areas. The
											driver’s mobile phone will send your GPS coordinates, duringthe ride, to our
											servers. Most GPS enabled mobile devices can define one’s location to within
											50 feet.
											<br />
											<b>iv).</b> In case you upload an emergency contact (name and phone number)
											on your account / on the Mobile Application, we will be collecting and
											storing such contact information to share your ride details with that
											contact and for use in an emergency situation.
											<br />
											<b>v).</b> In case you refer a friend to our services, we will be collecting
											and storing such contact information (name and phone number) to get in touch
											with your friend, to promote our services.
											<br />
											<b>vi).</b> Usage Information may be collected using a cookie. If you do not
											want information to be collected through the use of cookies, your browser
											allows you to deny or accept the use of cookies. Cookies can be disabled or
											controlled by setting a preference within your web browser or on your
											Device.
											<br />
											<b>vii).</b> If you choose to disable cookies or flash cookies on your
											Device, some features of the Services may not function properly or may not
											be able to customize the delivery of information to you. The Company cannot
											control the use of cookies (or the resulting information) by third parties,
											and use of third party cookies is not covered by our Privacy Policy.
											<br />
											<br />
											<b>c) INFORMATION THIRD PARTIES PROVIDE ABOUT YOU</b>
											<br />
											We may, from time to time, supplement the information we collect about you
											through our websiteor Mobile Application or Services with outside records
											from third parties.
											<br />
											<br />
											<b>d) INFORMATION COLLECTED BY MOBILE APPLICATIONS</b>
											<br />
											<b>i).</b> Our Services are primarily provided through the Mobile
											Application. We may collect and usetechnical data and related information,
											including but not limited to, technical information about your device,
											system and application software, and peripherals, that is gathered
											periodically to facilitate the provision of software updates, product
											support and other services to you (if any) related to such Mobile
											Applications.
											<br />
											<b>ii).</b> When you use any of our Mobile Applications, the Mobile
											Application may automatically collectand store some or all of the following
											information from your mobile device (“Mobile Device Information”), in
											addition to the Device Information, including without limitation:
											<br />
											<b>iii).</b> Your preferred language and country site (if applicable)
											<br />
											<b>iv).</b> The manufacturer and model of your mobile device
											<br />
											<b>v).</b> Your mobile operating system
											<br />
											<b>vi).</b> The type of mobile internet browsers you are using
											<br />
											<b>vii).</b> Your geo-location
											<br />
											<b>viii).</b> Information about how you interact with the Mobile Application
											and any of our web sites to whichthe Mobile Application links, such as how
											many times you use a specific part of the Mobile Application over a given
											time period, the amount of time you spend using the Mobile Application,how
											often you use the Mobile Application, actions you take in the Mobile
											Application and how you engage with the Mobile Application
											<br />
											<b>ix).</b> Information to allow us to personalize the services and content
											available through the Mobile Application
											<br />
											<b>x).</b> Data from SMS/ text messages upon receiving Device permissions
											for the purposes of
											<br />
											<b>i)</b> issuing and receiving one-time passwords and other device
											verification, and
											<br />
											<b>ii)</b> automatically filling verification details during financial
											transactions, either through us or a third-party service provider,in
											accordance with applicable law. We do not share or transfer SMS/ text
											message data to anythird party other than as provided under this Privacy
											Policy.
											<br />
											<b>xi).</b> We monitor user behaviour and have implemented security
											solutions on our website as well as in our solutions and on our premises,
											where our legitimate interest in anti-corruption, anti-fraud, anti-bribery,
											technical and physical security overrides your interest in the information
											not being processed, where we process:
											<br />
											<b>xii).</b> Contact information (e.g. name, address, e-mail and phone
											number)
											<br />
											<b>xiii).</b> User behaviour and logs
											<br />
											<b>xiv).</b> Images captured by video surveillance in marked areas at
											NowWagon premises
											<br />
											<b>xv).</b> We may use information collected from our cookies to identify
											user behaviour and to serve content and offers based on your profile, and
											for the other purposes described below, to the extent legally permissible in
											certain jurisdictions.
											<br />
											<b>xvi).</b> Some of the cookies we use don't collect information that
											identifies a visitor. For example –
											<br />• Performance cookies (see below)
											<br />• Targeting cookies (see below) where you are not a registered user
											<br />
											<b>xvii).</b> In other cases, we can associate cookie information (including
											information from cookies placed via our advertisements on third party sites)
											with an identifiable individual. For example –
											<br />• If we send you a targeted email which includes web beacons, cookies
											or similar technologies we may know whether you open, read, or delete the
											message.
											<br />• When you click a link in a marketing e-mail you receive from us, we
											may also use a cookie to log what pages you view and what content you
											download from our websites, even if you are not registered at or signed into
											our site
											<br />• Combining and analysing personal data – As described above, we may
											combine data from publicly available sources, and from our different e-mail,
											website, and personal interactions with you (this includes information
											collected across our different websites such as our careers and corporate
											sites and information collected when you sign-up or log on to our sites or
											connect to our sites using your social media credentials. We combine this
											data to better assess your experience with us and to perform the other
											activities described throughout our privacy policy. Some cookies we use are
											placed by third party companies, such as Internet Explorer, Mozilla Firefox,
											Chrome, Safari, iOS, Android, etc. These companies may use programming code
											to collect information about your interaction with our sites, such as the
											pages you visit, the links you click on and how long you are on our sites.
											<br />
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>3. USE OF INFORMATION COLLECTED</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<b>e)</b> Our primary goal in collecting your information is to provide
												you with an enhanced experiencewhen using the Services. We use your
												information to closely monitor which features of the Servicesare used
												most, to allow you to view your trip history, rate trips, and to
												determine which features weneed to focus on improving, including usage
												patterns and geographic locations to determine wherewe should offer or
												focus services, features and/or resources.
												<br />
												<b>f)</b> Based upon the Protected Information you provide us when
												registering for an account, we will sendyou a welcoming email to verify
												your username and password.
												<br />
												<b>g)</b> We use the information collected from our Mobile Application
												so that we are able to serve you thecorrect app version depending on
												your device type, for troubleshooting and in some cases,marketing
												purposes.
												<br />
												<b>h)</b> We use your Internet Protocol (IP) address to help diagnose
												problems with our computer server,and to administer our web site(s).
												Your IP address is used to help identify you, but contains nopersonal
												information about you.
												<br />
												<b>i)</b> We will send you strictly service-related announcements on
												rare occasions when it is necessaryto do so. For instance, if our
												Services are temporarily suspended for maintenance, we might send you an
												email. If you do not wish to receive them, you have the option to
												deactivate your account.
												<br />
												<b>j)</b> We may use the information obtained from you to prevent,
												discover and investigate violations of this Privacy Policy or any
												applicable terms of service or terms of use for the Mobile
												Application,and to investigate fraud, chargeback or other matters.
												<br />
												<b>k)</b> We provide some of your Protected Information (such as your
												name, pick up address, contactnumber) to the driver who accepts your
												request for transportation so that the driver may contactand find you.
												The companies for which drivers work (that are providing the
												transportation service)are also able to access your Protected
												Information, including your geo-location data.
												<br />
												<b>l)</b> We use that geo-location information for various purposes,
												including for you to be able to view thedrivers in your area that are
												close to your location, for you to set your pick-up location, for
												thedrivers to identify the pick-up location, to send you promotions and
												offers, and to allow you (if youchoose through any features we may
												provide) to share this information with other people.
												<br />
												<b>m)</b> In addition, we may use your Protected Information or Usage
												Information that we collect aboutyou:
												<br />
												<b>1)</b> To provide you with information or services or process
												transactions that you have requestedor agreed to receive including to
												send you electronic newsletters, or to provide you with specialoffers or
												promotional materials on behalf of us or third parties;
												<br />
												<b>2)</b> To enable you to participate in avariety of the Services’
												features such as online or mobile entry sweepstakes, contests or
												otherpromotions;
												<br />
												<b>3)</b> To contact you with regard to your use of the Services and, in
												our discretion,changes to the Services and/or the Services’ policies; (
												<br />
												<b>4)</b> For internal business purposes;
												<br />
												<b>5)</b> For inclusion in our data analytics; and
												<br />
												<b>6)</b> For purposes disclosed at the time you provide yourinformation
												or as otherwise set forth in this Privacy Policy.
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>
													<b>4. HOW AND WHEN DO WE DISCLOSE INFORMATION TO THIRD PARTIES ?</b>{" "}
												</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												We do not sell, share, rent or trade the information we have collected
												about you, other than as disclosed within this Privacy Policy or at the
												time you provide your information. Following are the situations when
												information may be shared:
												<br />
												<br />
												<b>a) WHEN YOU AGREE TO RECEIVE INFORMATION FROM THIRD PARTIES.</b>
												<br />
												You may be presented with an opportunity to receive information and/or
												marketing offers directly from third parties. If you do agree to have
												your Protected Information shared, your Protected Information will be
												disclosed to such third parties and all information you disclose will be
												subject tothe privacy policy and practices of such third parties. We are
												not responsible for the privacy policies and practices of such third
												parties and, therefore, you should review the privacy policies and
												practices of such third parties prior to agreeing to receive such
												information from them. If you laterdecide that you no longer want to
												receive communication from a third party, you will need to contactthat
												third party directly.
												<br />
												<br />
												<b>b) THIRD PARTIES PROVIDING SERVICES ON OUR BEHALF</b>
												<br />
												We use third party companies and individuals to facilitate our Services,
												provide or perform spects of the Services on our behalf – such as
												drivers and companies they work for to providethe Services, other third
												parties including Intab to host the Services, design and/or operate
												theServices’ features, track the Services’ analytics, process payments,
												engage in anti-fraud andsecurity measures, provide customer support,
												provide geo-location information to our drivers,enable us to send you
												special offers, host our job application form, perform technical
												services (e.g.,without limitation, maintenance services, database
												management, web analytics and improvementof the Services‘ features), or
												perform other administrative services. These third parties will
												haveaccess to user information, including Protected Information to only
												carry out the services they areperforming for you or for us. Each of
												these third parties including TFS are required to ensure thesame level
												of data protection as us and are obligated not to disclose or use
												Protected Information for any other purpose.Analytics TPSPs may set and
												access their own cookies, web beacons and embedded scripts onyour Device
												and they may otherwise collect or have access to information about you,
												including non-personally identifiable information. We use a third party
												hosting provider who hosts our support section of our website.
												Information collected within this section of our website by such third
												party is governed by our Privacy Policy.
												<br />
												<br />
												<b> c) CONTESTS AND PROMOTIONS</b>
												<br />
												We may offer Promotions through the Services that may require
												registration. By participating in aPromotion, you are agreeing to
												official rules that govern that Promotion, which may contain specific
												requirements of you, including, allowing the sponsor of the Promotion to
												use your name, voiceand/or likeness in advertising or marketing
												associated with the Promotion. If you choose to entera Promotion, you
												agree that your Protected Information may be disclosed to third parties
												or thepublic in connection with the administration of such Promotion,
												including, in connection with winner selection, prize fulfilment, and as
												required by law or permitted by the Promotion’s official rules,such as
												on a winners list.
												<br />
												<br />
												<b>(e) ADMINISTRATIVE AND LEGAL REASONS</b>
												<br />
												We cooperate with Government and law enforcement officials and private
												parties to enforce and comply with the law. Thus, we may access, use,
												preserve, transfer and disclose your information (including Protected
												Information, IP address, Device Information or geo-location data),
												toGovernment or law enforcement officials or private parties as we
												reasonably determine is necessary and appropriate:
												<br />
												<b>i)</b> to satisfy any applicable law, regulation, subpoenas,
												Governmental requests or legal process;
												<br />
												<b>ii)</b> to protect and/or defend the Terms and Conditions for online
												andmobile Services or other policies applicable to any online and mobile
												Services, including investigation of potential violations thereof;
												<br />
												<b>iii)</b> to protect the safety, rights, property or security ofthe
												Company, our Services or any third party;
												<br />
												<b>iv)</b> to protect the safety of the public for any reason;
												<br />
												<b>v)</b> to detect, prevent or otherwise address fraud, security or
												technical issues; and /or
												<br />
												<b>vi)</b> to preventor stop activity we may consider to be, or to pose
												a risk of being, an illegal, unethical, or legally actionable activity.
												<br />
												<br />
												<b>f) WHEN YOU SHARE INFORMATION</b>
												<br />
												Protected Information may be collected and shared with third-parties if
												there is content from the Mobile Application that you specifically and
												knowingly upload to, share with or transmit to an emailrecipient, online
												community, website, or to the public, e.g. uploaded photos, posted
												reviews orcomments, or information about you or your ride that you
												choose to share with others through features which may be provided on
												our Services. This uploaded, shared or transmitted content will also be
												subject to the privacy policy of the email, online community website,
												social media or other platform to which you upload, share or transmit
												the content.
												<br />
												<br />
												<b>g) BUSINESS TRANSFER</b>
												<br />
												We may share your information, including your Protected Information and
												Usage Information withour parent, subsidiaries and affiliates for
												internal reasons, including business and operational purposes. We also
												reserve the right to disclose and transfer all such information:
												<br />
												<b>i)</b> to a subsequent owner, co-owner or operator of the Services or
												applicable database; or
												<br />
												<b>ii)</b> inconnection with a corporate merger, consolidation,
												restructuring, the sale of substantially all of ourmembership interests
												and/or assets or other corporate change, including, during the course of
												anydue diligence process.
												<br />
												<br />
												<b>h) MARKET STUDY AND OTHER BENEFITS</b>
												<br />
												We may share your information, including your Protected Information and
												Usage Information with third parties for any purpose, including but not
												limited to undertaking market research/ study,conduct data analysis,
												determine and customize product or service offerings, to improve the
												products or Services or to make any other benefits/products/ services
												available to you.
												<br />
												<br />
												<b>i) WITH THE OWNER OF NOWWAGON ACCOUNTS THAT YOU MAY USE</b>
												<br />
												If you use an account or profile associated with another party we may
												share the details of the trip with the owner of the profile. This may
												occur:
												<br />
												<b> A.</b> If you use your employer’s profile under a corporate
												arrangement;
												<br />
												<b>B.</b> If you take trip arranged by a friend.
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>5. THIRD PARTY CONTENT AND LINKS TO THIRD PARTY SERVICES.</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												The Services may contain content that is supplied by a third party, and
												those third parties may collect website usage information and your
												Device Identifier when web pages from any online or mobile Services are
												served to your browser. In addition, when you are using the Services,
												you may be directed to other sites or applications that are operated and
												controlled by third parties thatwe do not control. We are not
												responsible for the privacy practices employed by any of these third
												parties. For example, if you click on a banner advertisement, the click
												may take you away fromone of our websites onto a different web site.
												These other web sites may send their own cookies to you, independently
												collect data or solicit Protected Information and may or may not have
												theirown published privacy policies. We encourage you to note when you
												leave our Services and to read the privacy statements of all third party
												web sites or applications before submitting any Protected Information to
												third parties.
											</p>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>6. SOCIAL MEDIA FEATURES AND WIDGETS</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												Our online and mobile Services may include social media features, such
												as the Facebook “Like” button, and widgets such as a “Share This”
												button, or interactive mini-programs that run on ouronline and mobile
												Services. These features may collect your IP address, photograph, which
												page you are visiting on our online or mobile Services, and may set a
												cookie to enable the feature to function properly. Social media features
												and widgets are either hosted by a third party or hosteddirectly on our
												online Services. Your interactions with these features and widgets are
												governed by the privacy policy of the company providing them.
											</p>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>7. INFORMATION COLLECTED BY DRIVERS</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												This Privacy Policy shall not cover the usage of any information about
												you which is obtained by the driver or the company to which the driver
												belongs, while transporting your goods on a truck booked using the
												Services, or otherwise, which is not provided by us.
											</p>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>8. CHANGE OF INFORMATION AND CANCELLATION OF ACCOUNT</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<b>a)</b> You are responsible for maintaining the accuracy of the
												information you submit to us, such asyour contact information provided
												as part of account registration. If your Protected Information changes,
												or if you no longer desire our Services, you may correct, delete
												inaccuracies, or amend information by making the change on our member
												information page or by contacting usthrough email address mentioned on
												our website or Mobile Application. We will make goodfaith efforts to
												make requested changes in our then active databases as soon as
												reasonably practicable.
												<br />
												<b>b)</b> You may also cancel or modify your communications that you
												have elected to receive from the Services by following the instructions
												contained within an e-mail or by logging into your useraccount and
												changing your communication preferences.
												<br />
												<b>c)</b> If upon modifying or changing the information earlier provided
												to us, we find it difficult to permitaccess to our Services to you due
												to insufficiency/ inaccuracy of the information, we may, in oursole
												discretion terminate your access to the Services by providing you a
												written notice to this effecton your registered email id.
												<br />
												<b>d)</b> If you wish to cancel your account or request that we no
												longer use your information to provide you services, contact us through
												email address mentioned on the trip bill received. We will retain your
												Protected Information and Usage Information (including geo-location) for
												as long as your account with the Services is active and as needed to
												provide you services. Even after your account is terminated, we will
												retain your Protected Information and Usage Information (including
												geo-location, trip history, and transaction history) as needed to comply
												with our legal and regulatory obligations, resolve disputes, conclude
												any activities related to cancellation of an account,investigate or
												prevent fraud and other inappropriate activity, to enforce our
												agreements, and forother business reason. After a period of time, your
												data may be anonymized and aggregated, andthen may be held by us as long
												as necessary for us to provide our Services effectively, but our useof
												the anonymized data will be solely for analytic purposes.
												<br />
											</p>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>9. SECURITY</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												The Protected Information and Usage Information we collect is securely
												stored within our Databases and we use standard, industry-wide,
												commercially reasonable security practices suchas encryption, firewalls
												and SSL (Secure Socket Layers) for protecting your information.
												However,as effective as encryption technology is, no security system is
												impenetrable. We cannot guarantee the security of our databases, nor can
												we guarantee that information you supply won't be intercepted while
												being transmitted to us over the Internet or wireless communication, and
												any information you transmit to the Company you do at your own risk. We
												recommend that you not disclose your password to anyone.
											</p>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>10. GRIEVANCE OFFICER</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												NowWagon hereby appoints NowWagon Support Manager as the grievance
												officer for the purposes of the rules drafted under the Information
												Technology Act, 2000, who may be contactedat <b>support@nowwagon.com</b>
												. You may address any grievances you may have in respect of this
												privacypolicy or usage of your Protected Information or other data to
												him.
											</p>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>11. CHANGES TO THE PRIVACY POLICY</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												From time to time, we may update this Privacy Policy to reflect changes
												to our information practices. Any changes will be effective immediately
												upon the posting of the revised Privacy Policy.If we make any material
												changes, we will notify you by email (sent to the e-mail address
												specifiedin your account) or by means of a notice on the Services prior
												to the change becoming effective.We encourage you to periodically review
												this page for the latest information on our privacy practices.
											</p>
										</AccordionItemPanel>
									</AccordionItem>
								</Accordion>
							</p>
						</Typography>
					</ThemeProvider>
				</GridItem>
			</Container>
		</Container>
	);
}
