import React from "react";
import CustomTabs from "../../components/CustomTabs/CustomTabs";

import AddIcon from "@material-ui/icons/Add";
import LaunchIcon from "@material-ui/icons/Launch";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import AutorenewIcon from "@material-ui/icons/Autorenew";

import OpenTickets from "./OpenTickets";
import CloseTickets from "./CloseTickets";
import OnHoldTickets from "./OnHoldTickets";
import InProgressTickets from "./InProgressTickets";

export default function TicketingSystem() {
	return (
		<CustomTabs
			headerColor="warning"
			tabs={[
				{
					tabName: "Open Tickets",
					tabIcon: LaunchIcon,
					allowed: true,
					variant: "fullWidth",
					tabContent: <OpenTickets />,
				},
				{
					tabName: "On Hold Tickets",
					tabIcon: AccessibilityIcon,
					allowed: true,
					variant: "fullWidth",
					tabContent: <OnHoldTickets />,
				},
				{
					tabName: "In Progress Tickets",
					tabIcon: AutorenewIcon,
					allowed: true,
					variant: "fullWidth",
					tabContent: <InProgressTickets />,
				},
				{
					tabName: "Closed Tickets",
					tabIcon: HighlightOffIcon,
					allowed: true,
					tabContent: <CloseTickets />,
				},
			]}
		/>
	);
}
