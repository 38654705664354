import React, { useState, useEffect } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
	Container,
	Grid,
	makeStyles,
	CircularProgress,
	Backdrop,
	Typography,
	ThemeProvider,
	createMuiTheme,
	MenuItem,
	TextField,
	Button,
	TableContainer,
	TableHead,
	Divider,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";

//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import { orange } from "@material-ui/core/colors";
import GridContainer from "../../components/Grid/GridContainer";
import stateDistrictData from "../../allStates-Districts.json";
import _, { forEach } from "lodash";
import { getAllProvider } from "../../services/users/userOnboarding";
import { getAllVehicles } from "../../services/vehicle/Vehicles";
import { getAllDrivers } from "../../services/drivers/Drivers";
import { getDescendingOrderedList } from "../Helper/Utils";
import { getAllRides } from "../../services/ride/Ride";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	title: {
		fontSize: 25,
		textAlign: "center",
	},
	customTableContainer: {
		overflowX: "initial",
	},

	tableCell: {
		Width: 100,
	},
	head: {
		backgroundColor: "#FFA93F",
		position: "sticky",
		top: 0,
		color: "#fff",
	},
	button: {
		margin: theme.spacing(1),
		backgroundColor: "#FF8000",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#c55100",
		},
	},
}));
const theme = createMuiTheme({
	palette: {
		primary: orange,
	},
});

export default function VehicalStatus() {
	const classes = useStyles();
	let navigate = useNavigate();

	const [open, setOpen] = useState(false);

	const [inputValues, setInputValues] = useState({
		state: "Maharashtra",
		city: "Pune",
		operator: "",
		Driver: "",
		fromDate: "",
		toDate: "",
	});
	const { state, city, operator, Driver, fromDate, toDate } = inputValues;
	const [stateData, setStateData] = useState([]);
	const [districts, setDistricts] = useState([]);
	const [provider, setProvider] = useState([]);
	const [allProvider, setAllProvider] = useState([]);
	const [allVehicle, setAllVehicle] = useState([]);
	const [vehicleList, setVehicleList] = useState([]);
	const [allProviderList, setAllProviderList] = useState([]);
	const [mainProviderList, setMainProviderList] = useState([]);
	const [allDriverList, setAllDriverList] = useState([]);
	const [allRidesData, setAllRidesData] = useState([]);
	const [allDriversList, setAllDriversList] = useState([]);
	const [mainDriversList, setMainDriversList] = useState([]);
	const [allDrivers, setAllDrivers] = useState([]);
	const [allDriversEmails, setAllDriversEmails] = useState([]);
	const [finalRowDataSet, setFinalRowDataSet] = useState([]);
	const getInitialValues = () => {
		return {
			state: "Maharashtra",
			city: "Pune",
			operator: "",
			Driver: "",
			fromDate: "",
			toDate: "",
		};
	};
	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message, variant) => {
		enqueueSnackbar(message, {
			variant: variant ? variant : "info",
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		});
	};
	const handleSearchClick = () => {
		if (fromDate === "" || toDate === "") {
			showToast("Please Select Dates", "error");
			return;
		}
		if (new Date(fromDate).getTime() > new Date(toDate).getTime()) {
			showToast("Please Select Valid Date", "error");
			return;
		}

		let filteredList = allRidesData;
		// if (state !== "") {
		// 	filteredList = filteredList.filter((element) => element.state == state);
		// }
		if (city !== "") {
			filteredList = filteredList.filter((element) => element.city == city);
		}
		if (fromDate !== "") {
			filteredList = filteredList.filter((element) => new Date(fromDate).getTime() <= element.tripDateMil);
		}
		let lastHourToDate = new Date(toDate);
		lastHourToDate.setHours(23, 59, 0);
		let lastHourToDateMil = lastHourToDate.getTime();
		if (toDate !== "") {
			filteredList = filteredList.filter((element) => element.tripDateMil <= lastHourToDateMil);
		}
		if (operator !== "") {
			let newList = [];
			filteredList.forEach((element) => {
				if (allDriversEmails.includes(element.driverEmail)) {
					newList.push(element);
				}
			});
			filteredList = newList;
		}
		if (Driver !== "") {
			filteredList = filteredList.filter((element) => element.driverEmail == Driver);
		}
		if (filteredList?.length > 0) {
			let perDayRides = {};
			filteredList.forEach((ride) => {
				if (ride.rideStatus === "Completed") {
					let tripDate = ride.tripDate;
					if (perDayRides[`${tripDate}`] === undefined) {
						perDayRides[`${tripDate}`] = {
							totalTrips: 1,
							totalRevenue: parseInt(ride.amount),
							tripDate: ride.tripDate,
							tripDateMil: new Date(ride.tripDate).getTime(),
						};
					} else {
						let rides = perDayRides[`${tripDate}`].totalTrips;
						let revenue = perDayRides[`${tripDate}`].totalRevenue;
						perDayRides[`${tripDate}`] = {
							totalTrips: rides + 1,
							totalRevenue: revenue + parseInt(ride.amount),
							tripDate: ride.tripDate,
						};
					}
				}
			});
			let finalRows = [];
			Object.keys(perDayRides).map((item) => {
				let nwCommision = (10 / 100) * perDayRides[item].totalRevenue;
				let nwCharges = perDayRides[item].totalRevenue - nwCommision;
				finalRows.push({
					...perDayRides[item],
					nwCommision: nwCommision,
					nwCharges: nwCharges,
				});
			});
			setFinalRowDataSet(_.orderBy(finalRows, "tripDateMil", "desc"));
		} else {
			setFinalRowDataSet([]);
		}
	};
	const handleChange = (name) => (event) => {
		let valueToSet = event.target.value;
		setInputValues({ ...inputValues, error: false, [name]: valueToSet });
		console.log(mainDriversList, valueToSet);
		if (name === "operator") {
			let driver = mainDriversList.filter((element) => element.ownerId == valueToSet);
			let driverData = [];
			let driverEmailsOnly = [];
			Object.keys(driver).forEach((key) => {
				driverData.push({ driverName: driver[key].firstName, driverEmail: driver[key].email });
				driverEmailsOnly.push(driver[key].email);
			});
			console.log(driverData);
			setAllDrivers(driverData);
			setAllDriversEmails(driverEmailsOnly);
		}
	};

	const handleChangeCity = (name) => (event) => {
		let selectedCity = event.target.value;
		setInputValues({ ...inputValues, city: selectedCity });

		// getAllProvider().then((response) => {
		// 	let selectedProvider = response.filter((provider) => provider.businessInfo.businessCity == selectedCity);

		// 	//console.log("response", selectedProvider);
		// 	setAllProvider(selectedProvider);
		// });
	};
	const handleChangeOperator = (name) => (event) => {
		let selectedOperator = event.target.value;
		setInputValues({
			...inputValues,
			//operator: selectedOperator,
			operator: selectedOperator.businessInfo.businessName,
		});
		getAllDrivers().then((response) => {
			let selectedDriver = response.filter((provider) => provider.ownerId == selectedOperator.businessInfo.officeEmail);
			setInputValues({ ...inputValues, Driver: selectedDriver });
			// //console.log("responsedriver",selectedDriver)
			//    setAllProvider(selectedProvider);
		});

		//console.log("selectedOperator", selectedOperator.businessInfo.officeEmail);
	};
	useEffect(() => {
		let driver = [];
		getAllProvider().then((response) => {
			let providerData = [];
			Object.keys(response).forEach((key) => {
				providerData.push({
					operatorName: response[key].businessInfo?.businessName,
					operatorEmail: response[key].businessInfo.officeEmail,
				});
			});
			setProvider(response);
			setAllProvider(getDescendingOrderedList(providerData));
		});

		getAllDrivers().then((response) => {
			console.log(response);
			setAllDriversList(getDescendingOrderedList(response));
			setMainDriversList(getDescendingOrderedList(response));
		});

		getAllRides().then((response) => {
			setAllRidesData(getDescendingOrderedList(response));
		});
	}, []);

	const closeLoader = () => {
		setOpen(false);
	};
	const toggleLoader = () => {
		setOpen(!open);
	};
	return (
		<Container>
			<Container maxWidth={false}>
				<GridItem xs={12} sm={12} md={12}>
					<Typography className={classes.title}> Daily Total Earning Report </Typography>
					<ThemeProvider theme={theme}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="state"
									label="State"
									type="text"
									id="state"
									select
									value={state}
									onChange={handleChange("state")}
								>
									<MenuItem value="Maharashtra">Maharashtra</MenuItem>
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="city"
									label="City"
									type="text"
									id="city"
									select
									value={city}
									onChange={handleChange("city")}
								>
									<MenuItem value="Pune">Pune</MenuItem>
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="operator"
									label="Operator"
									type="text"
									id="operator"
									select
									value={operator}
									onChange={handleChange("operator")}
								>
									{allProvider.map((value, index) => (
										<MenuItem value={value.operatorEmail} key={index}>
											{value.operatorName}
										</MenuItem>
									))}
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="Driver"
									label="Driver"
									type="text"
									id="Driver"
									select
									value={Driver}
									onChange={handleChange("Driver")}
								>
									{allDrivers.map((value, index) => (
										<MenuItem value={value.driverEmail} key={index}>
											{value.driverName}
										</MenuItem>
									))}
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									size="small"
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="fromDate"
									label="From Date"
									type="date"
									id="fromDate"
									value={fromDate}
									onChange={handleChange("fromDate")}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									size="small"
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="toDate"
									label="To Date"
									type="date"
									id="toDate"
									value={toDate}
									onChange={handleChange("toDate")}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={handleSearchClick}
								>
									Search
								</Button>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={() => {
										setInputValues(getInitialValues());
									}}
								>
									Clear
								</Button>
							</GridItem>
						</GridContainer>
						<GridContainer style={{ paddingTop: 20 }}>&nbsp;</GridContainer>
						<Divider></Divider>
						<GridContainer>
							<Table className={classes.table}>
								<TableBody>
									<TableRow>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Sr No.
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Date
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Total Number of Trips
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Total Revenue
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												NowWagon Commission
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Operator Revenue
											</Typography>
										</TableCell>
									</TableRow>
									{finalRowDataSet.map((row, index) => (
										<TableRow className={classes.tableRow} key={index}>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{index + 1}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{row.tripDate}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{row.totalTrips}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													₹{row.totalRevenue}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													₹{row.nwCommision && row.nwCommision.toFixed(2)}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													₹{row.nwCharges}
												</Typography>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
							{/* </TableContainer> */}
						</GridContainer>
					</ThemeProvider>
				</GridItem>
			</Container>
			<Backdrop className={classes.backdrop} open={open} onClick={closeLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Container>
	);
}
