import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout/DashboardLayout';
import MainLayout from './layouts/MainLayout/MainLayout';
import DashboardView from './screens/Dashboard/Dashboard';
import AddUserView from './screens/user/AddUserView';
import UpdateUserView from './screens/user/UpdateUserView';

import LoginView from './screens/auth/LoginView';
import SignUpView from './screens/auth/SignUpView';
import ResetPwd from './screens/auth/ResetPwd';
import UserListView from './screens/user/UserListView';
import UserManagement from './screens/user/UserManagement';

import AddExecutiveView from './screens/user/AddExecutiveView';
import ExecutiveListView from './screens/user/ExecutiveListView';
import ExecutiveManagement from './screens/user/ExecutiveManagement';
import ExecutiveProfile from './screens/user/ExecutiveProfile';
import notFound from './screens/Error/notFound';
import DriverListView from './screens/user/DriverListView';
import VehicleListView from './screens/user/VehicleListView';
import TicketingSystem from './screens/ticketingSystem/TicketingSystem';
import Reports from './screens/reports/Reports';
import Notification from './screens/notification/Notification';
//import TransportationCharges from './screens/transportationCharges/TransportationCharges';
import DailyTotalEarningReport from './screens/reports/DailyTotalEarningReport';
import DriverPerformanceReport from './screens/reports/DriverPerformanceReport';
import VehicleType from './screens/vehicleType/VehicleType';
import ChangePwd from './screens/auth/ChangePwd';
import VehicleStatusReport from './screens/reports/VehicleStatusReport';
import TripHistoryReport from './screens/reports/TripHistoryReport';
import HelpDeskReport from './screens/reports/HelpDeskReport';
import InsuranceReport from './screens/reports/InsuranceReport';
import VehicleTracking from './screens/vehicleTracking/VehicleTracking';
import OpenTickets from './screens/ticketingSystem/OpenTickets';
import CloseTickets from './screens/ticketingSystem/CloseTickets';
import OnHoldTickets from './screens/ticketingSystem/OnHoldTickets';
import InProgressTickets from './screens/ticketingSystem/InProgressTickets';

//import TransportationChargesList from './screens/transportationCharges/TransportationChargesList';
//import TransportationChargesManagement from './screens/transportationCharges/TransportationChargesManagement';
import VehicleTypeList from './screens/vehicleType/VehicleTypeList';
import VehicleTypeManagement from './screens/vehicleType/VehicleTypeManagement';
import HelpDeskScreen from './screens/helpDesk/HelpDeskScreen';
import PrivacyPolicyScreen from './screens/privacyPolicy/PrivacyPolicyScreen';
import FAQScreen from './screens/FAQ/FAQScreen';
import TermsConditionsScreen from './screens/TermsAndConditions/TermsAndConditionsScreen';
import AboutUsScreen from './screens/aboutUs/AboutUsScreen';
import OperatorReport from './screens/reports/OperatorReport';
import CustomerReport from './screens/reports/CustomerReport';
import TermsAndConditionsScreen from './screens/TermsAndConditions/TermsAndConditionsScreen';
import AdminManagement from './screens/user/AdminManagement';
import FareManagement from './screens/user/FareManagement';

const routes = [
	{
		path: 'app',
		element: <DashboardLayout />,
		children: [
			{ path: 'dashboard', element: <DashboardView /> },
			{ path: 'addUser/:mode', element: <AddUserView /> },
			{ path: 'updateUser', element: <UpdateUserView /> },
			{ path: 'addExecutive', element: <AddExecutiveView /> },
			{ path: 'usersList', element: <UserListView /> },
			{ path: 'executivesList', element: <ExecutiveListView /> },
			{ path: 'userManagement/:mode', element: <UserManagement /> },
			{ path: 'executiveManagement', element: <ExecutiveManagement /> },
			{ path: 'adminManagement', element: <AdminManagement /> },
			{ path: 'FareManagement', element:  <FareManagement /> },
			{ path: 'executiveProfile', element: <ExecutiveProfile /> },
			{ path: 'providerProfile', element: <ExecutiveProfile /> },
			{ path: 'driverProfile', element: <ExecutiveProfile /> },
			{ path: 'driversList', element: <DriverListView /> },
			{ path: 'vehicleList', element: <VehicleListView /> },
			{ path: 'ticketingSystem', element: <TicketingSystem /> },
			{ path: 'openTickets', element: <OpenTickets /> },
			{ path: 'closeTickets', element: <CloseTickets /> },
			{ path: 'onHoldTickets', element: <OnHoldTickets /> },
			{ path: 'inProgressTickets', element: <InProgressTickets /> },

			{ path: 'reports', element: <Reports /> },
			{ path: 'notification', element: <Notification /> },
			//   { path: 'transportationCharges', element: <TransportationCharges /> },
			//  { path: 'trasnportationChargesList', element: <TransportationChargesList /> },
			// { path: 'transportationChargesManagement', element: <TransportationChargesManagement /> },

			{ path: 'vehicleStatusReport', element: <VehicleStatusReport /> },
			{ path: 'dailyTotalEarningReport', element: <DailyTotalEarningReport /> },
			{ path: 'driverPerformanceReport', element: <DriverPerformanceReport /> },
			{ path: 'tripHistoryReport', element: <TripHistoryReport /> },
			{ path: 'helpDeskReport', element: <HelpDeskReport /> },
			{ path: 'insuranceReport', element: <InsuranceReport /> },
			{ path: 'vehicleTypeList', element: <VehicleTypeList /> },
			{ path: 'vehicleTypeManagement', element: <VehicleTypeManagement /> },
			{ path: 'operatorReport', element: <OperatorReport /> },
			{ path: 'customerReport', element: <CustomerReport /> },

			{ path: 'helpDesk', element: <HelpDeskScreen /> },
			{ path: 'PrivacyPolicy', element: <PrivacyPolicyScreen /> },
			{ path: 'FAQ', element: <FAQScreen /> },
			{ path: 'aboutUs', element: <AboutUsScreen /> },
			{ path: 'TermsAndConditions', element: <TermsAndConditionsScreen /> },

			{ path: 'vehicleType', element: <VehicleType /> },
			{ path: 'changePwd', element: <ChangePwd /> },
			{ path: 'vehicleTracking', element: <VehicleTracking /> },
		],
	},
	{
		path: '/',
		element: <MainLayout />,
		children: [
			{ path: 'login', element: <LoginView /> },
			{ path: 'register', element: <SignUpView /> },
			{ path: 'resetpwd', element: <ResetPwd /> },
			{ path: '404', element: <notFound /> },
			{ path: '/', element: <Navigate to="/login" /> },
			{ path: '*', element: <Navigate to="/404" /> },
		],
	},
];

export default routes;
