import React from 'react';
import CustomTabs from '../../components/CustomTabs/CustomTabs';

import AddIcon from '@material-ui/icons/Add';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import AddUserView from './AddUserView';
import UserListView from './UserListView';

export default function UserManagement() {
	return (
		<CustomTabs
			headerColor="warning"
			tabs={[
				{
					tabName: 'Operator List',
					tabIcon: FormatListBulletedIcon,
					allowed: true,
					variant: 'fullWidth',
					tabContent: <UserListView />,
				},
				{
					tabName: 'Operator Onboarding',
					tabIcon: AddIcon,
					allowed: true,
					tabContent: <AddUserView />,
				},
			]}
		/>
	);
}
