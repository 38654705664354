import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import {
	Backdrop,
	Button,
	CircularProgress,
	Fade,
	Modal,
	TableContainer,
	TableHead,
	TextareaAutosize,
	TextField,
	Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";

import Lottie from "react-lottie";
import animationData from "../../assets/jss/lf30_editor_fvhlirkb.json";
import { getAllvehicles, updatevehicle } from "../../services/vehicle/Vehicles";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import { getAllInProgressTickets, getAllOpenTickets, updateTicket } from "../../services/ticketingSystem/Tickets";
import { useSnackbar } from "notistack";
import _ from "lodash";

const useStyles = makeStyles((styles) => ({
	backdrop: {
		zIndex: styles.zIndex.drawer + 1,
		color: "#fff",
	},

	customTableContainer: {
		overflowX: "initial",
	},

	tableCell: {
		Width: 100,
	},
	head: {
		backgroundColor: "#FFA93F",
		position: "sticky",
		top: 0,
		color: "#fff",
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	paperModal: {
		backgroundColor: styles.palette.background.paper,
		border: "2px solid #000",
		boxShadow: styles.shadows[5],
		padding: styles.spacing(2, 4, 3),
		width: "50%",
	},
	button: {
		// margin: theme.spacing(1),
		backgroundColor: "#FF8000",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#c55100",
		},
	},
}));

const PurpleSwitch = withStyles({
	switchBase: {
		"&$checked": {
			color: "#FF8000",
		},
		"&$checked + $track": {
			backgroundColor: "#FF8000",
		},
	},
	checked: {},
	track: {},
})(Switch);

const loaderOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};

export default function InProgressTickets() {
	const classes = useStyles();

	const [openModal, setOpenModal] = useState(false);
	const [openLoader, setOpenLoader] = useState(false);
	const [ticketData, setTicketData] = useState([]);

	const [selectedTicket, setSelectedTicket] = useState(null);
	const [comment, setComment] = useState("");

	// Noti snackbar handling...
	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message, variant) => {
		enqueueSnackbar(message, {
			variant: variant ? variant : "info",
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		});
	};

	const handleChangeComment = (event) => {
		setComment(event.target.value);
	};

	const handleOpenModal = (ticket) => {
		setComment("");
		setSelectedTicket(ticket);
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setSelectedTicket(null);
		setOpenModal(false);
	};

	const closeLoader = () => {
		setOpenLoader(false);
	};

	const toggleLoader = () => {
		setOpenLoader(!openLoader);
	};

	const onClose = (ticket) => {
		if (comment && comment.trim() != "") {
			ticket.ticketStatus = "close";
			ticket.executiveComment = comment;
			ticket.modifiedOn = new Date();
			ticket.modifiedOnMil = new Date().getTime();
			ticket.closedOn = new Date();
			ticket.closedOnMil = new Date().getTime();
			toggleLoader();
			updateTicket(ticket)
				.then((response) => {
					closeLoader();
					handleCloseModal();
					showToast("Ticket marked Closed Successfully", "success");
					toggleLoader();
					fetchAllInProgressTickets();
				})
				.catch((err) => {
					showToast("Error Updating Ticket", "error");
				});
		} else {
			showToast("Please enter closing comment", "error");
		}
	};

	const onHold = (ticket) => {
		if (comment && comment.trim() != "") {
			ticket.ticketStatus = "onHold";
			ticket.executiveComment = comment;
			ticket.modifiedOn = new Date();
			ticket.modifiedOnMil = new Date().getTime();
			toggleLoader();
			updateTicket(ticket)
				.then((response) => {
					handleCloseModal();
					closeLoader();
					showToast("Ticket marked On hold Successfully", "success");
					toggleLoader();
					fetchAllInProgressTickets();
				})
				.catch((err) => {
					showToast("Error Updating Ticket", "error");
				});
		} else {
			showToast("Please enter comment for marking On Hold", "error");
		}
	};

	useEffect(() => {
		toggleLoader();
		fetchAllInProgressTickets();
		/* getAllInProgressTickets().then((inProgressTickets) => {
			closeLoader();
			inProgressTickets =  _.orderBy(inProgressTickets, 'modifiedOnMil', 'desc');
			setTicketData(inProgressTickets);
		}); */
	}, []);

	const fetchAllInProgressTickets = () => {
		getAllInProgressTickets()
			.then((fetchedTickets) => {
				closeLoader();
				let sortedTickets = _.orderBy(fetchedTickets, "modifiedOnMil", "desc");
				setTicketData([...sortedTickets]);
			})
			.catch((error) => {
				showToast("Problem loading tickets", "error");
			});
	};

	return (
		<>
			<TableContainer component={Paper} className={classes.customTableContainer}>
				<Table>
					<TableHead>
						<TableRow>
							{/* <TableCell className={classes.head}>
              <Typography variant="h4" gutterBottom className={classes.titleColor}>Ticket ID</Typography>
            </TableCell>
            <TableCell className={classes.head} >
              <Typography variant="h4" gutterBottom className={classes.titleColor}>Category</Typography>
            </TableCell>
            <TableCell  className={classes.head}>
              <Typography variant="h4" gutterBottom className={classes.titleColor}>Sub-Category</Typography>
            </TableCell> */}
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Sr.No
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Created By
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Description
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Creator's Comment
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Executive's Comment
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Actions
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{ticketData.map((ticket, index) => (
							<TableRow key={index} className={classes.tableRow}>
								{/* <TableCell>
                  <Typography variant="body1" gutterBottom>{ticket.id}</Typography>
                </TableCell>
                <TableCell >
                  <Typography variant="body1" gutterBottom>{ticket.category}</Typography>
                </TableCell>
                <TableCell >
                  <Typography variant="body1" gutterBottom>{ticket.subCategory}</Typography>
                </TableCell> */}
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{index + 1}
									</Typography>
								</TableCell>

								<TableCell>
									<Typography variant="body1" gutterBottom>
										{ticket.ticketOwner}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{ticket.issueDescription}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{ticket.operatorComment || ticket.driverComment || ticket.customerComment || ""}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{ticket.executiveComment || ""}
									</Typography>
								</TableCell>
								<TableCell>
									<EditIcon onClick={() => handleOpenModal(ticket)} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Backdrop className={classes.backdrop} open={openLoader} onClick={closeLoader}>
				{/* <Lottie 
	        options={loaderOptions}
            height={200}
            width={600}
        /> */}
				<CircularProgress color="inherit" />
			</Backdrop>
			{selectedTicket !== null && (
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className={classes.modal}
					open={openModal}
					onClose={handleCloseModal}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={openModal}>
						<div className={classes.paperModal}>
							<GridContainer>
								<GridItem xs={12} sm={12} md={4}>
									<strong>Ticket ID:</strong>
								</GridItem>
								<GridItem xs={12} sm={12} md={8}>
									<Typography variant="body2" noWrap>
										{selectedTicket.id}
									</Typography>
								</GridItem>
							</GridContainer>
							{/* <GridContainer>&nbsp;</GridContainer> */}
							{/* <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <strong>Creation Date:</strong>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8}>
                    <Typography variant="body2" noWrap><Moment format='YYYY-MM-DD:hh-mm'>{selectedOrder.createOn}</Moment></Typography>
                  </GridItem>
                </GridContainer> */}
							<GridContainer>&nbsp;</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={4}>
									<strong>Issue Description:</strong>
								</GridItem>
								<GridItem xs={12} sm={12} md={8}>
									<Typography variant="body2">{selectedTicket.issueDescription}</Typography>
								</GridItem>
							</GridContainer>
							<GridContainer>&nbsp;</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={4}>
									<strong>Category</strong>
								</GridItem>
								<GridItem xs={12} sm={12} md={8}>
									<Typography variant="body2" noWrap>
										{selectedTicket.category}
									</Typography>
								</GridItem>
							</GridContainer>
							<GridContainer>&nbsp;</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={4}>
									<strong>Sub Category</strong>
								</GridItem>
								<GridItem xs={12} sm={12} md={8}>
									<Typography variant="body2" noWrap>
										{selectedTicket.subCategory}
									</Typography>
								</GridItem>
							</GridContainer>
							<GridContainer>&nbsp;</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={4}>
									<strong>Created By:</strong>
								</GridItem>
								<GridItem xs={12} sm={12} md={8}>
									<Typography variant="body2" noWrap>
										{selectedTicket.ticketOwner}
									</Typography>
								</GridItem>
							</GridContainer>
							<GridContainer>&nbsp;</GridContainer>
							{selectedTicket.executiveComment && (
								<>
									<GridContainer>
										<GridItem xs={12} sm={12} md={4}>
											<strong>Executive's Comment: </strong>
										</GridItem>
										<GridItem xs={12} sm={12} md={8}>
											<Typography variant="body2">{selectedTicket.executiveComment}</Typography>
										</GridItem>
									</GridContainer>
									<GridContainer>&nbsp;</GridContainer>
								</>
							)}
							{selectedTicket.operatorComment && (
								<>
									<GridContainer>
										<GridItem xs={12} sm={12} md={4}>
											<strong>Operator's Comment: </strong>
										</GridItem>
										<GridItem xs={12} sm={12} md={8}>
											<Typography variant="body2">{selectedTicket.operatorComment}</Typography>
										</GridItem>
									</GridContainer>
									<GridContainer>&nbsp;</GridContainer>
								</>
							)}
							{selectedTicket.driverComment && (
								<>
									<GridContainer>
										<GridItem xs={12} sm={12} md={4}>
											<strong>Driver's Comment: </strong>
										</GridItem>
										<GridItem xs={12} sm={12} md={8}>
											<Typography variant="body2">{selectedTicket?.driverComment}</Typography>
										</GridItem>
									</GridContainer>
									<GridContainer>&nbsp;</GridContainer>
								</>
							)}
							{selectedTicket.customerComment && (
								<>
									<GridContainer>
										<GridItem xs={12} sm={12} md={4}>
											<strong>Customer's Comment: </strong>
										</GridItem>
										<GridItem xs={12} sm={12} md={8}>
											<Typography variant="body2">{selectedTicket?.customerComment}</Typography>
										</GridItem>
									</GridContainer>
									<GridContainer>&nbsp;</GridContainer>
								</>
							)}
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										label="Comment"
										name="comment"
										id="comment"
										type="text"
										value={comment}
										onChange={handleChangeComment}
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={9}>
									&nbsp;
								</GridItem>

								<GridContainer xs={12} sm={12} md={12}>
									<GridItem xs={12} sm={12} md={3}>
										<Button
											style={{ marginTop: 20 }}
											variant="contained"
											className={classes.button}
											fullWidth="true"
											onClick={() => {
												onClose(selectedTicket);
											}}
										>
											Mark Closed
										</Button>
									</GridItem>
									<GridItem xs={12} sm={12} md={3}>
										<Button
											style={{ marginTop: 20 }}
											variant="contained"
											className={classes.button}
											fullWidth="true"
											onClick={() => {
												onHold(selectedTicket);
											}}
										>
											Mark On Hold
										</Button>
									</GridItem>
								</GridContainer>
							</GridContainer>
						</div>
					</Fade>
				</Modal>
			)}
		</>
	);
}
