import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";

import { Link as RouterLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
	AppBar,
	Badge,
	Box,
	Hidden,
	IconButton,
	Toolbar,
	makeStyles,
	TextField,
	Typography,
	MenuItem,
	Menu,
	ListItemIcon,
	ListItemText,
	Grid,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import { LogOut, Settings, User, UserCheck, Info } from "react-feather";
import { Drafts, MoreVert } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import FbApp from "../../config/FbApp";
// import Logo from 'src/components/Logo';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#FF8000",
	},
	avatar: {
		width: 60,
		height: 60,
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: "#FF8000",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#c55100",
		},
	},
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
	let profile = JSON.parse(localStorage.getItem("USER_PROFILE"));
	let username = localStorage.getItem("USERNAME");
	// Noti snackbar handling...
	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message, variant) => {
		enqueueSnackbar(message, {
			variant: variant ? variant : "info",
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		});
	};
	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="up" ref={ref} {...props} />;
	});

	const classes = useStyles();
	const [notifications] = useState([]);
	let navigate = useNavigate();

	const [open, setOpen] = React.useState(false);
	const [anchor, setAnchor] = React.useState(null);

	const handleOpenMenu = (event) => {
		setAnchor(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchor(null);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		refreshPage();
	};

	const openMenu = () => {};
	function refreshPage() {
		window.location.reload(false);
	}

	const navigateToLogin = async () => {
		localStorage.removeItem("USERNAME");
		localStorage.removeItem("USER_PROFILE");
		localStorage.removeItem("IsLoggedIn");
		localStorage.removeItem("USER_PASSWORD");
		localStorage.clear();
		await FbApp.auth().signOut();
		showToast("Logged out successfully", "success");
		navigate("/login");
	};

	return (
		<>
			<AppBar
				className={clsx(classes.root, className)}
				elevation={0}
				{...rest}
				// color="#FF8000"
			>
				<Toolbar>
					<Typography style={{ color: "#FFFF" }} component="h2" variant="h3">
						Welcome to NowWagon
					</Typography>

					<Box flexGrow={1} />
					{/* <IconButton color="inherit" onClick={() => navigate('/app/userProfile')}>
						<Badge
							badgeContent={notifications.length}
							color="#FF8000"
							variant="dot"
						>
						</Badge>
					</IconButton> */}
					{/* <User /> */}
					<Hidden>
						<>
							{profile ? (
								<>
									<Grid>
										<Typography component="h1" variant="h4">
											{profile.firstName} {profile.lastName}
										</Typography>

										<Typography component="h1" variant="caption">
											{profile.role}
										</Typography>
									</Grid>
								</>
							) : null}
						</>
						{/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="#FF8000"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}

						<IconButton color="inherit">
							<MoreVert onClick={handleOpenMenu} />
						</IconButton>
						<Menu id="simple-menu" anchorEl={anchor} keepMounted open={Boolean(anchor)} onClose={handleCloseMenu}>
							{profile.role && profile.role == "EXECUTIVE" && (
								<MenuItem
									onClick={() => {
										if (profile.role && profile.role == "EXECUTIVE") {
											navigate("/app/executiveProfile");
										}
									}}
								>
									<ListItemIcon>
										<UserCheck fontSize="small" />
									</ListItemIcon>
									<ListItemText primary="Profile" />
								</MenuItem>
							)}
							<MenuItem onClick={() => navigate("/app/changePwd")}>
								<ListItemIcon>
									<Settings fontSize="small" />
								</ListItemIcon>
								<ListItemText primary="Change Password" />
							</MenuItem>
							<MenuItem onClick={handleClickOpen}>
								<ListItemIcon>
									<LogOut fontSize="small" />
								</ListItemIcon>
								<ListItemText primary="Logout" />
							</MenuItem>
							<MenuItem>
								<ListItemIcon>
									<Info fontSize="small" />
								</ListItemIcon>
								<ListItemText primary={process.env.REACT_APP_VERSION} />
							</MenuItem>
						</Menu>
					</Hidden>
					<Hidden lgUp>
						<IconButton color="inherit" onClick={onMobileNavOpen}>
							<MenuIcon />
						</IconButton>
					</Hidden>
				</Toolbar>
			</AppBar>

			<Dialog
				component={Paper}
				fullWidth="true"
				maxWidth="xs"
				open={open}
				TransitionComponent={Transition}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Thanks {profile?.firstName} {profile?.lastName}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">Do you want to LOGOUT?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" className={classes.submit}>
						Cancel
					</Button>
					<Button onClick={navigateToLogin} color="primary" className={classes.submit} autoFocus>
						LOGOUT
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default TopBar;
