import React, { useState,useEffect } from 'react';
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell"

import { Backdrop, CircularProgress, TableContainer, TableHead, Typography } from "@material-ui/core";
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';

import Lottie from 'react-lottie';
import animationData from '../../assets/jss/lf30_editor_fvhlirkb.json';
import { getAllvehicles, getVehicleType, updatevehicle } from '../../services/vehicle/Vehicles';
import { getTransportationCharges } from '../../services/transportationCharges/TransportationCharges';




const useStyles = makeStyles(styles => ({
    backdrop: {
      zIndex: styles.zIndex.drawer + 1,
      color: '#fff',
    },
  
    customTableContainer: {
      overflowX: "initial"
    },
   
    tableCell:{
      Width:100,
    },
    head: {
      backgroundColor: "#FFA93F",
      position: "sticky",
      top: 0,
      color:'#fff' 
    }
  }));

  const PurpleSwitch = withStyles({
    switchBase: {
      '&$checked': {
        color: "#FF8000",
      },
      '&$checked + $track': {
        backgroundColor:"#FF8000",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const loaderOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
export default function VehicleTypeList() {
  const classes = useStyles();

  
const [openLoader,setOpenLoader] = useState(false);
const [transportationChargesData,setTransportationChargesData]=useState([]);
const [vehicleTypeData,setVehicleTypeData]=useState([])


  const closeLoader=() =>{
    setOpenLoader(false);
  }

  const toggleLoader = () => {
    setOpenLoader(!openLoader);
  }

  useEffect(()=>{
      getVehicleType().then(response=>{
          setTransportationChargesData(response)
          //console.log('TransportationChargesData',response)
      })
  },[])

    return(
      <>
    <TableContainer component={Paper} className={classes.customTableContainer}>
    <Table>
        <TableHead>
          <TableRow >
          <TableCell className={classes.head}>
              <Typography variant="h4" gutterBottom className={classes.titleColor}>Sr.No.</Typography>
            </TableCell>
            <TableCell className={classes.head} >
              <Typography variant="h4" gutterBottom className={classes.titleColor}>Vehicle Type</Typography>
            </TableCell>
            <TableCell  className={classes.head}>
              <Typography variant="h4" gutterBottom className={classes.titleColor}>Vehicle Loadsize</Typography>
            </TableCell>
            <TableCell className={classes.head}>
              <Typography variant="h4" gutterBottom className={classes.titleColor}>Description</Typography>
            </TableCell>
            <TableCell className={classes.head}>
              <Typography variant="h4" gutterBottom className={classes.titleColor}>Manufacturer</Typography>
            </TableCell>
            <TableCell className={classes.head}>
              <Typography variant="h4" gutterBottom className={classes.titleColor}>Manufacturing Date</Typography>
            </TableCell>
          </TableRow>   
          </TableHead>
        <TableBody>
        {transportationChargesData.map((value,index)=>(
            <TableRow key={index} className={classes.tableRow}>
              <TableCell >
                <Typography variant="body1" gutterBottom>{index+1}</Typography>
              </TableCell>
              <TableCell >
                <Typography variant="body1" gutterBottom>{value.vehicleType}</Typography>
              </TableCell>
              <TableCell >
                <Typography variant="body1" gutterBottom>{value.vehicleLoadSize}</Typography>
              </TableCell>
              <TableCell >
                <Typography variant="body1" gutterBottom>{value.vehicleDescription}</Typography>
              </TableCell>
              <TableCell >
                <Typography variant="body1" gutterBottom>{value.vehicleManufacture}</Typography>
              </TableCell>
              <TableCell >
                <Typography variant="body1" gutterBottom>{value.vehicleManufactureDate}</Typography>
              </TableCell>
            </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  <Backdrop className={classes.backdrop} open={openLoader} onClick={closeLoader}>
        {/* <Lottie 
	        options={loaderOptions}
            height={200}
            width={600}
        /> */}
        <CircularProgress color="inherit" />
</Backdrop>
  </>
  )

}