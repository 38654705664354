import { Email } from '@material-ui/icons';
import FbApp from '../../config/FbApp';

export default function Notifications() {}

export const sendNotifications = (payload) => {
	const db = FbApp.firestore();
	var userRef = db.collection('PULL_NOTIFICATIONS');
	return userRef
		.doc()
		.set(payload)
		.then((response) => {
			return response;
		});
};

export const setPullNotificationsStatus = (emailId) => {
	const db = FbApp.firestore();
	var userRef = db.collection('PULL_NOTIFICATIONS_STATUS');
	return userRef
		.doc(emailId)
		.set({ unread: true })
		.then((response) => {
			return response;
		});
};

// const getPullNotificationsStatus = (emailId) => {
// 	const db = FbApp.firestore();
// 	var docRef = db.collection('PULL_NOTIFICATIONS_STATUS').doc(emailId).add(data);
// 	return docRef
// 		.get()
// 		.then((doc) => {
// 			if (doc.exists) {
// 				return doc.data();
// 			}
// 		})
// 		.catch((error) => {
// 			//console.log("Error fetching user data:", error);
// 			return error;
// 		});
// };
