import "react-perfect-scrollbar/dist/css/styles.css";
import React, { useEffect } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "./components/GlobalStyles";
import theme from "./theme/theme";
import routes from "./routes";
import { SnackbarProvider } from "notistack";
import { getAdminOrExecutiveByUserName } from "./services/users/userOnboarding";

function App() {
	const routing = useRoutes(routes);
	const navigate = useNavigate();

	// temp impl for login.
	useEffect(async () => {
		let user = JSON.parse(await localStorage.getItem("USER_PROFILE"));
		if (user && user != null && (user.role == "ADMIN" || user.role == "EXECUTIVE" || user.role == "SUPER_ADMIN")) {
			let dbUser = await getAdminOrExecutiveByUserName(user.email)
				.then((user) => {
					////console.log(user);
					// if(user.isActive){
					//   navigate("/app/dashboard");
					// }else{
					//   ////console.log('User may be deactivated, Please check with admin', 'warning');
					//   navigate("/login");
					// }
					if (user.isActive === false) {
						////console.log('User may be deactivated, Please check with admin', 'warning');
						navigate("/login");
					}
				})
				.catch((error) => {
					navigate("/login");
					////console.log(error);
				});
			////console.log('role',user.role);
		} else {
			navigate("/login");
		}
	}, []);
	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider autoHideDuration={3000} maxSnack={5} preventDuplicate={true}>
				<GlobalStyles />
				{routing}
			</SnackbarProvider>
		</ThemeProvider>
	);
}

export default App;
