import React, { useState, useEffect } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
	Grid,
	makeStyles,
	CircularProgress,
	Backdrop,
	Typography,
	ThemeProvider,
	createMuiTheme,
	TextField,
	Button,
	TableContainer,
	Divider,
	Chip,
	TableHead,
} from "@material-ui/core";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";

import { orange } from "@material-ui/core/colors";
import GridContainer from "../../components/Grid/GridContainer";
import { getAllTickets } from "../../services/ticketingSystem/Tickets";
import _ from "lodash";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import * as XLSX from 'xlsx';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	title: {
		fontSize: 25,
		textAlign: "center",
	},
	customTableContainer: {
		// overflowX: "overflowX",
		maxHeight: 700,
	},
	tableCell: {
		// Width: 100,
		textAlign: "center",
	},
	tableRow: {
		minWidth: 20,
	},
	head: {
		backgroundColor: "#FFA93F",
		position: "sticky",
		top: 0,
		color: "#fff",
		textAlign: "center",
	},
	table: {
		position: "sticky",
	},
	subcategorycss: {
		wordWrap: "break-word",
		width: "100",
	},
	button: {
		margin: theme.spacing(1),
		backgroundColor: "#FF8000",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#c55100",
		},
	},
}));
const theme = createMuiTheme({
	palette: {
		primary: orange,
	},
});

export default function DriverPerformanceReport() {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [inputValues, setInputValues] = useState({
		fromDate: "",
		toDate: "",
	});
	const { fromDate, toDate } = inputValues;
	const [allTicket, setAllTicket] = useState([]);
	const [mainTicketList, setMainTicketList] = useState([]);

	// Noti snackbar handling...
	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message, variant) => {
		enqueueSnackbar(message, {
			variant: variant ? variant : "info",
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		});
	};

	const handleChange = (name) => (event) => {
		let valueToSet = event.target.value;
		setInputValues({ ...inputValues, error: false, [name]: valueToSet });
	};
	const onHandleButton = () => {
		if (fromDate !== "" && toDate !== "") {
			let fromDateMil = new Date(fromDate).getTime();
			let lastHourToDate = new Date(toDate);
			lastHourToDate.setHours(23, 59, 0);
			let lastHourToDateMil = lastHourToDate.getTime();
			if (fromDateMil > lastHourToDateMil) {
				showToast("Invalid Date Selected", "error");
				return;
			}
		}
		let fileteredTickets = [...mainTicketList];
		if (fromDate && fromDate !== "") {
			let fromTime = moment(fromDate).toDate().getTime();
			fileteredTickets = fileteredTickets.filter((element) => element.createdOnMil >= fromTime);
		}
		let lastHourToDate = new Date(toDate);
		lastHourToDate.setHours(23, 59, 0);
		let lastHourToDateMil = lastHourToDate.getTime();
		if (toDate && toDate !== "") {
			fileteredTickets = fileteredTickets.filter((element) => element.createdOnMil <= lastHourToDateMil);
		}
		setAllTicket(fileteredTickets);
	};

	const onClear = () => {
		inputValues.fromDate = "";
		inputValues.toDate = "";
		setInputValues({ ...inputValues });
		setAllTicket([...mainTicketList]);
	};

	useEffect(() => {
		getAllTickets()
			.then((fetchedTickets) => {
				let sortedTickets = _.orderBy(fetchedTickets, "createdOnMil", "desc");
				setAllTicket([...sortedTickets]);
				setMainTicketList([...sortedTickets]);
			})
			.catch((error) => {
				showToast("Error fetching ticket data", "error");
			});
	}, []);

	const closeLoader = () => {
		setOpen(false);
	};

	const toggleLoader = () => {
		setOpen(!open);
	};

	const exportToExcel = () => {
		let excelElements = [];
		allTicket.map((excel,index)=>{
			let excelElement = {
				"Sr.No": index + 1,
				"Category":excel?.category,
				"Sub-Category":excel?.subCategory,
				"Description":excel?.issueDescription,
				"Created By": excel?.ticketOwner,
				"Status": excel?.ticketStatus,
				"Created On": excel?.createdOnMil && moment(excel?.createdOnMil).format("DD/MM/YYYY"),
				"Modified On":excel?.modifiedOnMil && moment(excel?.modifiedOnMil).format("DD/MM/YYYY"),
				"Closed On":excel?.closedOnMil && moment(excel?.closedOnMil).format("DD/MM/YYYY"),
				"Creator's Comment": excel?.operatorComment || excel?.driverComment || excel?.customerComment || "-",
				"Executive's Comment": excel?.executiveComment,
			  };
			  excelElements.push(excelElement);
			  return excel;
		});
		 const wb = XLSX.utils.book_new();
		 const ws1 = XLSX.utils.json_to_sheet(excelElements);
		 XLSX.utils.book_append_sheet(wb, ws1, 'Help Desk Status');
		 XLSX.writeFile(wb, `Help Desk report.xlsx`);
	};

	return (
		<>
			{/* // <Container> */}
			{/* <Container maxWidth={false}> */}
			<GridItem xs={12} sm={12} md={12} sx={{ width: "100%", overflow: "hidden" }}>
				<Typography className={classes.title}> Help Desk Report </Typography>
				<ThemeProvider theme={theme}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={3}>
							<TextField
								InputLabelProps={{
									shrink: true,
								}}
								size="small"
								variant="outlined"
								margin="normal"
								fullWidth
								name="fromDate"
								label="Created On From"
								type="date"
								id="fromDate"
								value={fromDate}
								onChange={handleChange("fromDate")}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={3}>
							<TextField
								InputLabelProps={{
									shrink: true,
								}}
								size="small"
								variant="outlined"
								margin="normal"
								fullWidth
								name="toDate"
								label="Created On To"
								type="date"
								id="toDate"
								value={toDate}
								onChange={handleChange("toDate")}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={2}>
							<Button
								style={{ marginTop: 20 }}
								variant="contained"
								onClick={onHandleButton}
								className={classes.button}
								fullWidth="true"
							>
								Search
							</Button>
						</GridItem>
						<GridItem xs={12} sm={12} md={2}>
							<Button
								style={{ marginTop: 20 }}
								onClick={onClear}
								variant="contained"
								className={classes.button}
								fullWidth="true"
							>
								Clear
							</Button>
						</GridItem>
						<GridItem xs={12} sm={12} md={2}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={(e) => {
										exportToExcel("Excel Export")
									}}
									startIcon={<FileDownloadOutlinedIcon fontSize="small" />}

								>
									Export
								</Button>
							</GridItem>
					</GridContainer>
					<GridContainer style={{ paddingTop: 40, margin: 80 }}>&nbsp;</GridContainer>
					<Divider></Divider>
					<TableContainer component={Paper} className={classes.customTableContainer}>
						{/* <GridContainer> */}
						<Table stickyHeader textAlign="center">
							<TableHead>
								<TableRow className={classes.table}>
									<TableCell className={classes.head}>
										<Typography variant="inherit" gutterBottom className={classes.head}>
											Sr No.
										</Typography>
									</TableCell>
									<TableCell className={classes.head}>
										<Typography variant="inherit" gutterBottom className={classes.head}>
											Category
										</Typography>
									</TableCell>
									<TableCell className={classes.head}>
										<Typography variant="inherit" gutterBottom className={classes.titleColor}>
											Sub-Category
										</Typography>
									</TableCell>
									<TableCell className={classes.head}>
										<Typography variant="inherit" gutterBottom className={classes.titleColor}>
											Description
										</Typography>
									</TableCell>

									<TableCell textAlign="center" className={classes.head}>
										<Typography variant="inherit" textAlign="center" gutterBottom>
											Created By
										</Typography>
									</TableCell>
									<TableCell className={classes.head}>
										<Typography variant="inherit" gutterBottom className={classes.titleColor}>
											Status
										</Typography>
									</TableCell>
									<TableCell className={classes.head} textAlign="center">
										<Typography variant="inherit" gutterBottom className={classes.titleColor}>
											Created On
										</Typography>
									</TableCell>
									<TableCell className={classes.head}>
										<Typography variant="inherit" gutterBottom className={classes.titleColor}>
											Modified On
										</Typography>
									</TableCell>
									<TableCell className={classes.head}>
										<Typography variant="inherit" gutterBottom className={classes.titleColor}>
											Closed On
										</Typography>
									</TableCell>
									<TableCell className={classes.head}>
										<Typography variant="inherit" gutterBottom className={classes.titleColor}>
											Creator's Comment
										</Typography>
									</TableCell>
									<TableCell className={classes.head}>
										<Typography variant="inherit" gutterBottom className={classes.titleColor}>
											Executive's Comment
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{allTicket.map((ticket, index) => (
									<TableRow className={classes.tableRow} key={index}>
										<TableCell className={classes.tableCell}>
											<Typography variant="body1" gutterBottom>
												{index + 1}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="body1" gutterBottom>
												{ticket.category}
											</Typography>
										</TableCell>
										<TableCell>
											<Grid xs={12}>
												<Typography variant="body1" gutterBottom className={classes.subcategorycss}>
													{ticket.subCategory}
												</Typography>
											</Grid>
										</TableCell>
										<TableCell>
											<Typography variant="body1" gutterBottom>
												{ticket.issueDescription}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="body1" gutterBottom>
												{ticket.ticketOwner}
											</Typography>
										</TableCell>
										<TableCell>
											{ticket.ticketStatus === "open" ? (
												<Chip
													label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Open&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
													variant="outlined"
													// onClick={ticket.ticketStatus}
													style={{
														backgroundColor: "green",
														color: "white",
														fontWeight: "bold",
													}}
												/>
											) : ticket.ticketStatus === "inProgress" ? (
												<Chip
													label="&nbsp;In Progress&nbsp;"
													variant="outlined"
													// onClick={ticket.ticketStatus}
													style={{
														backgroundColor: "skyblue",
														color: "white",
														fontWeight: "bold",
													}}
												/>
											) : ticket.ticketStatus === "onHold" ? (
												<Chip
													label="&nbsp;&nbsp;&nbsp;&nbsp;On Hold&nbsp;&nbsp;&nbsp;&nbsp;"
													variant="outlined"
													// onClick={ticket.ticketStatus}
													style={{
														backgroundColor: "orange",
														color: "white",
														fontWeight: "bold",
													}}
												/>
											) : (
												<Chip
													label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Closed&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
													variant="outlined"
													// onClick={ticket.ticketStatus}
													style={{
														backgroundColor: "red",
														color: "white",
														fontWeight: "bold",
													}}
												/>
											)}
										</TableCell>
										<TableCell>
											<Typography variant="body1" gutterBottom>
												{ticket?.createdOnMil && moment(ticket?.createdOnMil).format("DD/MM/YYYY")}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="body1" gutterBottom>
												{ticket?.modifiedOnMil && moment(ticket?.modifiedOnMil).format("DD/MM/YYYY")}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="body1" gutterBottom>
												{ticket?.closedOnMil && moment(ticket?.closedOnMil).format("DD/MM/YYYY")}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="body1" gutterBottom>
												{ticket?.operatorComment || ticket?.driverComment || ticket?.customerComment || "-"}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="body1" gutterBottom>
												{ticket?.executiveComment || "-"}
											</Typography>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{/* </TableContainer> */}
						{/* </GridContainer> */}
					</TableContainer>
				</ThemeProvider>
			</GridItem>
			{/* </Container> */}
			<Backdrop className={classes.backdrop} open={open} onClick={closeLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
			{/* // </Container> */}
		</>
	);
}
