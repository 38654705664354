import React, { useState, useEffect, useCallback, useRef } from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import {
  Backdrop,
  Chip,
  TableContainer,
  TableHead,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import Lottie from "react-lottie";
import AddIcon from "@material-ui/icons/Add";
import animationData from "../../assets/jss/lf30_editor_fvhlirkb.json";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import MuiAlert from "@material-ui/lab/Alert";
import { useSnackbar } from "notistack";
import _ from "lodash";
import {
  addAndUpdateAllVehiclesMasterData,
  getAllVehiclesMasterMetadata,
} from "../../services/metadata/metadata";
import { EditTwoTone } from "@material-ui/icons";
import FareManagementDetailsDialog from "./FareManagementDetailsDialog";

const loaderOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((styles) => ({
  backdrop: {
    zIndex: styles.zIndex.drawer + 1,
    color: "#fff",
  },

  customTableContainer: {
    overflowX: "initial",
  },

  tableCell: {
    Width: 100,
  },
  head: {
    backgroundColor: "#FFA93F",
    position: "sticky",
    top: 0,
    color: "#fff",
  },
  submit: {
    margin: styles.spacing(3, 0, 2),
    backgroundColor: "#FF8000",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c55100",
    },
  },
}));

const PurpleSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#FF8000",
    },
    "&$checked + $track": {
      backgroundColor: "#FF8000",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function FareManagement() {
  const classes = useStyles();
  const [allVehiclesMasterData, setAllVehiclesMasterData] = useState();
  const [open, setOpen] = useState(false);
  const [selectedVehicleMasterData, setSelectedVehicleMasterData] =
    useState("");
  const [selectedFareMasterData, setSelectedFareMasterData] = useState();
  const [selectedVehicleIndex, setSelectedVehicleIndex] = useState();
  const [openLoader, setOpenLoader] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const isMountedRef = useRef(true);

  const { enqueueSnackbar } = useSnackbar();
  const showToast = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant ? variant : "info",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };
  const closeLoader = () => {
    setOpenLoader(false);
  };

  const toggleLoader = () => {
    setOpenLoader(!openLoader);
  };

  const onDeactivate = (vehicleMasterData) => {
    vehicleMasterData.isActive = false;
    addAndUpdateAllVehiclesMasterData(allVehiclesMasterData).then(
      (response) => {
        setSelectedVehicleMasterData("");
        handleCloseConfirmationDialog();
        // setCurrentUserStatus(false);
        showToast(`Vehicle is Deactivated`, "success");
        // setuserStatusToastOpen(true);
      }
    );
  };

  const onActivate = (vehicleMasterData) => {
    setSelectedVehicleMasterData(vehicleMasterData);
    vehicleMasterData.isActive = true;
    addAndUpdateAllVehiclesMasterData(allVehiclesMasterData).then(
      (response) => {
        setSelectedVehicleMasterData("");
        handleCloseConfirmationDialog();
        showToast(`Vehicle is Activated`, "success");
      }
    );
  };

  const handleToggleUser = (vehicleMasterData) => {
    handleOpenConfirmationDialog();
    setSelectedVehicleMasterData(vehicleMasterData);
  };

  const handleEditMasterDataDialogOpen = () => {
    setOpen(true);
  };

  const fetchAllFareManagement = useCallback(async () => {
    try {
      let fetchAllMasterDataResponse = await getAllVehiclesMasterMetadata();
      setAllVehiclesMasterData(fetchAllMasterDataResponse);
    } catch (error) {
      console.log(error);
    }
  }, [isMountedRef]);

  useEffect(() => {
    fetchAllFareManagement();
  }, [fetchAllFareManagement]);

  return (
    <>
      {/* <Grid container spacing={2} >            
          <Grid item md={10} textAlign={'right'}>
            <Button
              onClick={() => {
                setSelectedFareMasterData(undefined);
                setOpen(true);
              }}
              variant="contained"
              startIcon={<AddIcon fontSize="small" />}
            >

              {('Fare')}
            </Button>
          </Grid>
        </Grid> */}
      <TableContainer
        component={Paper}
        className={classes.customTableContainer}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.head}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.titleColor}
                >
                  Sr.No
                </Typography>
              </TableCell>
              <TableCell className={classes.head}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.titleColor}
                >
                  Model
                </Typography>
              </TableCell>
              <TableCell className={classes.head}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.titleColor}
                >
                  Category
                </Typography>
              </TableCell>
              <TableCell className={classes.head}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.titleColor}
                >
                  Sub Category
                </Typography>
              </TableCell>
              <TableCell className={classes.head}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.titleColor}
                >
                  Base Fare
                </Typography>
              </TableCell>
              <TableCell className={classes.head}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.titleColor}
                >
                  Fare-Per-KM
                </Typography>
              </TableCell>

              {/* <TableCell className={classes.head}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.titleColor}
                >
                 Status
                </Typography>
              </TableCell> */}
              <TableCell className={classes.head} style={{ zIndex: 500 }}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.titleColor}
                >
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allVehiclesMasterData?.data?.vehicles.map(
              (allMasterDataElement, index) => (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell>
                    <Typography variant="body1" gutterBottom>
                      {index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" gutterBottom>
                      {allMasterDataElement.Model}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" gutterBottom>
                      {allMasterDataElement.Category}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" gutterBottom>
                      {allMasterDataElement.SubCategory}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" gutterBottom>
                      {allMasterDataElement?.BaseFare}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant="body1" gutterBottom>
                      {allMasterDataElement.FarePerKM}
                    </Typography>
                  </TableCell>

                  {/* <TableCell>
                    {allMasterDataElement.isActive === true ? (
                      <Chip
                        label="&nbsp;ACTIVE&nbsp;"
                        color="green"
                        variant="outlined"
                        style={{
                          borderColor: "green",
                          color: "black",
                          fontWeight: "bold",
                        }}
                      />
                    ) : (
                      <Chip
                        label="INACTIVE"
                        variant="outlined"
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      />
                    )}
                  </TableCell> */}

                  <TableCell align="center">
                    <Typography>
                      <Tooltip
                        title={"Edit"}
                        arrow
                        onClick={() => {
                          setSelectedFareMasterData(allMasterDataElement);
                          setSelectedVehicleIndex(index);
                          handleEditMasterDataDialogOpen(allMasterDataElement);
                        }}
                      >
                        <IconButton color="primary">
                          <EditTwoTone fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Typography>

                    {/* <Typography>

                  <PurpleSwitch
                    checked={allMasterDataElement.isActive ? true : false}
                    onClick={() => {
                      handleToggleUser(allMasterDataElement);
                    }}
                  />
                  </Typography> */}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Backdrop
        className={classes.backdrop}
        open={openLoader}
        onClick={closeLoader}
      >
        <CircularProgress color="inherit" />
        <Lottie options={loaderOptions} height={200} width={600} />
      </Backdrop> 
       <Dialog
        //component={Paper}
        //fullWidth="true"
        maxWidth="xs"
        open={openConfirmationDialog}
        //TransitionComponent={Transition}
        onClose={handleCloseConfirmationDialog}
      >
        <DialogTitle id="alert-dialog-title">{"NowWagon"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{`Do you want to ${
            selectedVehicleMasterData !== "" && selectedVehicleMasterData?.isActive
              ? "Deactivate"
              : "Activate"
          } vehicle?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            className={classes.submit}
            onClick={
              selectedVehicleMasterData !== ""
                ? selectedVehicleMasterData.isActive
                  ? () => onDeactivate(selectedVehicleMasterData)
                  : () => onActivate(selectedVehicleMasterData)
                : null
            }
          >
            YES
          </Button>
          <Button
            color="primary"
            className={classes.submit}
            onClick={() => handleCloseConfirmationDialog()}
            autoFocus
          >
            NO
          </Button>
        </DialogActions>
      </Dialog> */}

      <FareManagementDetailsDialog
        open={open}
        setOpen={setOpen}
        selectedVehicleIndex={selectedVehicleIndex}
        selectedFareMasterData={selectedFareMasterData}
        allVehiclesMasterData={allVehiclesMasterData}
      />
    </>
  );
}
